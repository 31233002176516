import styled from "styled-components";

export const OnduleurContainer = styled.div<{ display: boolean }>`
  background-color: ${(props) => props.theme.colors.clay.clayPower200};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.primary.bluePower300};

  .onduleurs {
    display: ${({ display }) => (display ? "block" : "none")};
  }
  width: 100%;
  hr {
    border: 1px solid ${(props) => props.theme.colors.primary.bluePower300};
  }
`;
export const OnduleurItemContainer = styled.div`
  &:not(.selectedOnduleur):hover {
    cursor: ${(props) => (props.onClick !== undefined ? "pointer" : "auto")};
    background-color: ${(props) => props.theme.colors.clay.clayPower400};
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  .onduleur-item-img {
    display: block;
    height: 40px;
    width: 40px;
  }

  .onduleur-item-arrow-right {
    padding-left: 5px;
  }

  padding: 8px 16px ;

  p {
    flex: 1 1 auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  .onduleurTrailing {
    text-align: end;
    line-height:1;
  }
`;
