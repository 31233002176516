import React, { useState, useEffect, useCallback } from "react";
import { Sections, SectionConfig } from "./constants";
import AlimentationTypeComponent from "./sections_content/1_alimentation_type";
import SolarKitComponent from "./sections_content/2_solar_kit";
import SupervisingComponent from "./sections_content/4_supervising";
import WiringComponent from "./sections_content/3_wiring";
import LightningArresterComponent from "./sections_content/5_lightning_arrester";
import { SectionManagerStyled } from "./styled";
import WarrantyComponent from "./sections_content/6_warranty";
import BatteryComponent from "./sections_content/7_battery";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  STEP_RECOMMANDATION,
} from "../../../../../store/reducer";
import { useTranslation } from "react-i18next";
import AdministrationHelpComponent from "./sections_content/8_administration_help";
import InstallationHelpComponent from "./sections_content/9_installation_help";

import store, { RootState } from "../../../../../store";
import { configurateurPanelsLoad } from "../../../../../store/configurateur/panels/configurateur-panels-reducer";

import { useSelector } from "react-redux";
import { configurateurOnduleursLoad } from "../../../../../store/configurateur/onduleurs/configurateur-onduleurs-reducer";
import { getPhaseNumberFromAlimType } from "./sections_content";
import { StructureTypesId } from "../../../Recommandation/phases/phase1/constants";
import { configurateurSupervisionLoad } from "../../../../../store/configurateur/supervision/configurateur-supervision-reducer";
import { configurateurAccessoriesLoad } from "../../../../../store/configurateur/accessories/configurateur-accessories-reducer";
import { useDeepCompareEffect } from "../../../../../hooks/use-deep-compare-effect";
import { configurateurBatteriesLoad } from "../../../../../store/configurateur/batteries/configurateur-batteries-reducer";
import { configurateurHelpsLoad } from "../../../../../store/configurateur/helps/configurateur-helps-reducer";
import { configurateurGarantiesLoad } from "../../../../../store/configurateur/garanties/configurateur-garanties-reducer";
import { configurateurInstallHelpsLoad } from "../../../../../store/configurateur/install-helps/configurateur-install-helps-reducer";
import CalepinageHorsToitureTypeComponent from "./sections_content/0_calepinageHorsToiture_type";
import CalepinageToitureMobileComponent from "./sections_content/0_calepinageToiture_mobile_type";
import { InstallationType } from "../../../Installation/constants";
import {
  OMainButton,
  OSecondaryButton,
  OTertiaryButton,
} from "../../../../Styled/Buttons";
import { OBody5, OBody4 } from "../../../../Styled/Texts";
import {
  Section,
  TotalContainer,
  TotalContent,
} from "./sections_content/styled";
import { useVirtualCartTotal } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-total-hook";
import {
  addToCart,
  saveDealtToCart,
} from "../../../../../data/prestashop/services/products-service";
import {
  useVirtualCartInstallHelpToAdd,
  useVirtualCartItemsToAdd,
} from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-items-to-add-hook";
import { URL_CART } from "../../../../../utils/constants";
import { redirectToUrl } from "../../../../../utils";
import { usePostcode } from "../../../../../hooks/location/location-hook";
import {
  useEffectPanneauFixations,
  useFixationIds,
} from "../../../../../hooks/fixations/use-fixations-hook";
import { InstallHelpSummary } from "./install-help/summary/install-help-summary";
import useIsMobile from "../../../../../utils/useIsMobile";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
import { useVirtualCartItems } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-items-hook";
import { useVirtualCartKitPower } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-kit-power";
import { formatNumber } from "../../../../../utils/convert-number-locale";
import { OLinkButton } from "../../../../Styled/Buttons/Link/o-link-button";
import { theme } from "../../../../Styled/GlobalStyle";
import { SectionEmplacement } from "./emplacement";
import { buildMatrixForApi } from "../../../../../utils/build-matrix-for-api";

interface SectionManagerProps {
  initialConfig: SectionConfig;
  isCartExpanded: boolean;
  onViewCartDetailClicked: any;
}

const SectionManager: React.FC<SectionManagerProps> = ({
  initialConfig,
  isCartExpanded,
  onViewCartDetailClicked,
}) => {
  const [config] = useState<SectionConfig>(initialConfig);
  const currentStepName = STEP_DESIGN;
  const { t } = useTranslation([currentStepName, "global"]);

  const hasSkipped = useSelector((state: RootState) => state.step.skip);
  const alimentation = useSelector(
    (state: RootState) => state.configurateurAlimentation.alimentationData.type
  );
  const emplacement = useSelector(
    (state: RootState) =>
      state.step.steps[STEP_RECOMMANDATION].data.phase1?.material
  );

  const panelsData = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData
  );
  const onduleursData = useSelector(
    (state: RootState) => state.configurateurOnduleurs?.onduleursData
  );
  const supervisionData = useSelector(
    (state: RootState) => state.configurateurSupervisions?.supervisionData
  );

  const installationData = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data
  );

  const batteryIdAttribute = useSelector(
    (state: RootState) =>
      state.configurateurBatteries?.batteriesData?.selected?.id_product_attr
  );

  const isRoof = installationData?.installation === InstallationType.Roof;

  const panelCount = useSelector((state: RootState) => {
    if (state.step.skip && isRoof) {
      return state.calepinageHMReducer?.count;
    }
    return state.calepinageReducer?.panelCountInCalepinage;
  });
  const fixationNumber = useSelector(
    (state: RootState) =>
      state.configurateurFixations?.fixationsData?.panneauFixation?.fixationsNb
  );

  const postcode = usePostcode();

  // Refresh panels on panel numbers changed
  useEffect(() => {
    store.dispatch(configurateurPanelsLoad(panelCount));
  }, [panelCount]);

  // Fixations
  const matrice = useSelector((state: RootState) => {
    if (state.step.skip) {
      return state.calepinageHMReducer?.matriceCalepinageApi;
    }
    return state.calepinageReducer?.matriceCalepinage;
  });

  const dispositionPanneaux = useSelector((state: RootState) => {
    if (state.step.skip) {
      return state.calepinageHMReducer?.dispositionPanneaux ? 0 : 1; // FUCK
    }
    return state.calepinageReducer?.dispositionPanneaux;
  });

  const fixationIds = useFixationIds();
  useEffectPanneauFixations(
    installationData?.installation,
    matrice,
    StructureTypesId[emplacement] as number,
    dispositionPanneaux,
    panelsData?.selected?.id_product
  );

  // Load garanties
  useEffect(() => {
    store.dispatch(configurateurGarantiesLoad());
  }, []);

  // Refresh onduleurs on panels loaded
  useEffect(() => {
    if (panelsData?.data?.length > 0) {
      const emplacementId = StructureTypesId[emplacement] as number;

      const panelId = panelsData?.selected?.id_product;
      const phaseNumber = getPhaseNumberFromAlimType(alimentation);
      if (emplacementId && fixationIds?.id && panelId && phaseNumber) {
        store.dispatch(
          configurateurOnduleursLoad(
            emplacementId,
            fixationIds?.id,
            panelId,
            panelCount,
            phaseNumber
          )
        );
      }
    }
  }, [
    emplacement,
    panelsData?.selected?.id_product_attr,
    alimentation,
    panelCount,
    fixationIds?.id,
  ]);

  // Refresh supervisions on onduleurs load
  useEffect(() => {
    const onduleurIdAttribute = onduleursData?.selected?.id_product_attr;
    if (onduleurIdAttribute) {
      store.dispatch(configurateurSupervisionLoad(onduleurIdAttribute));
    }
  }, [onduleursData?.selected?.id_product_attr]);

  // Refresh accessories combinations
  useDeepCompareEffect(() => {
    const panel = panelsData?.selected;
    const onduleur = onduleursData?.selected;
    const supervision = supervisionData?.selected;

    if (
      alimentation &&
      panel &&
      onduleur &&
      supervision &&
      fixationIds &&
      fixationNumber
    ) {
      store.dispatch(
        configurateurAccessoriesLoad(
          panel?.id_product_attr,
          panelCount,
          onduleur?.id_product_attr,
          onduleur?.kitQuantity,
          fixationIds?.attribute,
          supervision?.id_product_attr,
          getPhaseNumberFromAlimType(alimentation),
          onduleur?.tracker,
          fixationNumber,
          postcode
        )
      );
    }
  }, [
    alimentation,
    supervisionData?.selected,
    panelCount,
    fixationIds?.id,
    fixationNumber,
  ]);

  // Refresh batteries on onduleurs changed
  useEffect(() => {
    const onduleur = onduleursData?.selected;
    if (onduleur && alimentation) {
      store.dispatch(
        configurateurBatteriesLoad(
          onduleur?.id_product_attr,
          getPhaseNumberFromAlimType(alimentation)
        )
      );
    }
  }, [onduleursData?.selected?.id_product_attr]);

  // Refresh helps on panel changed
  useEffect(() => {
    const panelIdAttribute = panelsData?.selected?.id_product_attr;

    if (panelIdAttribute && panelCount) {
      store.dispatch(
        configurateurHelpsLoad(panelIdAttribute, panelCount, batteryIdAttribute)
      );
    }
  }, [panelsData?.selected?.id_product_attr, panelCount, batteryIdAttribute]);

  // Refresh install helps (dealt) on onduleur, alim or localization changed
  useEffect(() => {
    const onduleur = onduleursData?.selected;
    if (onduleur && alimentation) {
      store.dispatch(
        configurateurInstallHelpsLoad(postcode, onduleur, alimentation)
      );
    }
  }, [onduleursData?.selected?.id_product_attr, alimentation, postcode]);

  const total = useVirtualCartTotal();
  const itemsToAdd = useVirtualCartItemsToAdd();
  const installHelpToSave = useVirtualCartInstallHelpToAdd();
  const isMobile = useIsMobile();

  const items = useVirtualCartItems();
  const kitPower = useVirtualCartKitPower();

  // Format cart data we want to track
  const getDataToTrack = useCallback(() => {
    return {
      cartPreview: {
        kitPower: kitPower,
        panelsNumber: panelCount,
        categories: items.map((cartItem) => {
          return {
            category: cartItem.category,
            items: cartItem.items.map((item) => item.details),
          };
        }),
      },
    };
  }, [items, kitPower, panelCount]);

  return (
    <SectionManagerStyled>
      {hasSkipped && <SectionEmplacement />}
      {config[Sections.CALEPINAGE_HORS_TOITURE].display && !isRoof && (
        <CalepinageHorsToitureTypeComponent />
      )}
      {config[Sections.CALEPINAGE_TOITURE_MOBILE].display &&
        isRoof &&
        isMobile && <CalepinageToitureMobileComponent />}
      {config[Sections.ALIMENTATION_TYPE].display && (
        <AlimentationTypeComponent />
      )}
      {config[Sections.SOLAR_KIT].display && (
        <SolarKitComponent initial={config[Sections.SOLAR_KIT]} />
      )}
      {config[Sections.WIRING].display && (
        <WiringComponent initial={config[Sections.WIRING]} />
      )}
      {config[Sections.SUPERVISING].display && (
        <SupervisingComponent initial={config[Sections.SUPERVISING]} />
      )}
      {config[Sections.LIGHTNING_ARRESTER].display && (
        <LightningArresterComponent
          initial={config[Sections.LIGHTNING_ARRESTER]}
        />
      )}
      {config[Sections.WARRANTY].display && (
        <WarrantyComponent initial={config[Sections.WARRANTY]} />
      )}
      {config[Sections.BATTERY].display && (
        <BatteryComponent initial={config[Sections.BATTERY]} />
      )}
      {config[Sections.ADMINISTRATION_HELP].display && (
        <AdministrationHelpComponent
          initial={config[Sections.ADMINISTRATION_HELP]}
        />
      )}
      {config[Sections.INSTALLATION_HELP].display && (
        <InstallationHelpComponent
          initial={config[Sections.INSTALLATION_HELP]}
        />
      )}

      <TotalContainer>
        <TotalContent>
          <OBody5>{t("total")}</OBody5>
          <OBody4>{formatNumber(total)}</OBody4>
        </TotalContent>
        <OLinkButton
          color={theme.colors.clay.clayPower700}
          onClick={onViewCartDetailClicked}
        >
          {isCartExpanded ? t("hideCartDetails") : t("viewCartDetails")}
        </OLinkButton>
        {installHelpToSave && (
          <InstallHelpSummary item={installHelpToSave}></InstallHelpSummary>
        )}
        <OMainButton
          className="w-100 conception-configuration-next-btn"
          center
          primary
          onClick={() => {
            const cartId = parseInt(
              document.getElementById("root-configurateur").dataset.cart
            );
            trackDataLayer("CartSelected", getDataToTrack());
            Promise.all([
              addToCart(cartId, itemsToAdd),
              installHelpToSave && saveDealtToCart(cartId, installHelpToSave),
            ]).then(() => {
              redirectToUrl(URL_CART);
            });
          }}
        >
          {t("phase_2.continue")}
        </OMainButton>
      </TotalContainer>
    </SectionManagerStyled>
  );
};

export default SectionManager;
