import _ from "lodash";
import { transposeMatrix } from "../../utils/transpose-matrix";
import { get } from "http";
import { buildMatrixForApi } from "../../utils/build-matrix-for-api";

export const SET_PANEL_COUNT_IN_CALEPINAGE = "SET_PANEL_COUNT_IN_CALEPINAGE";
export const SET_MATRICE_CALEPINAGE = "SET_MATRICE_CALEPINAGE";
export const SET_DISPOSITION_PANNEAUX = "SET_DISPOSITION_PANNEAUX";
export const SET_EDITABLE = "SET_EDITABLE";
export const SET_ROW_CALEPINAGE = "SET_ROW_CALEPINAGE";
export const DELETE_ROW_CALEPINAGE = "DELETE_ROW_CALEPINAGE";
export const ADD_NEW_ROW = "ADD_NEW_ROW";
export const ADD_ROW_BUTTON_ON_MOUSE_ENTER = "ADD_ROW_BUTTON_ON_MOUSE_ENTER";
export const ADD_PANEL_IN_ROW = "ADD_PANEL_IN_ROW";
export const REMOVE_PANEL_IN_ROW = "REMOVE_PANEL_IN_ROW";
export const SET_DELETE_PANEL_ON_ROW_ON_MOUSE_ENTER = "SET_DELETE_PANEL_ON_ROW_ON_MOUSE_ENTER";
export const ROTATE_MATRIX = "ROTATE_MATRIX";
export const SWAP_OBSTACLE_IN_ROW = "ADD_OBSTACLE_IN_ROW";
const DISPOSITION_PORTRAIT = 1;
const DISPOSITION_PAYSAGE = 0;


interface SetPanelCountInCalepinageAction {
    type: typeof SET_PANEL_COUNT_IN_CALEPINAGE;
    payload: number;
  }

interface SetMatriceCalepinageAction {
    type: typeof SET_MATRICE_CALEPINAGE;
    payload: number[][];
}

interface SetDispositionPanneaux {
    type: typeof SET_DISPOSITION_PANNEAUX;
    payload: number;
}

interface SetDEditable {
    type: typeof SET_EDITABLE;
    payload: boolean;
}

interface SetRowCalepinagePayload {
    rowIndex: number;
    row: number[];
}

interface DeleteRowCalepinagePayload {
    rowIndex: number;
}

interface AddRowButtonOnMouseEnterPayload {
    rowIndex: number;
}

interface AddPanelInRowPayload {
    rowIndex: number;
}

interface RemovePanelInRowPayload {
    rowIndex: number;
}

interface setDeletePanelOnRowOnMouseEnterPayload {
    rowIndex: number;
}

interface swapObstacleInRowPayload {
    rowIndex: number;
    index: number;
}

////

interface AddRowButtonOnMouseEnter {
    type: typeof ADD_ROW_BUTTON_ON_MOUSE_ENTER;
    payload: AddRowButtonOnMouseEnterPayload;
}

interface SetDeletePanelOnRowOnMouseEnter {
    type: typeof SET_DELETE_PANEL_ON_ROW_ON_MOUSE_ENTER;
    payload: setDeletePanelOnRowOnMouseEnterPayload;
}

interface AddPanelInRow {
    type: typeof ADD_PANEL_IN_ROW;
    payload: AddPanelInRowPayload;
}

interface SetRowCalepinage {
    type: typeof SET_ROW_CALEPINAGE;
    payload: SetRowCalepinagePayload;
}

interface DeleteRowCalepinage {
    type: typeof DELETE_ROW_CALEPINAGE;
    payload: DeleteRowCalepinagePayload;
}

interface AddNewRow {
    type: typeof ADD_NEW_ROW;
}

interface RotateMatrix {
    type: typeof ROTATE_MATRIX;
}

interface RemovePanelinRow {
    type: typeof REMOVE_PANEL_IN_ROW;
    payload: RemovePanelInRowPayload;
}

interface SwapObstacleInRow {
    type: typeof SWAP_OBSTACLE_IN_ROW;
    payload: swapObstacleInRowPayload;
}

export type CalepinageHMActionTypes =
  | SetPanelCountInCalepinageAction
  | SetMatriceCalepinageAction
  | SetDispositionPanneaux
  | SetDEditable
  | SetRowCalepinage
  | DeleteRowCalepinage
  | AddNewRow
  | AddRowButtonOnMouseEnter
  | AddPanelInRow
  | SwapObstacleInRow
  | RemovePanelinRow
  | SetDeletePanelOnRowOnMouseEnter
  | RotateMatrix;

export interface CalepinageHMState {
    panelCountInCalepinage: number;
    matriceCalepinage: number[][];
    matriceCalepinageApi: number[][];
    dispositionPanneaux: number;
    isMatrixEditable: boolean;
    rowWithVirtualPanelToShow: number;
    rowWithDeletePanelToShow: number;
    maxRowLength: number;
    count: number;
    maxPanelCount: number;
}

const initalMatrix = [
    [1, 1, 1, 1],
    [1, 1, 1, 1]
];

const initialState: CalepinageHMState = {
    panelCountInCalepinage: 8,
    matriceCalepinage: initalMatrix,
    matriceCalepinageApi: initalMatrix,
    dispositionPanneaux: DISPOSITION_PORTRAIT,
    isMatrixEditable: false,
    rowWithVirtualPanelToShow: -1,
    rowWithDeletePanelToShow: -1,
    maxRowLength: getMaxLength(initalMatrix),
    count: getPanelCount(initalMatrix),
    maxPanelCount: 25,
  };

export function calepinageHMReducer (
    state = initialState,
    action: CalepinageHMActionTypes 
    ) : CalepinageHMState {

    let updatedMatrix;

    switch (action.type) {
        case SET_PANEL_COUNT_IN_CALEPINAGE:
            return {
                ...state,
                panelCountInCalepinage: action.payload,
            };
        case SET_ROW_CALEPINAGE:
            updatedMatrix = [...state.matriceCalepinage];
            updatedMatrix[action.payload.rowIndex] = action.payload.row;
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        case ADD_PANEL_IN_ROW:
            updatedMatrix = addPanelInSelectedRow([...state.matriceCalepinage], action.payload.rowIndex);
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        case REMOVE_PANEL_IN_ROW:
            updatedMatrix = removePanelInSelectedRow([...state.matriceCalepinage], action.payload.rowIndex);
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            }
        case DELETE_ROW_CALEPINAGE:
            updatedMatrix = [...state.matriceCalepinage];
            updatedMatrix.splice(action.payload.rowIndex, 1);
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        case ADD_NEW_ROW:
            updatedMatrix = duplicateLastRow(state.matriceCalepinage, state.count, state.maxPanelCount);
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        case SET_MATRICE_CALEPINAGE:
            updatedMatrix = action.payload;
            return {
                ...state,
                matriceCalepinage: action.payload,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        case SET_DISPOSITION_PANNEAUX:
            return {
                ...state,
                dispositionPanneaux: action.payload,
            };
        case SET_EDITABLE:
            return {
                ...state,
                isMatrixEditable: action.payload,
            };
        case ADD_ROW_BUTTON_ON_MOUSE_ENTER:
            return {
                ...state,
                rowWithVirtualPanelToShow: action.payload.rowIndex,
            };
        case SET_DELETE_PANEL_ON_ROW_ON_MOUSE_ENTER:
            return {
                ...state,
                rowWithDeletePanelToShow: action.payload.rowIndex,
            };
        case ROTATE_MATRIX:
            // console.log("before : ", state.matriceCalepinage);
            const transposedMatrix = transposeMatrix([...state.matriceCalepinage]);
            const newDisposition = state.dispositionPanneaux === DISPOSITION_PORTRAIT ? DISPOSITION_PAYSAGE : DISPOSITION_PORTRAIT;
            return {
                ...state,
                //matriceCalepinage: _.zip(...state.matriceCalepinage).reverse(), // if rotate by transposition don't work
                matriceCalepinage: transposedMatrix,
                matriceCalepinageApi: buildMatrixForApi(transposedMatrix, newDisposition),
                dispositionPanneaux: newDisposition,
            }
        case SWAP_OBSTACLE_IN_ROW:
            updatedMatrix = swapObstacleInSelectedRow([...state.matriceCalepinage], action.payload.rowIndex, action.payload.index);
            return {
                ...state,
                matriceCalepinage: updatedMatrix,
                matriceCalepinageApi: buildMatrixForApi(updatedMatrix, state.dispositionPanneaux),
                count: getPanelCount(updatedMatrix),
            };
        default:
            return state;
    }
}

export function setRowCalepinage(payload: SetRowCalepinagePayload) {
    return {
        type: SET_ROW_CALEPINAGE,
        payload: payload,
    };
}

export function deleteRowCalepinage(payload: DeleteRowCalepinagePayload) {
    return {
        type: DELETE_ROW_CALEPINAGE,
        payload: payload,
    };
}

export function addNewRow() {
    return {
        type: ADD_NEW_ROW,
    };
}

export function addRowButtonOnMouseEnter(payload: AddRowButtonOnMouseEnterPayload) {
    return {
        type: ADD_ROW_BUTTON_ON_MOUSE_ENTER,
        payload: payload
    };
}

// setRowToShowDeletePanel
export function setRowToShowDeletePanel(payload: setDeletePanelOnRowOnMouseEnterPayload) {
    return {
        type: SET_DELETE_PANEL_ON_ROW_ON_MOUSE_ENTER,
        payload: payload
    };
}

export function addPanelInRow(payload: AddPanelInRowPayload) {
    return {
        type: ADD_PANEL_IN_ROW,
        payload: payload,
    };
}

export function removePanelInRow(payload: RemovePanelInRowPayload) {
    return {
        type: REMOVE_PANEL_IN_ROW,
        payload: payload,
    };
}

export function rotateMatrix () {
    return {
        type: ROTATE_MATRIX,
    };
}

export function swapObstacle (payload: swapObstacleInRowPayload) {
    return {
        type: SWAP_OBSTACLE_IN_ROW,
        payload: payload,
    }
}

/////////////////////////

function duplicateLastRow(matrix, count, maxCount){

    const newMatrix = _.cloneDeep(matrix);
    const lastRow = _.cloneDeep(newMatrix[newMatrix.length - 1]);

    let newCount = count + _.sum(lastRow);

    if( newCount > maxCount){
        // reduce lastRow until count + _.sum(lastRow) <= maxCount
        while (newCount > maxCount) {
            lastRow.pop();
            newCount = count + _.sum(lastRow);
        }
    }

    newMatrix.push(lastRow);

    return newMatrix;
}

function addPanelInSelectedRow(matrix, rowIndex){

    const newRow = _.cloneDeep(matrix[rowIndex]);
    newRow.push(1);
    matrix[rowIndex] = newRow;

    return matrix;
}

function swapObstacleInSelectedRow(matrix, rowIndex, index){
    const newRow = _.cloneDeep(matrix[rowIndex]);
    newRow[index] = newRow[index] === 1 ? 0 : 1;
    matrix[rowIndex] = newRow;
    return matrix
}


function removePanelInSelectedRow(matrix, rowIndex){
    
    const newRow = _.cloneDeep(matrix[rowIndex]);

    if(newRow.length === 1){
        if(matrix.length === 1){
            return matrix;
        }
        matrix.splice(rowIndex, 1);
    } else {
        newRow.pop();
        matrix[rowIndex] = newRow;
    }

    return matrix;
}

function getMaxLength (matrix) {
    return _.maxBy(matrix, function(row) { return row.length; }).length;
}

function getPanelCount (matrix) {
    // sum all values of matrix [][]
    return _.sum(_.flatten(matrix));
}