import { useSelector } from "react-redux";
import { RootState } from "../..";

export const useVirtualCartTotal = () => {
  const panelPrice = useSelector(
    (state: RootState) =>
      state.configurateurPanels?.panelsData?.selected?.kitPrice || 0
  );
  const onduleurPrice = useSelector(
    (state: RootState) =>
      state.configurateurOnduleurs?.onduleursData?.selected?.kitPrice || 0
  );

  const fixationPrice = useSelector((state: RootState) =>
    state.configurateurFixations?.fixationsData?.panneauFixation?.data
      ?.map((fixation) => fixation?.kitPrice)
      ?.reduce((a, b) => a + b, 0)
  );
  const supervisionPrice = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected?.price || 0
  );
  const garantiePrice = useSelector(
    (state: RootState) =>
      state.configurateurGaranties?.garantiesData?.selected?.price || 0
  );
  const batteryPrice = useSelector(
    (state: RootState) =>
      state.configurateurBatteries?.batteriesData?.selected?.price || 0
  );
  const helpPrice = useSelector(
    (state: RootState) =>
      state.configurateurHelps?.helpsData?.selected?.price || 0
  );

  const accessoiresPrice = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
        ?.totalPrice || 0
  );

  return (
    panelPrice +
    onduleurPrice +
    fixationPrice +
    supervisionPrice +
    garantiePrice +
    batteryPrice +
    helpPrice +
    accessoiresPrice
  );
};
