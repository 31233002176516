import styled from "styled-components";
import { motion } from "framer-motion";
import { theme } from "../../Styled/GlobalStyle";

export const containerVariants = {
  initial: { y: "100%" },
  expanded: {
    y: "0%",
    height: "100%",
    transition: {
      y: { duration: 1, ease: "easeInOut" },
      height: { duration: 1, ease: [0.23, 1, 0.32, 1] },
    }, // Ease Out Quart
  },
  collapsed: {
    y: "0%",
    height: "fit-content",
    transition: {
      y: { duration: 1, ease: "easeInOut" },
      height: { duration: 1, ease: [0.23, 1, 0.32, 1] },
    }, // Ease Out Quart
  },
};

export const mainVariants = {
  expanded: { opacity: 1, transition: { duration: 0.2, delay: 0.2 } },
  collapsed: { opacity: 0, transition: { duration: 0.2 } },
};

export const KitPreviewContainer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  z-index: 999 !important;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.onyx.onyxPower700};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin: 0;
  padding: 0;
`;

export const KitPreviewBar = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  height: fit-content;

  .virtual-cart-title {
    color: transparent;
    text-shadow: ${({ theme }) => theme.colors.clay.clayPower200} 0 0 10px;
    transition: color 0.2s ease-out;
  }
  .virtual-cart-loader {
    position: absolute;
    width: 20px;
    height: 20px;
    transition: width 0.2s ease-out;
  }
`;

export const KitPreviewMain = styled(motion.div)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  gap: 1rem;
`;

export const KitPreviewSection = styled(motion.div)<{ isexpanded?: string }>`
  * {
    color: ${({ theme }) => theme.colors.clay.clayPower200};
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  //padding: 5px;

  div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;

    p {
      color: ${({ theme }) => theme.colors.clay.clayPower600};
    }
  }

  &.main {
    border-right: 1px solid ${({ theme }) => theme.colors.clay.clayPower700};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-right: 15px;
  }

  &.full {
    align-items: flex-start;
    padding-left: 24px;
  }

  .icon-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    img {
      transform: ${(props) =>
        props.isexpanded === "true"
          ? "rotateY(180deg) rotateX(180deg)"
          : "rotateY(0deg) rotateX(0deg)"};
      transition: transform 0.5s ease-in-out;
    }
  }
`;
