import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody6, OH3 } from "../../../../../Styled/Texts";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";
import { SectionBatteryData } from "./constants";
import { SectionBattery } from "./styled";

import { motion } from "framer-motion";
import { configurateurBatterySelect } from "../../../../../../store/configurateur/batteries/configurateur-batteries-reducer";
import { BatteryEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { configurateurHelpSelect } from "../../../../../../store/configurateur/helps/configurateur-helps-reducer";
import { SelectableRadioCard } from "../../../../../Styled/selectable-radio-card copy/selectable-radio-card";
import { formatNumber } from "../../../../../../utils/convert-number-locale";

const BatteryComponent: React.FC<{
  initial: SectionBatteryData;
}> = ({ initial }) => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.BATTERY;
  const { t } = useTranslation([currentStepName, "global"]);

  const batteries = useSelector((state: RootState) => {
    return state.configurateurBatteries?.batteriesData?.data;
  });
  const selected = useSelector((state: RootState) => {
    return state.configurateurBatteries?.batteriesData?.selected;
  });

  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <SectionBattery
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <motion.div className="section__title__col">
        <OH3 center="true">
          <Trans>
            {t("phase_2.sections." + currentSectionName + ".title")}
          </Trans>
        </OH3>
        <OBody6
          className="conception-configuration-batterie-viewmore"
          center="true"
          onClick={() => setPopup(ConfigurateurPopups.popupBattery)}
        >
          {t("phase_2.sections." + currentSectionName + ".info.label")}
        </OBody6>
      </motion.div>

      {batteries?.length ? (
        <>
          <SelectableRadioCard
            checked={!selected}
            label={t("phase_2.sections.battery.none")}
            onClick={() => {
              store.dispatch(configurateurBatterySelect(null));
            }}
          />
          {batteries.map((battery) => {
            const isSelected =
              selected?.id_product_attr === battery?.id_product_attr;

            return (
              <SelectableRadioCard
                checked={isSelected}
                label={battery?.name}
                trailingLabel={`${formatNumber(battery?.kitPrice)}`}
                onClick={() => {
                  store.dispatch(
                    configurateurBatterySelect(battery?.id_product_attr)
                  );
                }}
              ></SelectableRadioCard>
            );
          })}
        </>
      ) : (
        <OActionButton className="design" disabled={true}>
          {t("battery.unavailableForCurrentConfiguration")}
        </OActionButton>
      )}
    </SectionBattery>
  );
};

export default BatteryComponent;
