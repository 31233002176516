import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  STEP_NEEDS,
  setGlobalCurrentPhase,
  setStepAccessible,
  setStepData,
} from "../../../store/reducer";
import { NeedsContainer } from "./styled";
import { OContainer } from "../../Styled/CustomGrid";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { StepProps } from "../../interface";
import CustomTransition from "../../Styled/CustomTransitions";
import NeedPhase1 from "./phases/phase1/need_phase_1";
import NeedPhase2 from "./phases/phase2/need_phase_2";
import { NeedPhase1ReduxStepData } from "./phases/phase1/constants";
import { NeedPhase2ReduxStepData } from "./phases/phase2/constants";
import { simulationHandleChange } from "../../../store/simulation/simulation-slice";

const Needs: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_NEEDS;
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  const dispatch = useDispatch();

  const [dataPhase1, setDataPhase1] = useState<NeedPhase1ReduxStepData>(
    stepData?.phase1 || { budget: null }
  );
  const [dataPhase2] = useState<NeedPhase2ReduxStepData>(
    stepData?.phase2 || null
  );

  const [phase, setPhase] = useState(stepData.phase || 1);

  useEffect(() => {
    // Handle changes on this phase to refresh simulation if needed
    store.subscribe(simulationHandleChange);
    dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  }, []);


  useEffect(() => {
    dispatch(setGlobalCurrentPhase(phase));
  }, [phase]);

  const handleContinue = (
    dataKey: string,
    dataValue: any,
    nextPhase: number
  ) => {
    // console.log('Budget index ', dataPhase1)
    // console.log('Next phase ', nextPhase)

    if (nextPhase === 0) {
      onContinue();
      return;
    }
    dispatch(
      setStepData({
        step: currentStepName,
        data: { [dataKey]: dataValue, phase: nextPhase },
      })
    );
    setPhase(nextPhase);
  };

  return (
    <NeedsContainer>
      <OContainer fluid>
        <CustomTransition mode="fade" key={phase}>
          {phase === 1 ? (
            <NeedPhase1
              data={dataPhase1}
              setData={setDataPhase1}
              onContinue={() => handleContinue("phase1", dataPhase1, phase + 1)}
            />
          ) : null}

          {phase === 2 ? (
            <NeedPhase2
              onContinue={onContinue}
              onReturn={() => handleContinue("phase2", dataPhase2, phase - 1)}
            />
          ) : null}
        </CustomTransition>
      </OContainer>
    </NeedsContainer>
  );
};

export default Needs;
