import { AnimatePresence } from "framer-motion";
import Installation from "./Steps/Installation";
import Needs from "./Steps/Needs";
import Design from "./Steps/Design";
import Recommandation from "./Steps/Recommandation";
import Localization from "./Steps/Localization";
import Header from "./shared/Headers";
import Home from "./Home";
import CustomTransition from "./Styled/CustomTransitions";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentStep } from "../store/reducer";
import { IStepComponents } from "./interface";
import { useTrackingPageView } from "../utils/tracking/tracking-hook";
import { BuilderRouterContainer } from "./Styled/BuilderRouterContainer";

/**
 * `BuilderRouter` is responsible for managing and rendering a series of steps in a multi-step process.
 * Each step is represented by a separate React component, and the flow between steps is controlled by Redux.
 *
 * The state of the current step is maintained in a Redux store. When the user completes a step, the `handleContinue`
 * function is called, updating the `currentStep` in the Redux store to the next step, triggering a re-render of
 * `BuilderRouter` with the next step.
 */
function BuilderRouter() {
  // Get current step from redux
  const currentStep = useSelector((state: any) => state.step.currentStep);
  const dispatch = useDispatch();

  // Handle continue button to change current step
  const handleContinue = (nextStep: string) => {
    dispatch(setCurrentStep(nextStep));
  };
  useTrackingPageView();
  // Object with all steps components
  const stepComponents: IStepComponents = {
    home: <Home onContinue={() => handleContinue("localization")} />,
    localization: (
      <Localization onContinue={() => handleContinue("installation")} />
    ),
    installation: <Installation onContinue={() => handleContinue("needs")} />,
    needs: <Needs onContinue={() => handleContinue("recommandation")} />,
    recommandation: (
      <Recommandation onContinue={() => handleContinue("design")} />
    ),
    design: <Design onContinue={() => handleContinue("end")} />,
  };

  const renderCurrentStep = () => (
    <AnimatePresence mode="wait">
      <CustomTransition mode="fade" key={currentStep}>
        {stepComponents[currentStep]}
      </CustomTransition>
    </AnimatePresence>
  );

  return (
    <BuilderRouterContainer>
      <Header currentStep={currentStep} handleContinue={handleContinue} />
      <div className="h-100 overflow-hidden">{renderCurrentStep()}</div>
    </BuilderRouterContainer>
  );
}

export default BuilderRouter;
