import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceCenter,
  forceCollide,
} from "d3";
import { EquipementsData, needPhase2Config } from "./constants";
import { select } from "d3";
import Mustache from "mustache";

export const createSunWithEquipmentAroundMovable = (
  svgElement: SVGSVGElement | null,
  selectedEquipments: EquipementsData[]
) => {
  // Define the data
  const svg = select(svgElement);
  const config = needPhase2Config.simulation;
  const cardTemplate = needPhase2Config.cardTemplate;
  const sunTemplate = needPhase2Config.sunTemplate;

  // Found the center of the svg
  const width = svgElement.parentElement?.offsetWidth;
  const height = svgElement.parentElement?.offsetHeight;
  const center = {
    x: width / (config.CENTER_X_RATIO as any),
    y: height / (config.CENTER_Y_RATIO as any),
  };

  // Create the sun node
  const sunNode = { id: "sun", fx: center.x, fy: center.y, fixed: true };

  // Create the other nodes
  const equipmentCounters: { [type in any]: number } = {};
  let dynamicNodeDistance;
  let ratio = 1.1;

  if (selectedEquipments.length === 2) {
    ratio = 0.7;
  }
  if (selectedEquipments.length === 3) {
    ratio = 0.9;
  }
  dynamicNodeDistance =
    config.NODE_DISTANCE * Math.sqrt(selectedEquipments.length) * ratio;

  const otherNodes = selectedEquipments.map((equipment, i) => {
    equipmentCounters[equipment.name] =
      (equipmentCounters[equipment.name] || 0) + 1;

    const angle = (i / selectedEquipments.length) * config.NODE_SPREAD;
    const x = center.x + dynamicNodeDistance * Math.cos(angle);
    const y = center.y + dynamicNodeDistance * Math.sin(angle);
    return {
      id: `${equipment.name}-${equipmentCounters[equipment.name]}`,
      equipment,
      x,
      y,
    };
  });

  // Merge in order the nodes
  const nodes = [sunNode, ...otherNodes];

  // Defines basic links by default
  const links = otherNodes.map((node) => ({
    source: sunNode.id,
    target: node.id,
  }));

  // Defines simulation
  const simulation = forceSimulation(nodes as any)
    .force("charge", forceManyBody().strength(config.SIMULATION_FORCE))
    .force("center", forceCenter(center.x, center.y))
    .force(
      "collision",
      forceCollide(dynamicNodeDistance / selectedEquipments.length)
    )
    .force(
      "gravity",
      forceCenter(center.x, center.y).strength(
        config.SIMULATION_GRAVITY_STRENGTH
      )
    )
    .force(
      "link",
      forceLink(links)
        .id((d: any) => d.id)
        .distance(config.SIMULATION_LINK_DISTANCE)
        .strength(config.SIMULATION_LINK_FORCE)
    )
    .on("tick", ticked);

  const link = svg
    .selectAll(".link")
    .data(links)
    .enter()
    .append("path")
    .attr("class", "link");

  const node = svg
    .selectAll(".node")
    .data(nodes)
    .enter()
    .append("g")
    .attr("class", "node");
    /*.call(
      drag().on("start", dragstarted).on("drag", dragged).on("end", dragended)
    );

  function dragstarted(event: any, d: any) {
    if (!event.active)
      simulation.alphaTarget(config.SIMULATION_ALPHA_TARGET_START).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(event: any, d: any) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended(event: any, d: any) {
    if (!event.active)
      simulation.alphaTarget(config.SIMULATION_ALPHA_TARGET_END);
    d.fx = null;
    d.fy = null;
  }*/

  node
    .filter((d) => d.id === "sun")
    .append("foreignObject")
    .attr("width", config.SUN_WIDTH)
    .attr("height", config.SUN_HEIGHT)
    .attr("x", -config.SUN_WIDTH / 2)
    .attr("y", -config.SUN_HEIGHT / 2)
    .attr("pointer-events", "none")
    .style("-webkit-user-select", "none")
    .html((d: any) => {
      return `${sunTemplate}`;
    });

  node
    .filter((d) => d.id !== "sun")
    .append("foreignObject")
    .attr("width", config.CARD_WIDTH)
    .attr("height", config.CARD_HEIGHT)
    .attr("x", -config.CARD_WIDTH / 2)
    .attr("y", -config.CARD_HEIGHT / 2)
    .html((d: any) => {
      try {
        const rendered = Mustache.render(cardTemplate, {
          name: d.equipment.name,
          link: d.equipment.link,
          power: d.equipment.power,
          label: d.equipment.label,
          unit: d.equipment.unit,
        });
        return rendered;
      } catch (e) {
        console.error("Error rendering equipment card: ", e);
        return "";
      }
    });

  function ticked() {
    node.attr("transform", function (d: any) {
      if (d.x && d.y) {
        return "translate(" + d.x + "," + d.y + ")";
      }
    });

    link.attr("d", function (d: any) {
      const dx = d.target.x - d.source.x;
      const dy = d.target.y - d.source.y;
      const numSteps = Math.max(
        Math.round(Math.sqrt(dx * dx + dy * dy) / 150),
        1
      ); // au moins une marche
      const stepX = dx / numSteps;
      const stepY = dy / numSteps;
      const baseRadius = 5; // rayon de base du quart de cercle
      let path = `M${d.source.x},${d.source.y}`;

      for (let i = 1; i <= numSteps; i++) {
        const x = d.source.x + i * stepX;
        const y = d.source.y + i * stepY;

        let sweepFlagX = 0;
        let sweepFlagY = 0;
        let r = Math.min(Math.abs(stepX), Math.abs(stepY), baseRadius); // ajuste le rayon en fonction de la longueur des segments

        if ((dx > 0 && dy > 0) || (dx < 0 && dy < 0)) {
          sweepFlagX = 1;
          sweepFlagY = 0;
        } else if ((dx < 0 && dy > 0) || (dx > 0 && dy < 0)) {
          sweepFlagX = 0;
          sweepFlagY = 1;
        }

        path += `L${x - r * (dx > 0 ? 1 : -1)},${y - stepY}
                 A${r},${r} 0 0,${sweepFlagX} ${x},${
          y - stepY + r * (dy > 0 ? 1 : -1)
        }
                 L${x},${y - r * (dy > 0 ? 1 : -1)}
                 A${r},${r} 0 0,${sweepFlagY} ${
          x + r * (dx > 0 ? 1 : -1)
        },${y}`;
      }

      path += `L${d.target.x},${d.target.y}`;
      return path;
    });
  }

  simulation.on("tick", ticked);

  return simulation;
};
