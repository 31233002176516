import React, { useState } from "react";
import { STEP_DESIGN, STEP_RECOMMANDATION } from "../../../store/reducer";

import { Trans, useTranslation } from "react-i18next";
import {
  KitPreviewBar,
  KitPreviewContainer,
  KitPreviewMain,
  KitPreviewSection,
  containerVariants,
  mainVariants,
} from "./styled";
import { OBody9, OH4 } from "../../Styled/Texts";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useIsMobile from "../../../utils/useIsMobile";
import { RecommandationReduxStepData } from "../../Steps/Recommandation/constants";
import { useVirtualCartTotal } from "../../../store/configurateur/virtual-cart/use-virtual-cart-total-hook";
import { VirtualCartBody } from "./body/virtual-cart-body";
import { assetsPath, svgsPath } from "../../../utils/constants";
import { useVirtualCartKitPower } from "../../../store/configurateur/virtual-cart/use-virtual-cart-kit-power";
import { useVirtualCartEconomy } from "../../../store/configurateur/virtual-cart/use-virtual-cart-economy";
import { useVirtualCartKitProfitability } from "../../../store/configurateur/virtual-cart/use-virtual-cart-kit-profitability";
import { formatNumber } from "../../../utils/convert-number-locale";
import { useConfigurationisLoading } from "../../../store/configurateur/use-configuration-is-loading";

/**
 * The component displays total price, power, profitability, and savings of the kit.
 * It allows users to expand or collapse the information shown based on their preferences.
 * This component is designed to not render on mobile devices.
 */
const KitPreview: React.FC<{
  full?: boolean;
  isExpanded?: boolean;
  setIsExpanded;
}> = ({ full = false, isExpanded = false, setIsExpanded }) => {
  const currentStepName = STEP_DESIGN;
  const { t } = useTranslation([currentStepName, "global"]);

  const stepData = useSelector(
    (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
  ) as RecommandationReduxStepData;
  const isMobile = useIsMobile();
  const defaultValues = "...";

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };
  const virtualCartTotal = useVirtualCartTotal();

  const [displayData] = useState({
    totalPrice: stepData.phase2.mapping.stats.prix,
    panelsPower: stepData.phase2.mapping.potential.annualProduction,
    rentability: stepData.phase2.mapping.profit.resale,
    savings: stepData.phase2.mapping.profit.savings,
    panelsNumber: stepData.phase2.mapping.stats.panelsNumber,
  });
  const kitPower = useVirtualCartKitPower();
  const economy = useVirtualCartEconomy();
  const savings = useVirtualCartKitProfitability(virtualCartTotal, economy);
  const isLoading = useConfigurationisLoading();

  if (isMobile || !virtualCartTotal) {
    return null;
  }

  return (
    <KitPreviewContainer
      className={isExpanded ? "expanded" : ""}
      variants={containerVariants}
      initial="initial"
      animate={isExpanded ? "expanded" : "collapsed"}
    >
      {isExpanded ? (
        <KitPreviewMain
          variants={mainVariants}
          initial="collapsed"
          animate={isExpanded ? "expanded" : "collapsed"}
        >
          <VirtualCartBody />
        </KitPreviewMain>
      ) : (
        <motion.span></motion.span>
      )}
      <KitPreviewBar>
        <KitPreviewSection
          className={full ? "main" : "full"}
          isexpanded={isExpanded.toString()}
        >
          {full && (
            <motion.div
              className="icon-switch"
              style={{ backgroundColor: "white" }}
              onClick={handleCardClick}
            >
              <img src={svgsPath + "swip-up-black.svg"} alt="" />
            </motion.div>
          )}
          <div>
            <OH4 className={`${isLoading ? "virtual-cart-title" : ""}`}>
              <Trans>{formatNumber(virtualCartTotal) || "0"}</Trans>
            </OH4>
            {isLoading && (
              <img
                className="virtual-cart-loader"
                src={assetsPath + "loader/loader.gif"}
                alt=""
              />
            )}
          </div>
        </KitPreviewSection>
        <KitPreviewSection>
          {full && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>
                    {t("preview.solar_kit.title", {
                      value: displayData.panelsNumber ?? defaultValues,
                    })}
                  </Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {t("preview.solar_kit.unit", {
                    value: formatNumber(kitPower, 3, false) ?? defaultValues,
                  })}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
        <KitPreviewSection>
          {full && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>{t("preview.rentability.title")}</Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {t("preview.rentability.unit", {
                    value: savings,
                  })}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
        <KitPreviewSection>
          {full && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>{t("preview.economy.title")}</Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {t("preview.economy.unit", {
                    value: economy ? formatNumber(economy, 0) : defaultValues,
                  })}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
      </KitPreviewBar>
    </KitPreviewContainer>
  );
};

export default KitPreview;
