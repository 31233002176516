import { useTranslation } from "react-i18next";
import "./supervision-tooltip.css";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { OBody8 } from "../../../../../Styled/Texts";
import { OTooltip } from "../../../../../Styled/tooltip/styled";

export const SupervisionTooltip = () => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  return (
    <OTooltip className="supervision-tooltip conception-configuration-supervision-tooltip">
      <OBody8>{t("phase_2.sections.supervising.tooltip")}</OBody8>
    </OTooltip>
  );
};
