import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody6, OH3 } from "../../../../../Styled/Texts";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";
import { SectionWarrantyData } from "./constants";
import { SectionWarranty } from "./styled";
import { GarantieEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import { configurateurGarantiesSelect } from "../../../../../../store/configurateur/garanties/configurateur-garanties-reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { formatNumber } from "../../../../../../utils/convert-number-locale";

const WarrantyComponent: React.FC<{
  initial: SectionWarrantyData;
}> = () => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.WARRANTY;
  const { t } = useTranslation([currentStepName, "global"]);

  const garanties = useSelector(
    (state: RootState) => state.configurateurGaranties?.garantiesData?.data
  ) as GarantieEntity[];

  const garantie = useSelector(
    (state: RootState) => state.configurateurGaranties?.garantiesData?.selected
  ) as GarantieEntity;

  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    garanties?.length > 0 && (
      <SectionWarranty
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={sectionVariants}
        id={currentSectionName + "_anchor"}
      >
        <motion.div className="section__title__col">
          <OH3 center="true">
            <Trans>
              {t("phase_2.sections." + currentSectionName + ".title")}
            </Trans>
          </OH3>
          <OBody6
            className="conception-configuration-garantie-viewmore"
            center="true"
            onClick={() => setPopup(ConfigurateurPopups.popupWarranty)}
          >
            {t("phase_2.sections." + currentSectionName + ".info.label")}
          </OBody6>
        </motion.div>
        <motion.div className="section__buttons">
          <OActionButton
            className="design"
            isactive={
              garantie === undefined || garantie === null ? "true" : "false"
            }
            onClick={() => {
              store.dispatch(configurateurGarantiesSelect(false));
            }}
          >
            {t("without")}
          </OActionButton>
          <OActionButton
            className="design"
            isactive={
              garantie === undefined || garantie === null ? "false" : "true"
            }
            onClick={() => {
              store.dispatch(configurateurGarantiesSelect(true));
            }}
            sublabel={`+${formatNumber(garanties[0]?.kitPrice, 0)}`}
          >
            {t("with")}
          </OActionButton>
        </motion.div>
      </SectionWarranty>
    )
  );
};

export default WarrantyComponent;
