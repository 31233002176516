import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { OBody8, OH3 } from "../../../../../Styled/Texts";
import { SectionCalepinageHorsToitureType } from "./styled";
import { OTinyButtonCalepinage } from "../../../../../Styled/Buttons";
import { ORow, OCol } from "../../../../../Styled/CustomGrid";
import {
  setPanelCountInCalepinage,
  setMatriceCalepinage as setMatriceCalepinageReducer,
} from "../../../../../../store/calepinage/calepinageReducer";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { createMinimizedMatrix } from "../../../../../../utils/create-minimized-matrix";
import MatrixViewer from "../../../../../shared/matrix_viewer";
import { OInfoCard } from "../../../../../Styled/info-card/o-info-card";
import { theme } from "../../../../../Styled/GlobalStyle";

const CalepinageToitureMobileComponent: React.FC = () => {
  const dispatch = useDispatch();
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.CALEPINAGE_TOITURE_MOBILE;
  const { t } = useTranslation([currentStepName, "global"]);

  const recommandationCount = useSelector(
    (state: RootState) => state.calepinageReducer.recommandationCount
  );
  /*const fixationSelected = useSelector(
    (state: RootState) => state.calepinageReducer.fixationSelected
  );*/
  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );
  const rooforientationCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.dispositionPanneaux
  );
  const dispatchPanelCountInCalepinage = (count) =>
    dispatch(setPanelCountInCalepinage(count));

  /*const dataRecommandation = useSelector(
    (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
  ) as RecommandationReduxStepData;*/

  const [matriceCalepinage, setMatriceCalepinage] = useState([[]]);

  const [count, setCount] = useState(panelCountInCalepinage);

  const dispatchNewPanelCount = useRef(
    debounce((e) => {
      dispatchPanelCount(e);
    }, 600)
  ).current;

  const decrement = useCallback((newCount) => {
    setCount(newCount);
    dispatchNewPanelCount(newCount);
  }, []);

  const increment = useCallback((newCount) => {
    setCount(newCount);
    dispatchNewPanelCount(newCount);
  }, []);

  const dispatchPanelCount = (count: number) => {
    dispatchPanelCountInCalepinage(count);
  };

  // useEffect(() => {
  //   if (isFixationAvailableForNewCount(panelCountInCalepinage)) {
  //     generateFixationList(panelCountInCalepinage, fixationId);
  //   } else {
  //     increment(panelCountInCalepinage + 1);
  //   }
  // }, []);

  useEffect(() => {
    const matrice = createMinimizedMatrix(count);
    setMatriceCalepinage(matrice);
    dispatch(setMatriceCalepinageReducer(matrice));
  }, [count]);

  useEffect(() => {
    if (panelCountInCalepinage == null) {
      setCount(recommandationCount);
      dispatchPanelCountInCalepinage(recommandationCount);
    }
  }, [
    dispatchPanelCountInCalepinage,
    panelCountInCalepinage,
    recommandationCount,
  ]);

  return (
    <SectionCalepinageHorsToitureType
      initial="hidden"
      animate="visible"
      exit="exit"
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true" className="mt-1">
        <Trans>{t("phase_2.sections." + currentSectionName + ".title")}</Trans>
      </OH3>
      <ORow className="align-items-center">
        <OCol className="d-flex align-items-center justify-content-end">
          <p>
            <Trans>
              {t("phase_2.sections." + currentSectionName + ".quantity")}
            </Trans>
          </p>
        </OCol>
        <OCol className="d-flex align-items-center justify-content-start">
          <OTinyButtonCalepinage
            mode="minus"
            background="none"
            onClick={() => {
              decrement(count - 1);
            }}
            disabled={count <= 1}
          />
          <OH3>{count}</OH3>
          <OTinyButtonCalepinage
            mode="plus"
            background="none"
            onClick={() => {
              increment(count + 1);
            }}
            disabled={count >= 25}
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol>
          <MatrixViewer
            matrix={matriceCalepinage}
            orientation={rooforientationCalepinage}
          ></MatrixViewer>
        </OCol>
      </ORow>
      <OInfoCard
        backgroundColor={theme.colors.secondary.yellowPower400}
        width="100%"
      >
        <OBody8>
          {t(
            "phase_2.sections." + currentSectionName + ".warning_not_optimized"
          )}
        </OBody8>
      </OInfoCard>
    </SectionCalepinageHorsToitureType>
  );
};

export default CalepinageToitureMobileComponent;
