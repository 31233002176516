import styled from "styled-components";
import { PanelEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import { OBody10, OBody11, OBody12, OBody8 } from "../../../../../Styled/Texts";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { svgsPath } from "../../../../../../utils/constants";

export const PanelDetailItem = (props: { panel: PanelEntity }) => {
  const panel = props.panel;

  return (
    <div className="flex">
      {panel?.configurator_label ? (
        <PanelDetailItemLabelContainer>
          <OBody11>{panel?.configurator_label}</OBody11>
        </PanelDetailItemLabelContainer>
      ) : (
        <PanelDetailItemLabelContainer backgroundColor="none">
          <OBody11>&nbsp;</OBody11>
        </PanelDetailItemLabelContainer>
      )}
      <PanelDetailItemContainer>
        <img
          className="panel-detail-img"
          src={panel?.image}
          alt={panel?.description}
        />
        <OBody8>{panel?.configurator_name}</OBody8>
        <OBody12>{panel?.manufacturer_name}</OBody12>
        <OBody10>{formatNumber(panel?.price, 0)}</OBody10>
        {panel?.powerWcFeature && (
          <>
            <PanelDetailFeature>
              <img
                src={`${svgsPath}/shield-elec.svg`}
                alt={panel?.configurator_name}
              />
              <OBody10>{panel?.powerWcFeature?.value} Wc</OBody10>
              <OBody12>Puissance</OBody12>
            </PanelDetailFeature>
          </>
        )}
        {panel?.warrantyFeature && (
          <>
            <PanelDetailFeature>
              <img
                src={`${svgsPath}/warranty.svg`}
                alt={panel?.configurator_name}
              />
              <OBody10>{panel?.warrantyFeature?.value} ans</OBody10>
              <OBody12>Garantie produit</OBody12>
            </PanelDetailFeature>
          </>
        )}
        {panel?.dimensionFeature && (
          <>
            <PanelDetailFeature>
              <img
                src={`${svgsPath}/dimension.svg`}
                alt={panel?.configurator_name}
              />
              <OBody10>{panel?.dimensionFeature?.value}</OBody10>
              <OBody12>{panel?.dimensionFeature?.title}</OBody12>
            </PanelDetailFeature>
          </>
        )}
      </PanelDetailItemContainer>
    </div>
  );
};

const PanelDetailItemLabelContainer = styled.div<{ backgroundColor?: string }>`
  text-align: center;
  margin-right: 16px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.tertiary.leafPower700};
  border-radius: 4px;
  p {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.clay.clayPower200};
  }
`;

const PanelDetailItemContainer = styled.div`
  border: 2px solid ${(props) => props.theme.colors.clay.clayPower500};
  border-radius: 12px;
  padding: 16px;
  margin-top: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: center;

  .panel-detail-img {
    height: 120px;
    padding-bottom: 8px;
    max-width: fit-content;
  }
`;

const PanelDetailFeature = styled.div`
  margin-top: 24px;
`;
