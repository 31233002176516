// ENV
// TODO detect from hostname
export const isProd = false;

const location = window.location;
const href = location.href;
const hostName = location.hostname;

//------------------
let webUrl = location.origin;
let apiUrl = "https://dev.oscaro-power.com"; // DEV API by Default
let buildRootPath = "/"; // root by default
let configurateurBaseUrl = "https://cfg.oscaro-power.com"; // DEV Configurateur by default
let dimensionneurBaseUrl = "https://dim.oscaro-power.com"; // DEV Dimensionneur by default
//------------------

const API_GEOCODING_KEY_DEV =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGx5eHJ4MmoycmsyM2NwODMzaWp4ODFxIn0.jW4d1ap8A2n9lqWKX-rJyg"; // dev@oscaro-power.com
const API_GEOCODING_KEY_PROD =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGt0Z3VvOWEwOWdsM2Zxb3Rnb3E0ZWZ0In0.N28W4Govyb24ddlQrvjTxA"; // petru.magdeleine@oscaro-power.com

export let API_GEOCODING_KEY = API_GEOCODING_KEY_DEV;

//------------------

const PETRU_LOCAL_ENV = href.indexOf("localhost:8888/preprod-20220707/") !== -1;
const DEVELOP = hostName.indexOf("dev.oscaro-power.com") !== -1;
const PREPROD = hostName.indexOf("preprod.oscaro-power.com") !== -1;
export const PRODUCTION = hostName.indexOf("www.oscaro-power.com") !== -1;

// const PETRU_LOCAL_ENV = 0;
// const DEVELOP = 0;
// const PREPROD = 0;
// const PRODUCTION = 1;

if (PETRU_LOCAL_ENV) {
  webUrl = "http://localhost:8888/preprod-20220707";
  apiUrl = "https://dev.oscaro-power.com";
  buildRootPath = webUrl + "/public/configurateur-app/";
  configurateurBaseUrl = "https://cfg.oscaro-power.com";
} else if (DEVELOP) {
  webUrl = "https://dev.oscaro-power.com";
  apiUrl = "https://dev.oscaro-power.com";
  buildRootPath = webUrl + "/public/configurateur-app/";
  configurateurBaseUrl = "https://cfg.oscaro-power.com";
  dimensionneurBaseUrl = "https://dim.oscaro-power.com";
} else if (PREPROD) {
  webUrl = "https://preprod.oscaro-power.com";
  apiUrl = "https://preprod.oscaro-power.com";
  buildRootPath = webUrl + "/public/configurateur-app/";
  configurateurBaseUrl = "https://cfgpreprod.oscaro-power.com";
  dimensionneurBaseUrl = "https://dim.oscaro-power.com";

  API_GEOCODING_KEY = API_GEOCODING_KEY_PROD;
} else if (PRODUCTION) {
  webUrl = "https://www.oscaro-power.com";
  apiUrl = "https://www.oscaro-power.com";
  buildRootPath = webUrl + "/public/configurateur-app/";
  configurateurBaseUrl = "https://configurateur.oscaro-power.com";
  dimensionneurBaseUrl = "https://dimensionneur.oscaro-power.com";

  API_GEOCODING_KEY = API_GEOCODING_KEY_PROD;
}

// console.log("webUrl: ", webUrl, " - buildRootPath: ", buildRootPath);

export const assetsPath = buildRootPath + "assets/";
export const fontsPath = buildRootPath + "fonts/";
export const imgsPath = buildRootPath + "imgs/";
export const svgsPath = buildRootPath + "svgs/";

//------------------

// API
const urlParams = "ws_key=AUQPDAXNNL67N18H4HWVS8AKK2EQ53NG&output_format=JSON";

export const API_DEV_TOKEN = "ZGV2OlF1YXJhbnRhaW5l";
export const API_PRODUCTS = apiUrl + "/api/products/?" + urlParams;
export const API_OSCAROPOWER = apiUrl + "/api/oscaropower/?" + urlParams;
export const API_SETTINGS = API_OSCAROPOWER + "&display=settings";

export const CONFIGURATEUR_URL = configurateurBaseUrl;
export const DIMENSIONNEUR_URL = dimensionneurBaseUrl;

// DEALT
const dealtUrlParams =
  "ws_key=6RE26JSYHD2NMXDGSB4GXPD8SMZYZU1E&output_format=JSON";
export const API_DEALT_OFFERS =
  apiUrl + "/api/dealtlight-op-offers/?" + dealtUrlParams;
export const API_CART = (cartId: number, data: string) =>
  apiUrl +
  `/api/carts/${cartId}?${urlParams}&ps_method=PUT&json_to_xml=${data}`;

export const API_CART_DEALT =
  apiUrl +
  "/api/dealtlight-cart-offer-references?ws_key=6RE26JSYHD2NMXDGSB4GXPD8SMZYZU1E&output_format=JSON";

// CART
export const URL_CART = webUrl + "/panier?action=show";

//Localization
export const ZOOM_DEFAULT = 5;
// export const API_GEOCODING_KEY = "pk.ebf8b8698c7db3d284490dfe85dc834c"; // dev@oscaro-power.com
// export const API_GEOCODING_KEY_PROD = "pk.8a35fe281bf9afee31b1b821d921bfff"; // petru.magdeleine@oscaro-power.com
export const API_GEOCODING_LIMIT = 10;
export const API_GEOCODING_COUNTRY = "fr,be";
export const API_GEOCODING_LANG = "fr";

//MapBox
export const PUBLIC_KEY_MAPBOX =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGt0Z3VvOWEwOWdsM2Zxb3Rnb3E0ZWZ0In0.N28W4Govyb24ddlQrvjTxA";
export const API_MAPBOX_GEOCODING = "https://api.mapbox.com/geocoding/v5";
