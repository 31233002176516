import { createSlice, combineReducers } from "@reduxjs/toolkit";
import {
  InstallationReduxStepData,
  installationConfig,
} from "../components/Steps/Installation/constants";
import { needPhase2Config } from "../components/Steps/Needs/phases/phase2/constants";
import { needPhase1Config } from "../components/Steps/Needs/phases/phase1/constants";
import {
  LocalizationReduxStepData,
  localizationConfig,
} from "../components/Steps/Localization/constants";
import { designPhase1Config } from "../components/Steps/Design/phases/phase1/constants";
import { designPhase2Config } from "../components/Steps/Design/phases/phase2/constants";
import _ from "lodash";
import { NeedReduxStepData } from "../components/Steps/Needs/constants";
import { DesignReduxStepData } from "../components/Steps/Design/constant";
import simulationReducer from "./simulation/simulation-slice";
import { recommandationPhase1Config } from "../components/Steps/Recommandation/phases/phase1/constants";
import { RecommandationPhase2Config } from "../components/Steps/Recommandation/phases/phase2/constants";
import { RecommandationReduxStepData } from "../components/Steps/Recommandation/constants";
import { configurationPanelsReducer } from "./configurateur/panels/configurateur-panels-reducer";
import { calepinageReducer } from "./calepinage/calepinageReducer";
import { besoinsReducer } from "./besoins/besoins/besoins-reducer";
import { configurationOnduleursReducer } from "./configurateur/onduleurs/configurateur-onduleurs-reducer";
import { configurationSupervisionReducer } from "./configurateur/supervision/configurateur-supervision-reducer";
import { configurationAccessoriesReducer } from "./configurateur/accessories/configurateur-accessories-reducer";
import { configurationBatteriesReducer } from "./configurateur/batteries/configurateur-batteries-reducer";
import { configurationHelpsReducer } from "./configurateur/helps/configurateur-helps-reducer";
import { configurationGarantiesReducer } from "./configurateur/garanties/configurateur-garanties-reducer";
import { configurationInstallHelpsReducer } from "./configurateur/install-helps/configurateur-install-helps-reducer";
import { configurationAlimentationReducer } from "./configurateur/alimentation/configurateur-alimentation-reducer";
import { mapBoxReducer } from "./calepinage/mapBoxReducer";
import { calepinageHMReducer } from "./calepinage/calepinageHorsMapReducer";
import { localizationSlice } from "./localization/localization-reducer";
import { configurationFixationsReducer } from "./configurateur/fixations/configurateur-fixations-reducer";

export const devMode = false;
const initalAccess = devMode ? true : false;

export const STEP_HOME = "home";
export const STEP_LOCALIZATION = "localization";
export const STEP_LOCALIZATION_PHASE = !devMode ? 1 : 1;
export const STEP_INSTALLATION = "installation";
export const STEP_INSTALLATION_PHASE = !devMode ? 1 : 1;
export const STEP_NEEDS = "needs";
export const STEP_NEEDS_PHASE = !devMode ? 1 : 1;
export const STEP_DESIGN = "design";
export const STEP_DESIGN_PHASE = !devMode ? 1 : 1;
export const STEP_RECOMMANDATION = "recommandation";
export const STEP_RECOMMANDATION_PHASE = !devMode ? 1 : 1;
export const INITIAL_STEP = !devMode ? STEP_LOCALIZATION : STEP_DESIGN;
export const INITIAL_PHASE = 1;
export interface StepData {
  phase?: number;
}

/**
 * @typedef RootState
 * @property {Object} steps - Stores the data for each step
 * @property {string} currentStep - The current step
 * @property {number} currentPhase - The current phase of the current step
 * @property {Object} steps - Contains objects representing each step
 * @property {any} steps[key].data - The data associated with each step
 * @property {boolean} steps[key].accessible - Whether the step is accessible
 */
interface RootState {
  skip: boolean;
  steps: {
    [key: string]: {
      data: any;
      accessible: boolean;
    };
    [STEP_LOCALIZATION]: {
      data: LocalizationReduxStepData;
      accessible: boolean;
    };
    [STEP_INSTALLATION]: {
      data: InstallationReduxStepData;
      accessible: boolean;
    };
    [STEP_NEEDS]: {
      data: NeedReduxStepData;
      accessible: boolean;
    };
    [STEP_RECOMMANDATION]: {
      data: RecommandationReduxStepData;
      accessible: boolean;
    };
    [STEP_DESIGN]: {
      data: DesignReduxStepData;
      accessible: boolean;
    };
  };
  previousStep?: string;
  previousPhase?: number;
  currentStep: string;
  currentPhase: number;
}

/**
 * initialState: Stores the initial state of the step data
 * @type {RootState}
 */
const initialState: RootState = {
  currentStep: INITIAL_STEP,
  currentPhase: INITIAL_PHASE,
  skip: false,
  steps: {
    [STEP_LOCALIZATION]: {
      data: {
        ...localizationConfig.defaultValues,
        phase: STEP_LOCALIZATION_PHASE,
      },
      accessible: initalAccess,
    },
    [STEP_INSTALLATION]: {
      data: {
        ...installationConfig.defaultValues,
        phase: STEP_INSTALLATION_PHASE,
      },
      accessible: initalAccess,
    },
    [STEP_NEEDS]: {
      data: {
        phase1: {
          ...needPhase1Config.defaultValues,
        },
        phase2: {
          ...needPhase2Config.defaultValues,
        },
        phase: STEP_NEEDS_PHASE,
      },
      accessible: initalAccess,
    },
    [STEP_RECOMMANDATION]: {
      data: {
        phase1: {
          ...recommandationPhase1Config.defaultValues,
        },
        phase2: {
          ...RecommandationPhase2Config.defaultValues,
        },
        phase: STEP_RECOMMANDATION_PHASE,
      },
      accessible: initalAccess,
    },
    [STEP_DESIGN]: {
      data: {
        phase1: {
          ...designPhase1Config.defaultValues,
        },
        phase2: {
          ...designPhase2Config.defaultValues,
          ...designPhase2Config.sectionConfig,
        },
        phase: STEP_DESIGN_PHASE,
      },
      accessible: initalAccess,
    },
  },
};

/**
 * stepSlice: Redux slice that holds the reducer logic for step related actions
 * @type {import('@reduxjs/toolkit').Slice}
 */
const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.previousStep = state.currentStep;
      state.currentStep = action.payload;
    },
    setStepData: (state, action) => {
      _.merge(state.steps[action.payload.step].data, action.payload.data);
    },
    setStepPhaseData: (state, action) => {
      _.merge(
        state.steps[action.payload.step].data[action.payload.phase],
        action.payload.data
      );
    },
    setStepDataWithoutLodash(state, action) {
      state.steps[action.payload.step].data = {
        ...state.steps[action.payload.step].data,
        ...action.payload.data,
      };
    },
    setSectionData: (state, action) => {
      _.merge(
        state.steps[action.payload.step].data.phase2[action.payload.section],
        action.payload.data
      );
    },
    setGlobalCurrentPhase: (state, action) => {
      state.previousPhase = state.currentPhase;
      state.currentPhase = action.payload;
    },
    setStepAccessible: (state, action) => {
      state.steps[action.payload.step].accessible = action.payload.accessible;
    },
    setStepPhase1: (state, action) => {
      state.steps[action.payload.step].data.phase = 1;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
  },
});

export const {
  setCurrentStep,
  setStepData,
  setStepDataWithoutLodash,
  setStepAccessible,
  setSectionData,
  setStepPhase1,
  setGlobalCurrentPhase,
  setSkip,
  setStepPhaseData,
} = stepSlice.actions;

const rootReducer = combineReducers({
  step: stepSlice.reducer,
  simulationReducer: simulationReducer,
  // Configurateur reducers
  configurateurAlimentation: configurationAlimentationReducer,
  configurateurPanels: configurationPanelsReducer,
  calepinageReducer: calepinageReducer,
  calepinageHMReducer: calepinageHMReducer,
  mapBoxReducer: mapBoxReducer,
  besoinsReducer: besoinsReducer, // NON UTILISE - REDUCER EXEMPLE

  configurateurOnduleurs: configurationOnduleursReducer,
  configurateurSupervisions: configurationSupervisionReducer,
  configurateurAccessories: configurationAccessoriesReducer,
  configurateurBatteries: configurationBatteriesReducer,
  configurateurHelps: configurationHelpsReducer,
  configurateurGaranties: configurationGarantiesReducer,
  configurateurInstallHelps: configurationInstallHelpsReducer,
  configurateurFixations: configurationFixationsReducer,
  localization: localizationSlice.reducer,
});

export default rootReducer;
