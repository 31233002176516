import styled from "styled-components";
import { OInput } from "../../../../Styled/Forms";
import { OH1, OH2 } from "../../../../Styled/Texts/OHeading";
import { OBody5 } from "../../../../Styled/Texts/OBody";

export const OInputAmount = styled(OInput)`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 0;
  height: 56px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  & + .suffix {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0px;
    height: 56px;
    top: 0px;
    font-weight: 400;
    font-size: 16px;
    z-index: 20;
    user-select: none;
    padding-right: 10px;
    color: ${(props) => props.theme.colors.onyx.onyxPower500};
  }
`;

export const LabelAmount = styled.label`
  position: absolute;
  font-size: 14px;
  color: ${(props) => props.theme.colors.clay.clayPower700};
  transition: all 0.2s ease-in-out;
  transform: translateY(-0.6rem) scale(0.75);
  left: 1.2rem;
  transform-origin: 0;
  z-index: 10;
  top: 1.1rem;
`;

export const BudgetTitle = styled(OH1)`
    max-width:80%;
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      max-width:100%;
    }
`

export const BudgetBody = styled(OBody5)`
    max-width:80%;
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      max-width:100%;
    }
`
// transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600
// peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4;
