import styled from "styled-components";
import { motion } from "framer-motion";
export const OEquipGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  max-width: 255px;
  grid-auto-rows: 1fr;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: inherit;
  }
`;

export const OEquipCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 2px solid;
  transition: all 0.3s ease-in-out;
  aspect-ratio: 5/6;
  padding: 10px 5px;

  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;

  border-color: ${({ selected, theme }) =>
    selected
      ? theme.colors.primary.bluePower700
      : theme.colors.clay.clayPower600};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    aspect-ratio: auto;
    gap: 0px;
    padding: 10px 0px;
  }
`;

export const OEquipCardHeader = styled.div`
  margin: 0 auto;
  & > img {
    height:40px;
  }
`;

export const OEquipCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  gap: 4px;
  p {
    margin: 0;
    word-break: break-word;

    &:first-of-type {
      width: 75%;
    }
  }
`;

export const EquipmentViewerContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  .link {
    stroke: ${(props) => props.theme.colors.primary.bluePower700};
    stroke-width: 3px;
    fill: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }

  .svgCard {
    padding: 0.5rem;
    height: 100%;
    border-radius: 8px;
    border: 3px solid ${({ theme }) => theme.colors.primary.bluePower700};
    background-color: ${({ theme }) => theme.colors.clay.clayPower200};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    // scale: 0.7; #ERROR ON SAFARI
    transition: all 0.3s ease-in-out;

    .svgCard_img {
      width: 40%;
      height: 40%;
    }

    .svgCard_label {
      font-size: 12px !important;
      font-weight: 400;
      width: 95%;
      margin: 0 auto;
      color: ${({ theme }) => theme.colors.onyx.onyxPower700};
    }

    .svgCard_power {
      margin: 0 auto;
      font-size: 12px !important;
      color: ${({ theme }) => theme.colors.onyx.onyxPower700};
      font-weight: 700;
      font-style: italic;
    }
  }
`;
