import { useTranslation } from "react-i18next";
import "./lightning-arrester-tooltip.css";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { OBody8 } from "../../../../../Styled/Texts";
import { OTooltip } from "../../../../../Styled/tooltip/styled";

export const LightningArresterTooltip = () => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  return (
    <OTooltip className="lightning-arrester-tooltip conception-configuration-supervision-tooltip">
      <OBody8>{t("phase_2.sections.lightning_arrester.tooltip")}</OBody8>
    </OTooltip>
  );
};
