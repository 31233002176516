import { VirtualCartCategoryItem } from "../../../../store/configurateur/virtual-cart/use-virtual-cart-items-hook";
import { formatNumber } from "../../../../utils/convert-number-locale";
import { theme } from "../../../Styled/GlobalStyle";
import { OBody4, OBody8 } from "../../../Styled/Texts";
import { VirtualCartItemContainer } from "./styled";

export const VirtualCartItem = (props: {
  item: VirtualCartCategoryItem;
  itemColor?: {
    category?: string;
    link?: string;
    price?: string;
  };
}) => {
  const item = props.item;

  const categoryColor =
    props.itemColor?.category || theme.colors.clay.clayPower200;
  const priceColor = props.itemColor?.price || theme.colors.clay.clayPower200;
  const linkColor = props.itemColor?.link || theme.colors.clay.clayPower200;
  return (
    <VirtualCartItemContainer>
      <img src={item?.img} alt={item?.label} />
      <div className="virtualCartItemLeading">
        <OBody4 color={categoryColor}>{item?.label}</OBody4>
        {item?.link && (
          <a
            href={item?.link}
            rel="noreferrer"
            target="_blank"
            color={linkColor}
          >
            {item?.linkLabel}
          </a>
        )}
      </div>
      <div className="virtualCartItemTrailing">
        <OBody4 color={priceColor} className="text-nowrap">
          {formatNumber(item?.price)}
        </OBody4>
        {item?.trailingLabel && (
          <OBody8 color={theme.colors.clay.clayPower600}>
            {item.trailingLabel}
          </OBody8>
        )}
      </div>
    </VirtualCartItemContainer>
  );
};
