import { useTranslation } from "react-i18next";
import { OBody8 } from "../../../../../../Styled/Texts";
import { OTooltip } from "../../../../../../Styled/tooltip/styled";
import "./onduleurs-tooltip.css";
import { STEP_DESIGN } from "../../../../../../../store/reducer";
import { Sections } from "../../constants";

export const OnduleursTooltip = () => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  return (
    <OTooltip className="onduleurs-tooltip conception-configuration-onduleur-tooltip">
      <OBody8>
        {t(
          "phase_2.sections." +
            Sections.SOLAR_KIT +
            ".sections.inverter.tooltip"
        )}
      </OBody8>
    </OTooltip>
  );
};
