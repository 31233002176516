import { createContext } from "react";

export enum ConfigurateurPopups {
  popupHelp = "popupHelp",
  popupInstallHelp = "popupInstallHelp",
  popupWarranty = "popupWarranty",
  popupBattery = "popupBattery",
  popupAlimentation = "popupAlimentation",
  popupPanelDetails = "popupPanelDetails",
  none = "popupNone",
}
export interface ConfigurateurPopupContextValue {
  popup: ConfigurateurPopups;
  setPopup?: React.Dispatch<React.SetStateAction<ConfigurateurPopups | null>>;
}
export const ConfigurateurPopupContext = createContext({
  popup: ConfigurateurPopups.none,
  setPopup: null,
});
