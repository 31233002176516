import {
  InstallationType,
  InstallationTypeId,
} from "../../components/Steps/Installation/constants";
import {
  setSkip,
  setStepData,
  STEP_DESIGN,
  setStepAccessible,
  STEP_INSTALLATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  setCurrentStep,
} from "../../store/reducer";

export const skipRecommandation = (dispatch, installationType) => {
  dispatch(setSkip(true));
  dispatch(
    setStepData({
      step: STEP_DESIGN,
      data: {
        phase: 2,
      },
    })
  );
  dispatch(
    setStepAccessible({
      step: STEP_INSTALLATION,
      accessible: false,
    })
  );

  if (!installationType) {
    dispatch(
      setStepData({
        step: STEP_INSTALLATION,
        data: {
          installation: InstallationType.Roof,
          installationId: InstallationTypeId[InstallationType.Roof],
        },
      })
    );
  }

  dispatch(
    setStepAccessible({ step: STEP_NEEDS, accessible: false, data: {} })
  );
  dispatch(
    setStepAccessible({
      step: STEP_RECOMMANDATION,
      accessible: false,
      data: {},
    })
  );
  dispatch(
    setStepAccessible({ step: STEP_DESIGN, accessible: false, data: {} })
  );
  dispatch(setCurrentStep("design"));
};
