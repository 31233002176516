import axios from "axios";
import { DimensionneurDto } from "./dtos/dimensionneur-dto";
import { DIMENSIONNEUR_URL } from "../../../utils/constants";
import _ from "lodash";

export class DimensionneurApi {
  async getReqTab(parameters: {
    budget: number;
    equipements: string[];
    lat: number;
    lng: number;
    angle?: number;
    orientation: number;
    pays: string;
  }): Promise<DimensionneurDto> {
    // Monkey fix for the dimensionneur API
    if (parameters.budget) {
      parameters.budget = _.round(parameters.budget);
    }

    const equipementsParamUrl = parameters.equipements
      .map((equipement) => `équipement=${equipement}`)
      .join("&");
    const params = {
      ...parameters,
      equipements: undefined,
    };
    const response = await axios.get(
      `${DIMENSIONNEUR_URL}/req_tab?${equipementsParamUrl}`,
      {
        params,
      }
    );

    return response.data as DimensionneurDto;
  }
}
