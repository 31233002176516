import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";

import { ConfigurateurPanelsState } from "./configurateur-panels-state";

const initialState: ConfigurateurPanelsState = {
  panelsData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadPanels = "loadPanels",
  selectPanel = "selectPanel",
}

export const configurationPanelsReducer = (
  state = initialState,
  action
): ConfigurateurPanelsState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadPanels:
      return {
        ...state,
        panelsData: {
          data: data || state?.panelsData?.data,
          status: status,
          error: error,
          selected: selected || state?.panelsData?.selected,
        },
      };
    case ActionType.selectPanel:
      return {
        ...state,
        panelsData: {
          ...state.panelsData,
          data: data || state?.panelsData?.data,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurPanelsLoad =
  (panelsNumber: number) => async (dispatch, getStore) => {
    try {
      dispatch({
        type: ActionType.loadPanels,
        status: ComponentStatus.loading,
      });
      const panels = await configurateurService.getPannels();
      const selected =
        getStore().configurateurPanels?.panelsData?.selected ||
        panels.find((panel) => panel.is_oscaro_choice) ||
        panels[0];
      selected.kitPrice = panelsNumber * selected.price;
      selected.kitQuantity = panelsNumber;

      const panelsWithPriceDelta = panels.map((panel) => {
        return {
          ...panel,
          kitPrice: panelsNumber * panel.price,
          priceDelta: panel.price - selected.price,
          kitQuantity: panelsNumber,
        };
      });
      dispatch({
        type: ActionType.loadPanels,
        status: ComponentStatus.loaded,
        data: panelsWithPriceDelta,
        selected: selected,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadPanels,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurOnPanelSelected =
  (panelId: string) => (dispatch, getState) => {
    const panelsData = getState().configurateurPanels.panelsData;
    const newSelectedPanel = panelsData?.data?.find(
      (panel) => panel.id_product === panelId
    );
    const panelsWithPriceDelta = panelsData.data.map((panel) => {
      return {
        ...panel,
        priceDelta: panel.price - newSelectedPanel.price,
      };
    });
    if (
      newSelectedPanel &&
      panelsData?.selected?.id_product !== newSelectedPanel?.id_product
    ) {
      dispatch({
        type: ActionType.selectPanel,
        selected: newSelectedPanel,
        data: panelsWithPriceDelta,
      });
    }
  };
