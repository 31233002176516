import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  setStepAccessible,
  setStepData,
  setStepPhase1,
} from "../../../../store/reducer";
import { Trans, useTranslation } from "react-i18next";
import { Card, CardContainer, CheckCard, ImageCardContainer } from "../styled";
import { OCol, ORow } from "../../../Styled/CustomGrid";
import { StepProps } from "../../../interface";
import {
  InstallationTypeImageLinks,
  installationConfig,
  InstallationReduxStepData,
  InstallationTypeId,
  InstallationType,
} from "../constants";
import { equipCardVariants } from "../animations";
import { OBody5, OH1 } from "../../../Styled/Texts";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import { fade } from "../../../Styled/GlobalAnimations";
import useIsMobile from "../../../../utils/useIsMobile";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "react-lazy-load-image-component/src/effects/blur.css";
import { svgsPath } from "../../../../utils/constants";
import { trackDataLayer } from "../../../../utils/tracking/tracking-utils";

const InstallationPhase1: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_INSTALLATION;
  const { t } = useTranslation([currentStepName, "global"]);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const config = installationConfig;

  // Extract data from redux
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  // Component state to manage selected card flip flop
  const [tempSelectedCard, setTempSelectedCard] = useState<string | null>(null);

  const [selectedCard, setSelectedCard] = useState<string | null>(
    stepData.installation ?? null
  );

  const isSelected = (installation: string): boolean => {
    return stepData.installation === installation;
  };

  const isDisabledCard = (installation: string): boolean => {
    const disabledCards = config.disabled ?? [];
    return disabledCards.includes(installation as InstallationType);
  };

  const handleCardClick = (installation: string) => {
    if (!isDisabledCard(installation)) {
      setSelectedCard(null);
      setTempSelectedCard(installation);
      trackDataLayer("InstallationSelected", {
        installation: installation,
      });
    }
  };

  useEffect(() => {
    if (tempSelectedCard) {
      setSelectedCard(tempSelectedCard);
    }
  }, [tempSelectedCard]);

  // Save selected card in redux
  useEffect(() => {
    if (selectedCard) {
      dispatch(
        setStepData({
          step: currentStepName,
          data: {
            installation: selectedCard,
            installationId: InstallationTypeId[selectedCard],
          } as InstallationReduxStepData,
        })
      );
    }
  }, [selectedCard]);

  // Go to next step when selected card has changed
  useEffect(() => {
    if (tempSelectedCard) {
      if (tempSelectedCard !== stepData.installation) {
        dispatch(
          setStepAccessible({
            step: STEP_RECOMMANDATION,
            accessible: false,
            data: { installation: null },
          })
        );
        dispatch(setStepPhase1({ step: STEP_RECOMMANDATION }));
        dispatch(setStepPhase1({ step: STEP_NEEDS }));
        dispatch(setStepPhase1({ step: STEP_DESIGN }));
        dispatch(setStepAccessible({ step: STEP_DESIGN, accessible: false }));
      }
      onContinue();
    }
  }, [selectedCard]);

  return (
    <ORow className="h-100">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className="d-flex flex-column justify-content-start justify-content-md-center align-items-center gap-3 contentsized p-0 p-md-3 h-100 overflow-auto"
      >
        <OH1 center="true" {...fade(0.2)} style={!isMobile ? { maxWidth:'60%' } : { maxWidth:'100%' }}>
          <Trans>{t("title")}</Trans>
        </OH1>
        <CardContainer
          initial="hidden"
          className="d-flex flex-row justify-content-center align-items-center flex-wrap gap-3 pb-2"
        >
          {Object.entries(InstallationTypeImageLinks).map(
            ([installation, imageLink], index) => (
              <Card
                key={installation + "-card"}
                onClick={() => handleCardClick(installation)}
                variants={equipCardVariants}
                initial={"hidden"}
                animate={
                  isSelected(installation)
                    ? "selected"
                    : isDisabledCard(installation)
                    ? "disabled"
                    : "visible"
                }
                custom={index}
                className={`${
                  isDisabledCard(installation) ? "disabled" : ""
                } installation-opt-${installation}`}
              >
                {isSelected(installation) && (
                  <CheckCard src={svgsPath + "check.svg"} />
                )}
                <ImageCardContainer>
                  <LazyLoadImage
                    src={imageLink}
                    alt={installation}
                    key={installation + "-img"}
                    effect="blur img-fluid"
                    placeholder={<Skeleton height={100} />}
                  />
                </ImageCardContainer>
                <OBody5 center="true" {...fade(0.4)}>
                  {t(`type.${installation}`)}
                </OBody5>
              </Card>
            )
          )}
        </CardContainer>
      </OCol>
    </ORow>
  );
};

export default InstallationPhase1;
