import { transpose } from 'd3';
import _ from 'lodash';
import { transposeMatrix } from './transpose-matrix';

// interface buildMatrixForApi {
//     matrice: number[][];
// }

export const buildMatrixForApi = (matrix, dispositionPanneaux):number[][] => {

    let newMatrix = _.cloneDeep(matrix);

    if(dispositionPanneaux === 0) {
        newMatrix = transposeMatrix(newMatrix);
    }

    //get max length of rows
    const horizontalMatrixSize = Math.max(...newMatrix.map((row: []) => row.length));

    //set all rows to the same length-add 0 to the end of the row
    newMatrix.forEach((row: number[]) => {
        while (row.length < horizontalMatrixSize) {
            row.push(0);
        }
    });

    return newMatrix;
}

