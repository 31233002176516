const panelWidth = 78;
const panelHeight = 110;

const scrollbar = 12;

///

const defaultpanelCountRow = 3; // ROW,
const defaultpanelCountColumn = 7; // COLUMN,

const defaultpanelGridWidth = panelWidth * defaultpanelCountColumn;
const defaultpanelGridHeight = panelHeight * defaultpanelCountRow;

const defaultEditorWidth = panelWidth + defaultpanelGridWidth;
const defaultEditorHeight = panelHeight + defaultpanelGridHeight;

////

const defaultPanelCountRowPaysage = 5; // ROW
const defaultPanelCountColumnPaysage = 5; // COLUMN

const defaultPanelGridWidthPaysage = panelHeight * defaultPanelCountColumnPaysage;
const defaultPanelGridHeightPaysage = panelWidth * defaultPanelCountRowPaysage;

const defaultEditorWidthPaysage = panelHeight + defaultPanelGridHeightPaysage;
const defaultEditorHeightPaysage = panelWidth + defaultPanelGridWidthPaysage;

///

export const defaultSize = {

    panelWidth : panelWidth,
    panelHeight : panelHeight,
    scrollbar : 12,

    // PORTRAIT VARIABLES
    panelCountRow : defaultpanelCountRow,
    panelCountColumn : defaultpanelCountColumn,

    panelGridWidth : defaultpanelGridWidth,
    panelGridHeight : defaultpanelGridHeight,

    editorWidth : defaultEditorWidth,
    editorHeight : defaultEditorHeight,

    // --- PAYSAGE VARIABLES
    panelCountRowPaysage : defaultPanelCountRowPaysage,
    panelCountColumnPaysage : defaultPanelCountColumnPaysage,

    panelGridWidthPaysage : defaultPanelGridWidthPaysage,
    panelGridHeightPaysage : defaultPanelGridHeightPaysage,

    editorWidthPaysage : defaultEditorWidthPaysage,
    editorHeightPaysage : defaultEditorHeightPaysage,
}

////////////////////////////////////////////////////////////////


const smallPanelCountRow = 2; // ROW,
const smallPanelCountColumn = 6; // COLUMN,

const smallPanelGridWidth = panelWidth * smallPanelCountColumn;
const smallPanelGridHeight = panelHeight * smallPanelCountRow;

const smallEditorWidth = panelWidth + smallPanelGridWidth;
const smallEditorHeight = panelHeight + smallPanelGridHeight;

////

const smallPanelCountRowPaysage = 4; // ROW
const smallPanelCountColumnPaysage = 4; // COLUMN

const smallPanelGridWidthPaysage = panelHeight * smallPanelCountColumnPaysage;
const smallPanelGridHeightPaysage = panelWidth * smallPanelCountRowPaysage;

const smallEditorWidthPaysage = panelHeight + smallPanelGridHeightPaysage;
const smallEditorHeightPaysage = panelWidth + smallPanelGridWidthPaysage;

export const smallSize = {

    panelWidth : panelWidth,
    panelHeight : panelHeight,
    scrollbar : 12,

    // PORTRAIT VARIABLES
    panelCountRow : smallPanelCountRow,
    panelCountColumn : smallPanelCountColumn,

    panelGridWidth : smallPanelGridWidth,
    panelGridHeight : smallPanelGridHeight,

    editorWidth : smallEditorWidth,
    editorHeight : smallEditorHeight,

    // --- PAYSAGE VARIABLES
    panelCountRowPaysage : smallPanelCountRowPaysage,
    panelCountColumnPaysage : smallPanelCountColumnPaysage,

    panelGridWidthPaysage : smallPanelGridWidthPaysage,
    panelGridHeightPaysage : smallPanelGridHeightPaysage,

    editorWidthPaysage : smallEditorWidthPaysage,
    editorHeightPaysage : smallEditorHeightPaysage,
}

////////////////////////////////////////////////////////////////


const bigPanelCountRow = 4; // ROW,
const bigPanelCountColumn = 8; // COLUMN,

const bigPanelGridWidth = panelWidth * bigPanelCountColumn;
const bigPanelGridHeight = panelHeight * bigPanelCountRow;

const bigEditorWidth = panelWidth + bigPanelGridWidth;
const bigEditorHeight = panelHeight + bigPanelGridHeight;

////

const bigPanelCountRowPaysage = 6; // ROW
const bigPanelCountColumnPaysage = 6; // COLUMN

const bigPanelGridWidthPaysage = panelHeight * bigPanelCountColumnPaysage;
const bigPanelGridHeightPaysage = panelWidth * bigPanelCountRowPaysage;

const bigEditorWidthPaysage = panelHeight + bigPanelGridHeightPaysage;
const bigEditorHeightPaysage = panelWidth + bigPanelGridWidthPaysage;

export const bigSize = {

    panelWidth : panelWidth,
    panelHeight : panelHeight,
    scrollbar : 12,

    // PORTRAIT VARIABLES
    panelCountRow : bigPanelCountRow,
    panelCountColumn : bigPanelCountColumn,

    panelGridWidth : bigPanelGridWidth,
    panelGridHeight : bigPanelGridHeight,

    editorWidth : bigEditorWidth,
    editorHeight : bigEditorHeight,

    // --- PAYSAGE VARIABLES
    panelCountRowPaysage : bigPanelCountRowPaysage,
    panelCountColumnPaysage : bigPanelCountColumnPaysage,

    panelGridWidthPaysage : bigPanelGridWidthPaysage,
    panelGridHeightPaysage : bigPanelGridHeightPaysage,

    editorWidthPaysage : bigEditorWidthPaysage,
    editorHeightPaysage : bigEditorHeightPaysage,
}