import React, { useCallback } from 'react';
import { useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { addRowButtonOnMouseEnter, addPanelInRow, removePanelInRow, setRowToShowDeletePanel } from '../../../store/calepinage/calepinageHorsMapReducer';
import { OMaterialButton } from '../../Styled/Buttons/OMaterialButton';

interface RowButtonsContainerProps {
    id?: string;
    index: number;
    disposition: number;
    className?: string;
    displayMessage: Function;
}

const RowButtonsContainer: React.FC<RowButtonsContainerProps> = ({ index, disposition, displayMessage}) => {

    const maxPanelCount = useSelector(
        (state: RootState) => state?.calepinageHMReducer?.maxPanelCount
    );

    const panelsCount = useSelector(
        (state: RootState) => state?.calepinageHMReducer?.count
    );

    const addPanelInRowEvent = () => {
        if(maxPanelCount === panelsCount ){
            displayMessage();
            return;
        }
        store.dispatch(addPanelInRow({ rowIndex: index }));
    }

    const removePanelInRowEvent = () => {
        store.dispatch(removePanelInRow({ rowIndex: index }));
    }

    const showVirtualPanelOnRow = useCallback(() => {
        // console.log("On Mouse Enter : ", index);
        store.dispatch(addRowButtonOnMouseEnter({rowIndex: index}));
    }, [index]);

    const hideVirtualPanelOnRow = useCallback(() => {
        // console.log("On Mouse Leave", index);
        store.dispatch(addRowButtonOnMouseEnter({rowIndex: null}));
    }, [index]);

    const showDeletePanelOnRow = useCallback(() => {
        // console.log("On Mouse Enter : ", index);
        store.dispatch(setRowToShowDeletePanel({rowIndex: index}));
    }, [index]);

    const hideDeletePanelOnRow = useCallback(() => {
        // console.log("On Mouse Leave", index);
        store.dispatch(setRowToShowDeletePanel({rowIndex: null}));
    }, [index]);

    return(
        <div className={`d-flex align-items-center justify-content-center gap-1 ${ disposition ? "flex-column" : ""}`}>
            {/* <button className="btn btn-primary" onMouseEnter={showVirtualPanelOnRow} onMouseLeave={hideVirtualPanelOnRow}> + </button> */}
            {/* <button className="btn btn-primary" onMouseEnter={showVirtualPanelOnRow} onMouseLeave={hideVirtualPanelOnRow} onClick={addPanelInRowEvent}> + </button> */}
            {/* <button className="btn btn-primary" onMouseEnter={showDeletePanelOnRow} onMouseLeave={hideDeletePanelOnRow} onClick={removePanelInRowEvent}> - </button> */}

            <OMaterialButton className={`material-symbols-rounded ${ disposition ? "order-0" : "order-1"}`} icon="+" size="32" color="primary" onMouseEnter={showVirtualPanelOnRow} onMouseLeave={hideVirtualPanelOnRow} onClick={addPanelInRowEvent}></OMaterialButton>
            <OMaterialButton className={`material-symbols-rounded ${ disposition ? "order-1" : "order-0"}`} icon="-" size="32" color="grey-blue" onMouseEnter={showDeletePanelOnRow} onMouseLeave={hideDeletePanelOnRow} onClick={removePanelInRowEvent}></OMaterialButton>
        </div>
    );
};

export default RowButtonsContainer;