import { FixationDto } from "../dtos/fixation-dto";
import { FixationTypeDto } from "../dtos/fixation-type-dto";

export class FixationApi {
  public getFixationsType(): FixationTypeDto[] {
    return [
      {
        id: 1472,
        img: "ausol_metalliquegse",
        name: "Fixation métallique óTerra",
      },
      {
        id: 76,
        img: "ausol_toitter_bacplastique",
        name: "Fixation plastique",
      },
      {
        id: 864,
        img: "ausol_metalliquegse",
        name: "Fixation métallique",
      },
      // {
      //     id: 71,
      //     img: 'ausol_metalliquegse',
      //     name: 'Fixation métallique',
      // },
      {
        id: 409,
        img: "charpentebois",
        name: "Fixation sur charpente en bois",
      },
      /*
      {
          id: 516,
          img: 'pergola_abri_noir',
          name: 'Fixation 4 pieds noire',
      },
      {
          id: 517,
          img: 'pergola_abri_blanc',
          name: 'Fixation 4 pieds blanche',
      },
      {
          id: 101,
          img: 'pergola_abri_blanc',
          name: 'Fixation 2 pieds blanche',
      },
      */
      {
        id: 1056,
        img: "charpentebois",
        name: "Fixation sur charpente en bois",
      },
      /*
      {
          id: 515,
          img: 'pergola_abri_noir',
          name: 'Fixation 2 pieds noire',
      },
      */
      {
        id: 102,
        img: "facade_noir",
        name: "Fixation brise soleil - Noir",
      },
      {
        id: 514,
        img: "facade_blanc",
        name: "Fixation brise soleil - Blanc",
      },
      {
        id: 765,
        img: "brise_soleil",
        name: "Fixation Brise-Soleil - Galva",
      },
    ];
  }
  public getFixations(): FixationDto[] {
    return [
      {
        id: 1472,
        id_product: "1472_6312",
        id_product_attr: "6312",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 4 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6312-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 4,
        price: 400,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6346",
        id_product_attr: "6346",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 5 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6346-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 5,
        price: 600,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6313",
        id_product_attr: "6313",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 6 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6346-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 6,
        price: 600,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6347",
        id_product_attr: "6347",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 7 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6347-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 7,
        price: 800,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6314",
        id_product_attr: "6314",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 8 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6314-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 8,
        price: 800,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6348",
        id_product_attr: "6348",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 9 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6348-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 9,
        price: 1000,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6315",
        id_product_attr: "6315",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 10 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6315-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 10,
        price: 1000,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6349",
        id_product_attr: "6349",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 11 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6349-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 11,
        price: 1200,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6316",
        id_product_attr: "6316",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 12 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6316-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 12,
        price: 1200,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6316",
        id_product_attr: "6316",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 13 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6316-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 13,
        price: 1400,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6351",
        id_product_attr: "6351",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 14 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6351-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 14,
        price: 1400,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6352",
        id_product_attr: "6352",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 15 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6352-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 15,
        price: 1600,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6353",
        id_product_attr: "6353",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 16 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6353-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 16,
        price: 1600,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6354",
        id_product_attr: "6354",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 17 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6354-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 17,
        price: 1800,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6355",
        id_product_attr: "6355",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 18 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6355-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 18,
        price: 1800,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6356",
        id_product_attr: "6356",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 18 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6356-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 19,
        price: 2000,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6357",
        id_product_attr: "6357",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 20 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6357-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 20,
        price: 2000,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6358",
        id_product_attr: "6358",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 21 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6358-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 21,
        price: 2200,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6359",
        id_product_attr: "6359",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 22 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6359-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 22,
        price: 2200,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6360",
        id_product_attr: "6360",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 23 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6360-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 23,
        price: 2400,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      {
        id: 1472,
        id_product: "1472_6361",
        id_product_attr: "6361",
        placement: ["46"],
        name: "Fixation de panneau solaire au sol - oTerra 24 panneaux",
        link: "https://www.oscaro-power.com/fixation-sol/1472-6361-fixation-de-panneaux-solaires-au-sol-oterra.html",
        l: 1,
        c: 24,
        price: 2400,
        img: "https://www.oscaro-power.com/9627-large_default/fixation-de-panneaux-solaires-au-sol-oterra.jpg",
      },
      // {
      //     id: 71,
      //     id_product: '71_95',
      //     id_product_attr: '95',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS4',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-95-gse-ground-system.html',
      //     l: 1,
      //     c: 4,
      //     price: 351,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_96',
      //     id_product_attr: '96',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS6',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-96-gse-ground-system.html',
      //     l: 1,
      //     c: 6,
      //     price: 522,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_97',
      //     id_product_attr: '97',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS8',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-97-gse-ground-system.html',
      //     l: 1,
      //     c: 8,
      //     price: 693,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_98',
      //     id_product_attr: '98',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS10',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-98-gse-ground-system.html',
      //     l: 1,
      //     c: 10,
      //     price: 864,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_99',
      //     id_product_attr: '99',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS12',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-99-gse-ground-system.html',
      //     l: 1,
      //     c: 12,
      //     price: 1035,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_100',
      //     id_product_attr: '100',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS14',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-100-gse-ground-system.html',
      //     l: 1,
      //     c: 14,
      //     price: 1206,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_101',
      //     id_product_attr: '101',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS16',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-101-gse-ground-system.html',
      //     l: 1,
      //     c: 16,
      //     price: 1377,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_102',
      //     id_product_attr: '102',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS18',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-102-gse-ground-system.html',
      //     l: 1,
      //     c: 18,
      //     price: 1548,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_103',
      //     id_product_attr: '103',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS20',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-103-gse-ground-system.html',
      //     l: 1,
      //     c: 20,
      //     price: 1719,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      // {
      //     id: 71,
      //     id_product: '71_94',
      //     id_product_attr: '94',
      //     placement: ['46'],
      //     name: 'Fixation de panneau solaire au sol - GSE Ground System GSE-GS2',
      //     link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/71-94-gse-ground-system.html',
      //     l: 1,
      //     c: 2,
      //     price: 342,
      //     img: 'https://www.oscaro-power.com/3398-home_default/gse-ground-system.jpg',
      // },
      {
        id: 76,
        id_product: "76_2894",
        id_product_attr: "2894",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_2L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2894-renusol-console-bac-plastique.html",
        l: 2,
        c: 1,
        price: 159,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2895",
        id_product_attr: "2895",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_3L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2895-renusol-console-bac-plastique.html",
        l: 3,
        c: 1,
        price: 239,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2896",
        id_product_attr: "2896",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_4L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2896-renusol-console-bac-plastique.html",
        l: 4,
        c: 1,
        price: 319,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2897",
        id_product_attr: "2897",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_5L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2897-renusol-console-bac-plastique.html",
        l: 5,
        c: 1,
        price: 399,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2898",
        id_product_attr: "2898",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_1L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2898-renusol-console-bac-plastique.html",
        l: 1,
        c: 2,
        price: 159,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2899",
        id_product_attr: "2899",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_2L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2899-renusol-console-bac-plastique.html",
        l: 2,
        c: 2,
        price: 319,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2900",
        id_product_attr: "2900",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_3L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2900-renusol-console-bac-plastique.html",
        l: 3,
        c: 2,
        price: 479,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2901",
        id_product_attr: "2901",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_4L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2901-renusol-console-bac-plastique.html",
        l: 4,
        c: 2,
        price: 639,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2902",
        id_product_attr: "2902",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_5L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2902-renusol-console-bac-plastique.html",
        l: 5,
        c: 2,
        price: 799,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2903",
        id_product_attr: "2903",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_1L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2903-renusol-console-bac-plastique.html",
        l: 1,
        c: 3,
        price: 239,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2904",
        id_product_attr: "2904",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_2L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2904-renusol-console-bac-plastique.html",
        l: 2,
        c: 3,
        price: 479,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2905",
        id_product_attr: "2905",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_3L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2905-renusol-console-bac-plastique.html",
        l: 3,
        c: 3,
        price: 719,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2906",
        id_product_attr: "2906",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_4L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2906-renusol-console-bac-plastique.html",
        l: 4,
        c: 3,
        price: 959,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2907",
        id_product_attr: "2907",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_5L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2907-renusol-console-bac-plastique.html",
        l: 5,
        c: 3,
        price: 1199,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2908",
        id_product_attr: "2908",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_1L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2908-renusol-console-bac-plastique.html",
        l: 1,
        c: 4,
        price: 319,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2909",
        id_product_attr: "2909",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_2L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2909-renusol-console-bac-plastique.html",
        l: 2,
        c: 4,
        price: 639,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2910",
        id_product_attr: "2910",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_3L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2910-renusol-console-bac-plastique.html",
        l: 3,
        c: 4,
        price: 959,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2911",
        id_product_attr: "2911",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_4L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2911-renusol-console-bac-plastique.html",
        l: 4,
        c: 4,
        price: 1279,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2912",
        id_product_attr: "2912",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_5L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2912-renusol-console-bac-plastique.html",
        l: 5,
        c: 4,
        price: 1599,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2913",
        id_product_attr: "2913",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_1L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2913-renusol-console-bac-plastique.html",
        l: 1,
        c: 5,
        price: 399,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2914",
        id_product_attr: "2914",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_2L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2914-renusol-console-bac-plastique.html",
        l: 2,
        c: 5,
        price: 799,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2915",
        id_product_attr: "2915",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_3L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2915-renusol-console-bac-plastique.html",
        l: 3,
        c: 5,
        price: 1199,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2916",
        id_product_attr: "2916",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_4L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2916-renusol-console-bac-plastique.html",
        l: 4,
        c: 5,
        price: 1599,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2917",
        id_product_attr: "2917",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_5L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2917-renusol-console-bac-plastique.html",
        l: 5,
        c: 5,
        price: 2319,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      {
        id: 76,
        id_product: "76_2893",
        id_product_attr: "2893",
        placement: ["45", "46"],
        name: "Fixation de panneau solaire pour toiture terrasse ou sol - Console+ Console_1L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/76-2893-renusol-console-bac-plastique.html",
        l: 1,
        c: 1,
        price: 82,
        img: "https://www.oscaro-power.com/286-home_default/renusol-console-bac-plastique.jpg",
      },
      /*
          {
              id: 101,
              id_product: '101_815',
              id_product_attr: '815',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow SolarEvolution) - Blanc Umbra-Bl-3x3-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/101-815-irfts-shadow-solar.html',
              l: 3,
              c: 3,
              price: 5059.51,
              img: 'https://www.oscaro-power.com/435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 101,
              id_product: '101_817',
              id_product_attr: '817',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow SolarEvolution) - Blanc Umbra-Bl-4x2-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/101-817-irfts-shadow-solar.html',
              l: 4,
              c: 2,
              price: 4465.49,
              img: 'https://www.oscaro-power.com/435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 101,
              id_product: '101_819',
              id_product_attr: '819',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow SolarEvolution) - Blanc Umbra-Bl-4x3-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/101-819-irfts-shadow-solar.html',
              l: 4,
              c: 3,
              price: 6247.49,
              img: 'https://www.oscaro-power.com/435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 101,
              id_product: '101_813',
              id_product_attr: '813',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow SolarEvolution) - Blanc Umbra-Bl-2x2-2p',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/101-813-irfts-shadow-solar.html',
              l: 2,
              c: 2,
              price: 2535,
              img: 'https://www.oscaro-power.com/435-home_default/irfts-shadow-solar.jpg',
          },
          */
      {
        id: 102,
        id_product: "102_4496",
        id_product_attr: "4496",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Noir Umbra-g-2",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/102-4496-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 2,
        price: 650,
        img: "https://www.oscaro-power.com/445-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 102,
        id_product: "102_4497",
        id_product_attr: "4497",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Noir Umbra-g-3",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/102-4497-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 3,
        price: 950,
        img: "https://www.oscaro-power.com/445-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 102,
        id_product: "102_4498",
        id_product_attr: "4498",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Noir Umbra-g-4",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/102-4498-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 4,
        price: 1250,
        img: "https://www.oscaro-power.com/445-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 102,
        id_product: "102_849",
        id_product_attr: "849",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Noir Umbra-g-1",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/102-849-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 1,
        price: 350,
        img: "https://www.oscaro-power.com/445-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5643",
        id_product_attr: "5643",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L1C",
        link: "https://www.oscaro-power.com/1056-5643-oscaroof.html",
        l: 2,
        c: 1,
        price: 129,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5644",
        id_product_attr: "5644",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L1C",
        link: "https://www.oscaro-power.com/1056-5644-oscaroof.html",
        l: 3,
        c: 1,
        price: 195,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5645",
        id_product_attr: "5645",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L2C",
        link: "https://www.oscaro-power.com/1056-5645-oscaroof.html",
        l: 1,
        c: 2,
        price: 91,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5646",
        id_product_attr: "5646",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L2C",
        link: "https://www.oscaro-power.com/1056-5646-oscaroof.html",
        l: 2,
        c: 2,
        price: 186,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5647",
        id_product_attr: "5647",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L2C",
        link: "https://www.oscaro-power.com/1056-5647-oscaroof.html",
        l: 3,
        c: 2,
        price: 281,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5648",
        id_product_attr: "5648",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L3C",
        link: "https://www.oscaro-power.com/1056-5648-oscaroof.html",
        l: 1,
        c: 3,
        price: 119,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5649",
        id_product_attr: "5649",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L3C",
        link: "https://www.oscaro-power.com/1056-5649-oscaroof.html",
        l: 2,
        c: 3,
        price: 244,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5650",
        id_product_attr: "5650",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L3C",
        link: "https://www.oscaro-power.com/1056-5650-oscaroof.html",
        l: 3,
        c: 3,
        price: 369,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5651",
        id_product_attr: "5651",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L4C",
        link: "https://www.oscaro-power.com/1056-5651-oscaroof.html",
        l: 1,
        c: 4,
        price: 147,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5652",
        id_product_attr: "5652",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L4C",
        link: "https://www.oscaro-power.com/1056-5652-oscaroof.html",
        l: 2,
        c: 4,
        price: 301,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5653",
        id_product_attr: "5653",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L4C",
        link: "https://www.oscaro-power.com/1056-5653-oscaroof.html",
        l: 3,
        c: 4,
        price: 456,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5654",
        id_product_attr: "5654",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L5C",
        link: "https://www.oscaro-power.com/1056-5654-oscaroof.html",
        l: 1,
        c: 5,
        price: 175,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5655",
        id_product_attr: "5655",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L5C",
        link: "https://www.oscaro-power.com/1056-5655-oscaroof.html",
        l: 2,
        c: 5,
        price: 356,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5656",
        id_product_attr: "5656",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L5C",
        link: "https://www.oscaro-power.com/1056-5656-oscaroof.html",
        l: 3,
        c: 5,
        price: 543,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5657",
        id_product_attr: "5657",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L6C",
        link: "https://www.oscaro-power.com/1056-5657-oscaroof.html",
        l: 1,
        c: 6,
        price: 202,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5658",
        id_product_attr: "5658",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L6C",
        link: "https://www.oscaro-power.com/1056-5658-oscaroof.html",
        l: 2,
        c: 6,
        price: 416,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5659",
        id_product_attr: "5659",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L6C",
        link: "https://www.oscaro-power.com/1056-5659-oscaroof.html",
        l: 3,
        c: 6,
        price: 630,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5660",
        id_product_attr: "5660",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L7C",
        link: "https://www.oscaro-power.com/1056-5660-oscaroof.html",
        l: 1,
        c: 7,
        price: 230,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5661",
        id_product_attr: "5661",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L7C",
        link: "https://www.oscaro-power.com/1056-5661-oscaroof.html",
        l: 2,
        c: 7,
        price: 474,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5662",
        id_product_attr: "5662",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L7C",
        link: "https://www.oscaro-power.com/1056-5662-oscaroof.html",
        l: 3,
        c: 7,
        price: 717,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5663",
        id_product_attr: "5663",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L8C",
        link: "https://www.oscaro-power.com/1056-5663-oscaroof.html",
        l: 1,
        c: 8,
        price: 258,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5664",
        id_product_attr: "5664",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_2L8C",
        link: "https://www.oscaro-power.com/1056-5664-oscaroof.html",
        l: 2,
        c: 8,
        price: 516,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5665",
        id_product_attr: "5665",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_3L8C",
        link: "https://www.oscaro-power.com/ 1056-5665-oscaroof.html",
        l: 3,
        c: 8,
        price: 804,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 1056,
        id_product: "1056_5642",
        id_product_attr: "5642",
        placement: ["543", "544"],
        name: "Fixation de panneau solaire pour charpente bois OSCAROOF_1L1C",
        link: "https://www.oscaro-power.com/1056-5642-oscaroof.html",
        l: 1,
        c: 1,
        price: 64,
        img: "https://www.oscaro-power.com/951-home_default/oscaroof.jpg",
      },
      {
        id: 514,
        id_product: "514_4490",
        id_product_attr: "4490",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Blanc Umbra-b-2",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/514-4490-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 2,
        price: 650,
        img: "https://www.oscaro-power.com/1410-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 514,
        id_product: "514_4491",
        id_product_attr: "4491",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Blanc Umbra-b-3",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/514-4491-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 3,
        price: 950,
        img: "https://www.oscaro-power.com/1410-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 514,
        id_product: "514_4492",
        id_product_attr: "4492",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Blanc Umbra-b-4",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/514-4492-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 4,
        price: 1250,
        img: "https://www.oscaro-power.com/1410-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      {
        id: 514,
        id_product: "514_2701",
        id_product_attr: "2701",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar) - Blanc Umbra-b-1",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/514-2701-irfts-brise-soleil-umbra-solar.html",
        l: 1,
        c: 1,
        price: 350,
        img: "https://www.oscaro-power.com/1410-home_default/irfts-brise-soleil-umbra-solar.jpg",
      },
      /*
          {
              id: 515,
              id_product: '515_2712',
              id_product_attr: '2712',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow Solar) - Noir Umbra-Gr-3x3-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/515-2712-irfts-shadow-solar.html',
              l: 3,
              c: 3,
              price: 5059.51,
              img: 'https://www.oscaro-power.com/1422-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 515,
              id_product: '515_2714',
              id_product_attr: '2714',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow Solar) - Noir Umbra-Gr-4x2-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/515-2714-irfts-shadow-solar.html',
              l: 4,
              c: 2,
              price: 4465.49,
              img: 'https://www.oscaro-power.com/1422-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 515,
              id_product: '515_2716',
              id_product_attr: '2716',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow Solar) - Noir Umbra-Gr-4x3-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/515-2716-irfts-shadow-solar.html',
              l: 4,
              c: 3,
              price: 6247.49,
              img: 'https://www.oscaro-power.com/1422-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 515,
              id_product: '515_2710',
              id_product_attr: '2710',
              placement: ['544'],
              name: 'Fixation de panneau solaire - Pergola (Shadow Solar) - Noir Umbra-Gr-2x2-2P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/515-2710-irfts-shadow-solar.html',
              l: 2,
              c: 2,
              price: 2535,
              img: 'https://www.oscaro-power.com/1422-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 516,
              id_product: '516_2729',
              id_product_attr: '2729',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Noir Umbra-Gr-3x--3p-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/516-2729-irfts-shadow-solar.html',
              l: 3,
              c: 3,
              price: 5950.51,
              img: 'https://www.oscaro-power.com/1435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 516,
              id_product: '516_2731',
              id_product_attr: '2731',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Noir Umbra-Gr-4x2-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/516-2731-irfts-shadow-solar.html',
              l: 4,
              c: 2,
              price: 5059.51,
              img: 'https://www.oscaro-power.com/1435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 516,
              id_product: '516_2733',
              id_product_attr: '2733',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Noir Umbra-Gr-4x3-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/516-2733-irfts-shadow-solar.html',
              l: 4,
              c: 3,
              price: 6692.99,
              img: 'https://www.oscaro-power.com/1435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 516,
              id_product: '516_2727',
              id_product_attr: '2727',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Noir Umbra-Gr-2x2-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/516-2727-irfts-shadow-solar.html',
              l: 2,
              c: 2,
              price: 3277.51,
              img: 'https://www.oscaro-power.com/1435-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 517,
              id_product: '517_2737',
              id_product_attr: '2737',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Blanc Umbra-Bl-3x3p-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/517-2737-irfts-shadow-solar.html',
              l: 3,
              c: 3,
              price: 5950.51,
              img: 'https://www.oscaro-power.com/1446-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 517,
              id_product: '517_2739',
              id_product_attr: '2739',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Blanc Umbra-Bl-4x2p-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/517-2739-irfts-shadow-solar.html',
              l: 4,
              c: 2,
              price: 5059.51,
              img: 'https://www.oscaro-power.com/1446-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 517,
              id_product: '517_2741',
              id_product_attr: '2741',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Blanc Umbra-Bl-4x3-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/517-2741-irfts-shadow-solar.html',
              l: 4,
              c: 3,
              price: 6692.99,
              img: 'https://www.oscaro-power.com/1446-home_default/irfts-shadow-solar.jpg',
          },
          {
              id: 517,
              id_product: '517_2735',
              id_product_attr: '2735',
              placement: ['543'],
              name: 'Fixation de panneau solaire - Abri (Shadow Solar) - Blanc Umbra-Bl-2x2-4P',
              link: 'https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/517-2735-irfts-shadow-solar.html',
              l: 2,
              c: 2,
              price: 3277.51,
              img: 'https://www.oscaro-power.com/1446-home_default/irfts-shadow-solar.jpg',
          },
          */
      {
        id: 765,
        id_product: "765_5221",
        id_product_attr: "5221",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_2",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5221-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 2,
        price: 258,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5222",
        id_product_attr: "5222",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_3",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5222-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 3,
        price: 387,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5223",
        id_product_attr: "5223",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_4",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5223-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 4,
        price: 516,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5224",
        id_product_attr: "5224",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_5",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5224-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 5,
        price: 645,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5225",
        id_product_attr: "5225",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_6",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5225-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 6,
        price: 774,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5226",
        id_product_attr: "5226",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_7",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5226-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 7,
        price: 903,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5227",
        id_product_attr: "5227",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_8",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5227-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 8,
        price: 1032,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5228",
        id_product_attr: "5228",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_9",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5228-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 9,
        price: 1161,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5229",
        id_product_attr: "5229",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_10",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5229-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 10,
        price: 1290,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 765,
        id_product: "765_5220",
        id_product_attr: "5220",
        placement: ["545"],
        name: "Fixation de panneau solaire - Brise soleil (Umbra Solar PRO) - Galva UmbraSolar_PRO_1",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/765-5220-irfts-brise-soleil-umbra-solar-PRO.html",
        l: 1,
        c: 1,
        price: 129,
        img: "https://www.oscaro-power.com/2601-home_default/irfts-brise-soleil-umbra-solar-PRO.jpg",
      },
      {
        id: 864,
        id_product: "864_5174",
        id_product_attr: "5174",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_2L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5174-s-dome.html",
        l: 2,
        c: 1,
        price: 200,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5175",
        id_product_attr: "5175",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_3L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5175-s-dome.html",
        l: 3,
        c: 1,
        price: 272,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5176",
        id_product_attr: "5176",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_4L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5176-s-dome.html",
        l: 4,
        c: 1,
        price: 360,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5177",
        id_product_attr: "5177",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_5L1C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5177-s-dome.html",
        l: 5,
        c: 1,
        price: 450,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5178",
        id_product_attr: "5178",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_2L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5178-s-dome.html",
        l: 2,
        c: 2,
        price: 312,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5179",
        id_product_attr: "5179",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_3L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5179-s-dome.html",
        l: 3,
        c: 2,
        price: 432,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5180",
        id_product_attr: "5180",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_4L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5180-s-dome.html",
        l: 4,
        c: 2,
        price: 570,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5181",
        id_product_attr: "5181",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_5L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5181-s-dome.html",
        l: 5,
        c: 2,
        price: 708,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5182",
        id_product_attr: "5182",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_1L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5182-s-dome.html",
        l: 1,
        c: 3,
        price: 230,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5183",
        id_product_attr: "5183",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_2L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5183-s-dome.html",
        l: 2,
        c: 3,
        price: 432,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5184",
        id_product_attr: "5184",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_3L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5184-s-dome.html",
        l: 3,
        c: 3,
        price: 600,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5185",
        id_product_attr: "5185",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_4L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5185-s-dome.html",
        l: 4,
        c: 3,
        price: 780,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5186",
        id_product_attr: "5186",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_5L3C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5186-s-dome.html",
        l: 5,
        c: 3,
        price: 980,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5187",
        id_product_attr: "5187",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_1L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5187-s-dome.html",
        l: 1,
        c: 4,
        price: 290,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5188",
        id_product_attr: "5188",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_2L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5188-s-dome.html",
        l: 2,
        c: 4,
        price: 540,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5189",
        id_product_attr: "5189",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_3L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5189-s-dome.html",
        l: 3,
        c: 4,
        price: 756,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5190",
        id_product_attr: "5190",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_4L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5190-s-dome.html",
        l: 4,
        c: 4,
        price: 990,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5191",
        id_product_attr: "5191",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_5L4C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5191-s-dome.html",
        l: 5,
        c: 4,
        price: 1240,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5192",
        id_product_attr: "5192",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_1L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5192-s-dome.html",
        l: 1,
        c: 5,
        price: 345,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5193",
        id_product_attr: "5193",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_2L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5193-s-dome.html",
        l: 2,
        c: 5,
        price: 660,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5194",
        id_product_attr: "5194",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_3L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5194-s-dome.html",
        l: 3,
        c: 5,
        price: 900,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5195",
        id_product_attr: "5195",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_4L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5195-s-dome.html",
        l: 4,
        c: 5,
        price: 1200,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5196",
        id_product_attr: "5196",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_5L5C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5196-s-dome.html",
        l: 5,
        c: 5,
        price: 1500,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
      {
        id: 864,
        id_product: "864_5197",
        id_product_attr: "5197",
        placement: ["45"],
        name: "Fixation de panneau solaire pour Toiture terrasse - S-Dome 6 SDOME6_1L2C",
        link: "https://www.oscaro-power.com/kit-de-fixation-de-panneau-solaire/864-5197-s-dome.html",
        l: 1,
        c: 2,
        price: 168,
        img: "https://www.oscaro-power.com/3499-home_default/s-dome.jpg",
      },
    ];
  }

  public getFixationsOnLandscapeMode() {
    return [101, 515, 516, 517, 765, 76, 864];
  }
}
