import { OnduleurEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import store from "../../../../../../store";
import { configurateurOnOnduleurSelected } from "../../../../../../store/configurateur/onduleurs/configurateur-onduleurs-reducer";
import { OBody12, OBody4, OBody9 } from "../../../../../Styled/Texts";
import { OnduleurItemContainer } from "./onduleur-styled";
import { theme } from "../../../../../Styled/GlobalStyle";
import { OSecondaryButton } from "../../../../../Styled/Buttons";
import { t } from "i18next";
import { svgsPath } from "../../../../../../utils/constants";
import { formatNumber } from "../../../../../../utils/convert-number-locale";

export const OnduleurItem: React.FC<{
  onduleur: OnduleurEntity;
  showItems: boolean;
  setShowItems;
}> = ({ onduleur, showItems, setShowItems }) => {
  return (
    <OnduleurItemContainer
      onClick={() => {
        setShowItems(!showItems);
        store.dispatch(configurateurOnOnduleurSelected(onduleur.id_product));
      }}
    >
      <img
        className="onduleur-item-img"
        src={onduleur.img}
        alt={onduleur.name}
      />
      <OBody12>{onduleur.configurator_name}</OBody12>
      {onduleur.priceDelta !== 0 && (
        <OBody4
          color={theme.colors.onyx.onyxPower500}
          className="onduleurTrailing"
        >
          {onduleur.priceDelta > 0 && "+"}
          {formatNumber(onduleur.priceDelta)}
        </OBody4>
      )}
      <img
        className="onduleur-item-arrow-right"
        src={svgsPath + "arrow-right.svg"}
        alt=""
      />
    </OnduleurItemContainer>
  );
};

export const OnduleurSelectedItem: React.FC<{
  onduleur: OnduleurEntity;
  showItems: boolean;
  setShowItems;
}> = ({ onduleur, showItems, setShowItems }) => {
  return (
    <OnduleurItemContainer className="selectedOnduleur">
      <img
        className="onduleur-item-img"
        src={onduleur?.img}
        alt={onduleur?.name}
      />
      <OBody9>{onduleur?.configurator_name}</OBody9>
      <OSecondaryButton
        className="onduleurTrailing"
        onClick={() => setShowItems(!showItems)}
      >
        {!showItems ? t("global:update") : t("global:close")}
      </OSecondaryButton>
    </OnduleurItemContainer>
  );
};
