interface matrixRealDimensions {
    horizontalDimension: number;
    verticalDimension: number;
    areaDimension: number;
}

export const getMatrixRealDimensions = (matrix, disposition, dimensionFeature):matrixRealDimensions => {

    console.log("call getMatrixRealDimensions disposition: ", disposition, " dimensionFeature: ", dimensionFeature);

    if (disposition === 1) {

        const horizontalMatrixSize = Math.max(...matrix.map((row: []) => row.length));
        const verticalMatrixSize = matrix.length;

        const horizontalDimension = horizontalMatrixSize * dimensionFeature.largeur + 0.010 + 0.002 * (horizontalMatrixSize - 1); // 10 cm bords de rails + 2 cm bords de panneaux
        const verticalDimension = verticalMatrixSize * dimensionFeature.longueur;
        const areaDimension = horizontalDimension * verticalDimension;
        
        return {
            horizontalDimension: horizontalDimension,
            verticalDimension: verticalDimension,
            areaDimension: areaDimension
        }
    }

    const horizontalMatrixSize = matrix.length;
    const verticalMatrixSize = Math.max(...matrix.map((row: []) => row.length));

    const horizontalDimension = horizontalMatrixSize * dimensionFeature.longueur;
    const verticalDimension = verticalMatrixSize * dimensionFeature.largeur + 0.010 + 0.002 * (verticalMatrixSize - 1); // 10 cm bords de rails + 2 cm bords de panneaux
    const areaDimension = horizontalDimension * verticalDimension;

    return {
        horizontalDimension: horizontalDimension,
        verticalDimension: verticalDimension,
        areaDimension: areaDimension
    }

}