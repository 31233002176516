import _ from "lodash";
import { useEffect, useRef } from "react";

const deepCompareEquals = (a, b): boolean => {
  return _.isEqual(a, b);
};

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
