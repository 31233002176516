import { useSelector } from "react-redux";
import { RootState } from "../..";
import {
  AccessoryEntity,
  GarantieEntity,
  HelpEntity,
  InstallHelpEntity,
  OnduleurEntity,
  PanelEntity,
  PanneauFixationEntity,
  ProductEntity,
} from "../../../data/prestashop/services/entities/product-entity";
import { ConfigurateurAccessoriesCombination } from "../accessories/configurateur-accessories-state";
import { useTranslation } from "react-i18next";
import { STEP_DESIGN } from "../../reducer";
import _ from "lodash";
import { useVirtualCartInstallHelps } from "./install-helps/use-virtual-cart-install-helps";

export interface VirtualCartCategory {
  category: string;
  splitted: boolean;
  items: VirtualCartCategoryItem[];
}

export interface VirtualCartCategoryItem {
  details?: ProductEntity;
  img: string;
  label: string;
  price: number;
  link?: string;
  linkLabel?: string;
  trailingLabel?: string;
}

interface ProductsByCategory {
  name: string;
  products: ProductEntity[];
}

export const useVirtualCartItems = (): VirtualCartCategory[] => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);

  const panel = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData?.selected
  );
  const onduleur = useSelector(
    (state: RootState) => state.configurateurOnduleurs?.onduleursData?.selected
  );
  const panneauFixations = useSelector(
    (state: RootState) =>
      state.configurateurFixations?.fixationsData?.panneauFixation?.data
  );

  const supervision = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected
  );
  const garantie = useSelector(
    (state: RootState) => state.configurateurGaranties?.garantiesData?.selected
  );
  const battery = useSelector(
    (state: RootState) => state.configurateurBatteries?.batteriesData?.selected
  );
  const help = useSelector(
    (state: RootState) => state.configurateurHelps?.helpsData?.selected
  );
  const installHelps = useVirtualCartInstallHelps();

  const accessoireCombination = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
  );

  const categoryMain = t("virtualCart.category.main");
  const categoryServices = t("virtualCart.category.services");
  const categoryInstallHelp = t("virtualCart.category.installHelp");

  const accessoriesCategories =
    accessoireCombination && getAccessoriesCartItems(accessoireCombination);

  const coffrets = getCoffrets(accessoireCombination?.data);

  const mainCategory = getMainCartCategory(
    categoryMain,
    panel,
    panneauFixations,
    onduleur,
    coffrets
  );

  const supervisionCategories =
    supervision && getCategoriesFromProducts([supervision]);
  const batteryCategories =
    battery &&
    getCategoriesFromProducts([battery], {
      labelBuilder: (_category, product) => {
        return product?.name || product?.configurator_name;
      },
    });

  return [
    mainCategory,
    ...(supervisionCategories?.length > 0 ? supervisionCategories : []),
    ...(batteryCategories?.length > 0 ? batteryCategories : []),
    ...(accessoriesCategories?.length > 0 ? accessoriesCategories : []),
    ...(help || garantie
      ? [getServicesCartItems(categoryServices, help, garantie)]
      : []),
    ...(installHelps?.length > 0
      ? [
          getInstallHelpCartItems(
            categoryInstallHelp,
            installHelps,
            t("virtualCart.category.installHelp.paymentDescription")
          ),
        ]
      : []),
  ];
};

const getCategoriesFromProducts = (
  products: ProductEntity[],
  itemsBuilders?: {
    imgBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    labelBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    linkLabelBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    priceBuilder?: {
      (category: string, product: ProductEntity): number;
    };
  }
): VirtualCartCategory[] => {
  const group = _.groupBy(products, (product) => product?.category_name);
  const productsByCategory: ProductsByCategory[] = [];
  Object.keys(group).forEach((key) => {
    productsByCategory.push({
      name: key,
      products: group[key],
    });
  });
  return productsByCategory.map((category) => {
    return {
      category: category.name,
      splitted: false,
      items: category.products.map((product) =>
        getItemFromProduct(category.name, product, itemsBuilders)
      ),
    };
  });
};

const getItemFromProduct = (
  category: string,
  product: ProductEntity,
  itemsBuilders?: {
    imgBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    labelBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    linkLabelBuilder?: {
      (category: string, product: ProductEntity): string;
    };
    priceBuilder?: {
      (category: string, product: ProductEntity): number;
    };
  }
) => {
  const prefixLabel =
    product?.kitQuantity > 1 ? `${product?.kitQuantity}x ` : "";
  return {
    details: product,
    img: itemsBuilders?.imgBuilder
      ? itemsBuilders?.imgBuilder(category, product)
      : product?.img,
    label: itemsBuilders?.labelBuilder
      ? itemsBuilders?.labelBuilder(category, product)
      : `${prefixLabel}${product?.configurator_name}`,
    link: product?.link,
    linkLabel: itemsBuilders?.linkLabelBuilder
      ? itemsBuilders?.linkLabelBuilder(category, product)
      : "Accéder à la fiche produit",
    price: itemsBuilders?.priceBuilder
      ? itemsBuilders?.priceBuilder(category, product)
      : product?.kitPrice || product?.price,
  };
};

const getMainCartCategory = (
  category: string,
  panel?: PanelEntity,
  panneauFixations?: PanneauFixationEntity[],
  onduleur?: OnduleurEntity,
  coffrets?: AccessoryEntity[]
): VirtualCartCategory => {
  return {
    category: category,
    splitted: false,
    items: [
      ...(panel
        ? [
            getItemFromProduct(category, panel, {
              labelBuilder: (_category, product) => {
                const currentPanel = product as PanelEntity;
                const suffix = product?.configurator_label
                  ? ` ( ${product?.configurator_label} )`
                  : "";
                return `${currentPanel?.kitQuantity}x ${currentPanel?.category_name} - ${currentPanel?.configurator_name} ${currentPanel?.powerWcFeature?.value} Wc${suffix}`;
              },
              imgBuilder: (_category, product) => {
                return product?.image;
              },
            }),
          ]
        : []),
      ...(panneauFixations?.length > 0
        ? panneauFixations?.map((fixation) =>
            getItemFromProduct(category, fixation)
          )
        : []),
      ...(onduleur ? [getItemFromProduct(category, onduleur)] : []),
      ...(coffrets?.length > 0
        ? coffrets?.map((coffret) => getItemFromProduct(category, coffret))
        : []),
    ],
  };
};

const getAccessoriesCartItems = (
  accessoireCombination: ConfigurateurAccessoriesCombination
): VirtualCartCategory[] => {
  let accessoriesCategories = getCategoriesFromProducts(
    accessoireCombination?.data
  );
  // Remove "Coffret de protection" from category
  const coffrets = getCoffrets(accessoireCombination?.data);
  if (coffrets?.length > 0) {
    accessoriesCategories = accessoriesCategories.filter(
      (item) => item.category !== coffrets[0].category_name
    );
  }

  return accessoriesCategories?.sort((a, b) => {
    // if there is a fixation category into accessories
    // force to be in first position
    if (a?.category?.toLowerCase()?.includes("fixation")) {
      return -1;
    }
    return 0;
  });
};

const getCoffrets = (accessories: AccessoryEntity[]): AccessoryEntity[] => {
  return accessories?.filter(
    (item) =>
      // No other way to check the Coffrets category...
      item.id_category_default === "103"
  );
};

const getServicesCartItems = (
  category: string,
  help?: HelpEntity,
  garantie?: GarantieEntity
): VirtualCartCategory => {
  return {
    category: category,
    splitted: false,
    items: [
      ...(help ? [getItemFromProduct(category, help)] : []),
      ...(garantie ? [getItemFromProduct(category, garantie)] : []),
    ],
  };
};

const getInstallHelpCartItems = (
  category: string,
  installHelps: InstallHelpEntity[],
  trailingLabel: string
): VirtualCartCategory => {
  return {
    category: category,
    splitted: true,
    items: installHelps?.map((installHelp) => {
      return {
        img: installHelp?.thumbnail,
        label: installHelp?.title,
        price: Number.parseFloat(installHelp?.price),
        trailingLabel: trailingLabel,
      };
    }),
  };
};
