import { createGlobalStyle } from "styled-components";
import { fontsPath } from "../../utils/constants";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    src: url('${fontsPath}Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsPath}Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsPath}Poppins-Light.ttf');
    font-weight: 300;
    font-style: normal;
  }

  body {
    font-family: 'Poppins', sans-serif;
  }
`;
