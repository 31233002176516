export function transformNumberWithLocale(
  number: number,
  options?: Intl.NumberFormatOptions
): string {
  const language =
    window.navigator.languages && window.navigator.languages.length > 0
      ? window.navigator.languages[0]
      : window.navigator.language || "fr-FR";
  return new Intl.NumberFormat(language, {
    ...options,
  }).format(number);
}

export const formatNumber = (
  number: number,
  round: number = 2,
  displaySign: boolean = true,
  options?: Intl.NumberFormatOptions
) => {
  const currency = {
    style: "currency",
    currency: "EUR",
  };
  return transformNumberWithLocale(number, {
    ...(displaySign ? currency : {}),
    maximumFractionDigits: round,
    minimumFractionDigits: round,
    ...options,
  });
};
