import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { OnduleurEntity } from "../../../data/prestashop/services/entities/product-entity";

import { ConfigurateurOnduleursState } from "./configurateur-onduleurs-state";

const initialState: ConfigurateurOnduleursState = {
  onduleursData: {
    status: ComponentStatus.idle,
    hasSelectedOnce: false,
  },
};

enum ActionType {
  loadOnduleurs = "loadOnduleurs",
  selectOnduleur = "selectOnduleur",
}

export const configurationOnduleursReducer = (
  state = initialState,
  action
): ConfigurateurOnduleursState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadOnduleurs:
      return {
        ...state,
        onduleursData: {
          data: data || state?.onduleursData?.data,
          status: status,
          error: error,
          selected: selected || state?.onduleursData?.selected,
          hasSelectedOnce: state?.onduleursData?.hasSelectedOnce,
        },
      };
    case ActionType.selectOnduleur:
      return {
        ...state,
        onduleursData: {
          ...state.onduleursData,
          data: data || state?.onduleursData?.data,
          selected: selected,
          hasSelectedOnce: true,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurOnduleursLoad =
  (
    emplacementId: number,
    fixationId: string,
    panelId: string,
    panelsNumber: number,
    phaseNumber: number
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.loading,
      });

      const onduleurs = await configurateurService.getOnduleurs(
        emplacementId,
        fixationId,
        panelId,
        panelsNumber,
        phaseNumber
      );

      const selected = getDefaultSelection(
        panelsNumber,
        onduleurs,
        getState().configurateurOnduleurs?.onduleursData?.hasSelectedOnce,
        getState().configurateurOnduleurs?.onduleursData?.selected
      );

      const onduleursWithPriceDelta = onduleurs.map((panel) => {
        return {
          ...panel,
          priceDelta: panel.kitPrice - selected.kitPrice,
        };
      });
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.loaded,
        data: onduleursWithPriceDelta,
        selected: selected,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurOnOnduleurSelected =
  (onduleurId: string) => (dispatch, getState) => {
    const onduleursData = getState().configurateurOnduleurs?.onduleursData;
    const newSelectedOnduleur = onduleursData?.data?.find(
      (onduleur) => onduleur.id_product === onduleurId
    );
    const onduleursWithPriceDelta = onduleursData.data.map((onduleur) => {
      return {
        ...onduleur,
        priceDelta: onduleur.kitPrice - newSelectedOnduleur.kitPrice,
      };
    });

    if (
      newSelectedOnduleur &&
      onduleursData?.selected?.id_product !== newSelectedOnduleur?.id_product
    ) {
      dispatch({
        type: ActionType.selectOnduleur,
        selected: newSelectedOnduleur,
        data: onduleursWithPriceDelta,
      });
    }
  };

export const isMicroOnduleur = (onduleur: OnduleurEntity): boolean => {
  return onduleur.features.some((feature) => {
    return feature.title === "Type" && feature.value === "Micro-onduleur";
  });
};

const getDefaultSelection = (
  panelsNumber: number,
  onduleurs: OnduleurEntity[],
  hasSelectedOnce: boolean,
  lastOnduleurSelected?: OnduleurEntity
): OnduleurEntity => {
  // Return oscaro choice if no onduleur previously selected
  if (!hasSelectedOnce) {
    const oscaroChoices = getOscaroChoicesForPanelsNumber(panelsNumber);

    let selectedOnduleur = undefined;
    for (const choice of oscaroChoices) {
      const onduleurChoice = onduleurs.find(
        (onduleur) => onduleur?.id === choice
      );
      if (onduleurChoice) {
        selectedOnduleur = onduleurChoice;
        break;
      }
    }

    return (
      selectedOnduleur ||
      onduleurs.find((onduleur) => oscaroChoices.includes(onduleur?.id)) ||
      onduleurs[0]
    );
  }

  if (lastOnduleurSelected) {
    const previous = onduleurs.find(
      (onduleur) =>
        lastOnduleurSelected.id_product_attr === onduleur.id_product_attr
    );
    // Return previous selection if exists into new [onduleurs] list
    if (previous) return previous;

    // Return a product with the same manufacturer name if exists
    const sameManufacturer = onduleurs.find(
      (onduleur) =>
        onduleur?.manufacturer_name === lastOnduleurSelected?.manufacturer_name
    );
    if (sameManufacturer) return sameManufacturer;
  }
  return onduleurs[0];
};

// This should be returned by configurateur api...
const getOscaroChoicesForPanelsNumber = (panelsNumber: number): string[] => {
  if (panelsNumber <= 7) {
    return ["309", "1444"];
  }
  return ["1175", "1174", "1006", "1444"];
};
