export const useVirtualCartKitProfitability = (kitPrice, economy) => {
  if (economy) {
    return convertMonthlySavingsToYears(kitPrice, economy);
  }
};

const convertMonthlySavingsToYears = (kitPrice, monthlySavings) => {
  let cumulatedSavings = 0;
  let month = 0;
  while (cumulatedSavings < kitPrice) {
    cumulatedSavings += monthlySavings;
    month += 1;
  }
  // Convert months to years and round to the next year
  return Math.round(month / 12);
};
