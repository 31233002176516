import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurBatteriesState } from "./configurateur-batteries-state";

const initialState: ConfigurateurBatteriesState = {
  batteriesData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadBatteries = "loadBatteries",
  selectBattery = "selectBattery",
}

export const configurationBatteriesReducer = (
  state = initialState,
  action
): ConfigurateurBatteriesState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadBatteries:
      return {
        ...state,
        batteriesData: {
          data: data || state?.batteriesData?.data,
          status: status,
          error: error,
          selected: selected || state?.batteriesData?.selected,
        },
      };
    case ActionType.selectBattery:
      return {
        ...state,
        batteriesData: {
          ...state.batteriesData,
          data: data || state?.batteriesData?.data,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurBatteriesLoad =
  (onduleurIdAttribute: string, phaseNumber: number) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.loading,
      });
      const batteries = await configurateurService.getBatteries(
        onduleurIdAttribute,
        phaseNumber
      );

      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.loaded,
        data: batteries,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurBatterySelect =
  (batteryIdAttribute?: string) => (dispatch, getState) => {
    const batteriesState = getState()
      .configurateurBatteries as ConfigurateurBatteriesState;
    const selectedBattery = batteriesState?.batteriesData?.data?.find(
      (battery) => battery?.id_product_attr === batteryIdAttribute
    );

    dispatch({
      type: ActionType.selectBattery,
      selected: selectedBattery,
    });
  };
