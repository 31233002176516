import { useSelector } from "react-redux";
import { RootState } from "../..";
import { PrestashopCartItemDto } from "../../../data/prestashop/api/dtos/prestashop-cart-item-dto";
import { mapObjectToCartItem } from "../../../data/prestashop/services/products-service";
import { InstallHelpEntity } from "../../../data/prestashop/services/entities/product-entity";

export const useVirtualCartItemsToAdd = (): PrestashopCartItemDto[] => {
  const panel = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData?.selected
  );
  const onduleur = useSelector(
    (state: RootState) => state.configurateurOnduleurs?.onduleursData?.selected
  );
  const panneauFixations = useSelector(
    (state: RootState) =>
      state.configurateurFixations?.fixationsData?.panneauFixation?.data
  );
  const supervision = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected
  );
  const garantie = useSelector(
    (state: RootState) => state.configurateurGaranties?.garantiesData?.selected
  );
  const battery = useSelector(
    (state: RootState) => state.configurateurBatteries?.batteriesData?.selected
  );
  const help = useSelector(
    (state: RootState) => state.configurateurHelps?.helpsData?.selected
  );

  const accessoireCombination = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
  );

  return [
    ...(panel ? [panel] : []),
    ...(onduleur ? [onduleur] : []),
    ...(supervision ? [supervision] : []),
    ...(garantie ? [garantie] : []),
    ...(battery ? [battery] : []),
    ...(help ? [help] : []),
    ...(panneauFixations?.length > 0 ? panneauFixations : []),
    ...(accessoireCombination?.data?.length > 0
      ? accessoireCombination?.data
      : []),
  ].map((product) =>
    mapObjectToCartItem(product?.id_product_attr, product?.kitQuantity)
  );
};

export const useVirtualCartInstallHelpToAdd = (): InstallHelpEntity => {
  const dealtOffer = useSelector((state: RootState) => {
    if (state.configurateurInstallHelps?.installHelpsData?.error) {
      return null;
    }
    return state.configurateurInstallHelps?.installHelpsData?.installHelpsSelected?.find(
      (help) => help.checked
    )?.installHelp;
  });
  return dealtOffer;
};
