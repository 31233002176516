import styled from "styled-components";

export const BuilderRouterContainer = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr;
  overflow: hidden;
  height:100%;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    grid-template-rows: 50px 1fr;
  }
`;
