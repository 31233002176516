import styled from "styled-components";

export const OTooltip = styled.div`
  z-index: 1;
  padding: 16px 24px 16px 24px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.onyx.onyxPower600};

  p {
    color: ${(props) => props.theme.colors.clay.clayPower200};
    text-align: center;
  }
`;
