import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { GarantieEntity } from "../../../data/prestashop/services/entities/product-entity";
import { ConfigurateurGarantiesState } from "./configurateur-garanties-state";

const initialState: ConfigurateurGarantiesState = {
  garantiesData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadGarantie = "loadGarantie",
  selectGarantie = "selectGarantie",
}

export const configurationGarantiesReducer = (
  state = initialState,
  action
): ConfigurateurGarantiesState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadGarantie:
      return {
        ...state,
        garantiesData: {
          data: data || state?.garantiesData?.data,
          status: status,
          error: error,
          selected: selected || state?.garantiesData?.selected,
        },
      };
    case ActionType.selectGarantie:
      return {
        ...state,
        garantiesData: {
          ...state.garantiesData,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurGarantiesLoad = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.loadGarantie,
      status: ComponentStatus.loading,
    });
    const garanties = await configurateurService.getGaranties();

    dispatch({
      type: ActionType.loadGarantie,
      status: ComponentStatus.loaded,
      data: garanties,
    });
  } catch (_) {
    dispatch({
      type: ActionType.loadGarantie,
      status: ComponentStatus.error,
    });
  }
};

export const configurateurGarantiesSelect =
  (checked: boolean) => async (dispatch, getState) => {
    const garanties = getState().configurateurGaranties?.garantiesData
      ?.data as GarantieEntity[];

    dispatch({
      type: ActionType.selectGarantie,
      selected: checked ? garanties[0] : null,
    });
  };
