import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import {
  EquipementsData,
  NeedTypeImageLinks,
  NeedTypePower,
} from "./constants";
import { useTranslation } from "react-i18next";
import { STEP_NEEDS } from "../../../../../store/reducer";
import { createSunWithEquipmentAroundMovable } from "./d3_graph";
import { EquipmentViewerContainer } from "./styled";

const EquipmentViewer: React.FC<{ selectedsEquipements: any[] }> = ({
  selectedsEquipements,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const { t } = useTranslation([STEP_NEEDS + "phase2", "global"]);

  useEffect(() => {
    let equipementData: EquipementsData[] = [];

    selectedsEquipements.forEach((equipment) => {
      const equipmentData = {
        name: equipment,
        power: NeedTypePower[equipment],
        link: NeedTypeImageLinks[equipment],
        label: t(`products.${equipment}`),
        unit: t("kw"),
      };
      equipementData.push(equipmentData);
    });

    // remove all duplicated names
    equipementData = equipementData.filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );

    const simulation = createSunWithEquipmentAroundMovable(
      svgRef.current,
      equipementData
    );

    return () => {
      simulation.stop();
      d3.select(svgRef.current).selectAll("*").remove();
    };
  }, [selectedsEquipements]);

  return (
    <EquipmentViewerContainer>
      <svg ref={svgRef} width="100%" height="100%" />
    </EquipmentViewerContainer>
  );
};

export default EquipmentViewer;
