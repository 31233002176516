import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import {
  OBody10,
  OBody11,
  OBody12,
  OBody6,
  OBody8,
  OBody9,
  OH3,
} from "../../../../../Styled/Texts";
import { SectionSolarKitData } from "./constants";
import {
  PanelImg,
  SectionSolarKit,
  SolarKitCard,
  SolarKitContainer,
} from "./styled";
import { configurateurOnPanelSelected } from "../../../../../../store/configurateur/panels/configurateur-panels-reducer";
import { OnduleurItem, OnduleurSelectedItem } from "../onduleurs/onduleur-item";
import { OnduleurContainer } from "../onduleurs/onduleur-styled";
import { AskIcon } from "../../../../../Styled/Buttons/AskButton/styled";
import { OnduleursTooltip } from "../onduleurs/tooltip/onduleurs-tooltip";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { OLinkButton } from "../../../../../Styled/Buttons/Link/o-link-button";
import { theme } from "../../../../../Styled/GlobalStyle";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";

const SolarKitComponent: React.FC<{
  initial: SectionSolarKitData;
}> = () => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.SOLAR_KIT;
  const { t } = useTranslation([currentStepName, "global"]);

  const panelsData = useSelector(
    (state: RootState) => state.configurateurPanels.panelsData
  );

  const onduleursData = useSelector(
    (state: RootState) => state.configurateurOnduleurs.onduleursData
  );

  const alimentationData = useSelector(
    (state: RootState)=> state.configurateurAlimentation.alimentationData
  )

  const [showItems, setShowItems] = useState<boolean>(false);
  const { popup, setPopup } = useContext(ConfigurateurPopupContext);

  return (
    <SectionSolarKit
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true" className="mb-4">
        {t("phase_2.sections." + currentSectionName + ".title")}
      </OH3>
      <OBody6 center="true">
        {t("phase_2.sections." + currentSectionName + ".sections.panels.title")}
      </OBody6>
      <OLinkButton
        className="conception-configuration-panel-viewmore"
        color={theme.colors.clay.clayPower700}
        onClick={() => {
          setPopup(ConfigurateurPopups.popupPanelDetails);
        }}
      >
        {t(
          "phase_2.sections." + currentSectionName + ".sections.panels.viewmore"
        )}
      </OLinkButton>
      <motion.div>
        <SolarKitContainer>
          {panelsData?.data?.map((panel) => {
            const isActive =
              panel?.id_product === panelsData.selected?.id_product;

            return (
              <SolarKitCard
                key={panel?.id_product}
                onClick={() => {
                  store.dispatch(
                    configurateurOnPanelSelected(panel?.id_product)
                  );
                }}
                isactive={isActive.toString()}
              >
                <PanelImg>
                  <div className="h-100 overflow-hidden rounded">
                    <img
                      src={panel?.image}
                      alt={panel?.name}
                      className="img-fluid rounded"
                    />
                  </div>
                  <div className="panelTag">
                    {panel?.configurator_label && (
                      <OBody11>{panel?.configurator_label}</OBody11>
                    )}
                  </div>
                </PanelImg>

                <OBody8 center="true">{panel?.configurator_name}</OBody8>

                <OBody12 center="true">
                  {panel?.powerWcFeature?.value} Wc
                </OBody12>
                {panel?.manufacturer_name && (
                  <OBody12 center="true">{panel?.manufacturer_name}</OBody12>
                )}

                {!isActive && (
                  <OBody10 center="true">
                    {panel?.priceDelta > 0 && "+"}
                    {formatNumber(panel?.priceDelta, 0)}
                  </OBody10>
                )}
              </SolarKitCard>
            );
          })}
        </SolarKitContainer>
      </motion.div>
      <div className="section-title-ask mt-4">
        <OBody6 center="true">
          {t(
            "phase_2.sections." +
              currentSectionName +
              ".sections.inverter.title"
          )}
        </OBody6>
        <AskIcon className="onduleurs-ask">
          ?<OnduleursTooltip />
        </AskIcon>
      </div>
      <OnduleurContainer display={showItems}>
        <OnduleurSelectedItem
          onduleur={onduleursData?.selected}
          showItems={showItems}
          setShowItems={setShowItems}
        />
        {showItems && <hr className="my-1" />}
        <div className="onduleurs">
          {onduleursData?.data
            ?.filter(
              (onduleur) =>
                onduleursData?.selected?.id_product !== onduleur.id_product
            )
            .map((onduleur) => {
              return (
                <OnduleurItem
                  onduleur={onduleur}
                  showItems={showItems}
                  setShowItems={setShowItems}
                />
              );
            })}
        </div>
      </OnduleurContainer>

      {/* Monkey FIX - Contacter le CRC si installation Triphasée et sélection Enphase */}
      {
        onduleursData?.selected?.id_product == '1444'
        && alimentationData?.type == "three_phase" 
        && <OBody12 center="true"> 🟠 Merci de nous contacter afin de valider votre panier</OBody12>
      }
      
    </SectionSolarKit>
  );
};

export default SolarKitComponent;
