import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody4, OBody6, OBody9, OBody12, OH3 } from "../../../../../Styled/Texts";
import { SectionAdministrationHelpData } from "./constants";
import { SectionAdministrationHelp } from "./styled";
import { ConfigurateurHelpsState } from "../../../../../../store/configurateur/helps/configurateur-helps-state";
import { configurateurHelpSelect } from "../../../../../../store/configurateur/helps/configurateur-helps-reducer";
import { SelectableRadioCard } from "../../../../../Styled/selectable-radio-card copy/selectable-radio-card";
import { HelpEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { motion } from "framer-motion";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";

const AdministrationHelpComponent: React.FC<{
  initial: SectionAdministrationHelpData;
}> = () => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.ADMINISTRATION_HELP;
  const { t } = useTranslation([currentStepName, "global"]);

  const helpsState = useSelector(
    (state: RootState) => state.configurateurHelps
  ) as ConfigurateurHelpsState;

  const helps = helpsState?.helpsData?.data;

  const { popup, setPopup } = useContext(ConfigurateurPopupContext);

  return (
    <SectionAdministrationHelp
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true">
        <Trans>{t("phase_2.sections." + currentSectionName + ".title")}</Trans>
      </OH3>
      <OBody9 center="true">
        <Trans>
          {t("phase_2.sections." + currentSectionName + ".subtitle")}
        </Trans>
      </OBody9>

      {/* En savoir plus */}
      <motion.div className="section__title__col">
        <OBody6
          className="conception-configuration-aide-administrative-viewmore"
          center="true"
          onClick={() => setPopup(ConfigurateurPopups.popupHelp)}
        >
          {t("phase_2.sections." + currentSectionName + ".viewMore")}
        </OBody6>
      </motion.div>

      {helps?.length ? (
        <>
          <SelectableRadioCard
            checked={
              helpsState?.helpsData?.selected === null ||
              helpsState?.helpsData?.selected === undefined
            }
            label={t("administrativeHelpNoHelp")}
            trailingLabel={formatNumber(0, 0)}
            onClick={() => {
              store.dispatch(configurateurHelpSelect(null));
            }}
          ></SelectableRadioCard>
          {helps?.length > 0 &&
            helpsState?.helpsData?.data?.map((help) => {
              const isSelected =
                helpsState?.helpsData?.selected?.id_product_attr ===
                help?.id_product_attr;

              return (
                <SelectableRadioCard
                  checked={isSelected}
                  label={help?.configurator_name || help?.name}
                  trailingLabel={formatNumber(help?.kitPrice)}
                  onClick={() => {
                    store.dispatch(configurateurHelpSelect(help?.id_product_attr));
                  }}
                ></SelectableRadioCard>
              );
            })}        
            {/* <OBody4 center='true'>250€ offerts sur l'offre JPME avec le code : SOLAIRE250</OBody4> */}
            {/* <OBody12 center='true'>Remise valable uniquement sur l'offre JPME + demande préalable de travaux en mairie sur un panier minimum de 1500€</OBody12>  */}
        </>
        ) : (
          <OActionButton className="design" disabled={true}>
            {t("administrativeHelp.unavailableForCurrentConfiguration")}
          </OActionButton>
      )}
    </SectionAdministrationHelp>
  );
};

export default AdministrationHelpComponent;
