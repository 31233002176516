import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import {
  STEP_DESIGN,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  setStepAccessible,
  setStepDataWithoutLodash,
  setStepPhase1,
} from "../../../../../store/reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { OMainButton, OReturnButton } from "../../../../Styled/Buttons";
import { OBody9, OH1, OH3 } from "../../../../Styled/Texts";
import {
  NeedType,
  NeedTypePower,
  NeedPhase2Props,
  needPhase2Config,
} from "./constants";
import EquipmentViewer from "./equipement_viewer";
import { EquipmentOEquipCard } from "./equipement_card";
import { fade } from "../../../../Styled/GlobalAnimations";
import { OEquipGrid } from "./styled";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import useIsMobile from "../../../../../utils/useIsMobile";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
const NeedPhase2: React.FC<NeedPhase2Props> = ({ onContinue, onReturn }) => {
  const currentStepName = STEP_NEEDS;
  const isMobile = useIsMobile();
  const { t } = useTranslation([currentStepName + "phase2", "global"]);
  const config = needPhase2Config;
  const dispatch = useDispatch();

  const reduxEquipements = useSelector(
    (state: RootState) => state.step.steps[STEP_NEEDS].data?.phase2?.equipements
  );

  const dataStep1 = useSelector(
    (state: RootState) => state.step.steps[STEP_NEEDS].data?.phase1
  );

  const [budget] = useState<number>(dataStep1?.budget);

  const [selectedEquipements, setSelectedEquipements] = useState<string[]>([]);

  useEffect(() => {
    setSelectedEquipements(reduxEquipements || []);
  }, [reduxEquipements]);

  useEffect(() => {}, [selectedEquipements]);

  const handleUpdate = (type: NeedType) => {
    const isCurrentlySelected = selectedEquipements.includes(type);
    if (isCurrentlySelected) {
      setSelectedEquipements(
        selectedEquipements.filter((equipment) => equipment !== type)
      );
    } else {
      setSelectedEquipements([...selectedEquipements, type]);
    }
  };

  const handleContinue = () => {
    dispatch(
      setStepDataWithoutLodash({
        step: STEP_NEEDS,
        data: { phase2: { equipements: selectedEquipements } },
      })
    );
    dispatch(setStepAccessible({ step: STEP_DESIGN, accessible: false }));
    dispatch(setStepPhase1({ step: STEP_RECOMMANDATION }));
    dispatch(setStepPhase1({ step: STEP_DESIGN }));
    trackDataLayer("EquipementsSelected", {
      equipements: selectedEquipements,
    });
    onContinue();
  };
  return (
    <ORow className="h-100">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className="d-flex flex-column justify-content-between align-items-center light-bg gap-3 p-0 p-md-3 h-100 overflow-auto"
      >
        <motion.span className="d-flex flex-column justify-content-start align-items-center w-100 gap-4">
          {isMobile ? (
            <OH1 center="true" {...fade(0.5)}>
              <Trans> {t("title")}</Trans>
            </OH1>
          ) : (
            <OH3 center="true" {...fade(0.5)}>
              <Trans> {t("title")}</Trans>
            </OH3>
          )}
          <OBody9 center="true">{t("subtitle")}</OBody9>

          <OEquipGrid {...fade(0.9)}>
            {Object.values(NeedType).map((type) => (
              <EquipmentOEquipCard
                key={type}
                type={type}
                label={t(`products.${type}`)}
                unit={t("kw")}
                power={NeedTypePower[type]}
                update={handleUpdate}
                isSelected={selectedEquipements.includes(type)}
              />
            ))}
          </OEquipGrid>
        </motion.span>
        <motion.span className="d-flex flex-column justify-content-start align-items-center w-100">
          <OMainButton
            className="w-100 besoins-equipements-next-btn"
            primary
            onClick={handleContinue}
          >
            {t("global:validate")}
          </OMainButton>
          <OReturnButton
            className="w-100 besoins-equipements-prev-btn"
            onClick={onReturn}
            {...fade(0.3)}
          >
            {t("global:return")}
          </OReturnButton>
        </motion.span>
      </OCol>
      <OCol
        sm={config.gridConfig.right[0]}
        md={config.gridConfig.right[1]}
        lg={config.gridConfig.right[2]}
        className="neutral-bg p-2 contentsized"
      >
        <EquipmentViewer selectedsEquipements={selectedEquipements} />
      </OCol>
    </ORow>
  );
};

export default NeedPhase2;
