import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SimulationState } from "../../store/simulation/simulation-state";
import { useVirtualCartKitPower } from "../../store/configurateur/virtual-cart/use-virtual-cart-kit-power";

// Use a simulation result
export const useSimulationFormula = () => {
  const dimensionneur = useSelector(
    (state: RootState) =>
      (state?.simulationReducer as SimulationState)?.simulation?.data?.all
  );
  const panelNumber = useSelector(
    (state: RootState) => state.calepinageReducer?.panelCountInCalepinage
  );
  const battery = useSelector(
    (state: RootState) => state?.configurateurBatteries?.batteriesData?.selected
  );

  const isHelpWithSurplusSelected = useSelector(
    (state: RootState) =>
      state.configurateurHelps?.helpsData?.selected?.hasSurplus
  );

  const kitPower = useVirtualCartKitPower();
  if (panelNumber) {
    if (!isHelpWithSurplusSelected && !battery) {
      return dimensionneur?.solar[panelNumber];
    } else if (isHelpWithSurplusSelected && !battery) {
      if (kitPower > 9) {
        return dimensionneur?.solar[panelNumber];
      }
      return dimensionneur?.maxPower[panelNumber];
    } else if (isHelpWithSurplusSelected && battery) {
      return dimensionneur?.liberté[panelNumber];
    } else if (!isHelpWithSurplusSelected && battery) {
      return dimensionneur?.autarcie[panelNumber];
    }
    return null;
  }
};
