import { useSimulationFormula } from "../../../hooks/simulation/use-simulation-power";

export const useVirtualCartEconomy = (): number => {

  // const economyIndex = 15;
  // const revenuIndex = 8;
  const smartEconomyIndex = 0;
  const simulationPower = useSimulationFormula();

  if (simulationPower) {
    // const annualEconomy = simulationPower[economyIndex];
    // const annualRevenue = simulationPower[revenuIndex];
    // return (annualEconomy + annualRevenue) / 12;
    const annualSmartRevenue = simulationPower[smartEconomyIndex];
    return annualSmartRevenue / 12;
  }

};