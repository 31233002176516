import { PrestashopCartItemDto } from "../api/dtos/prestashop-cart-item-dto";
import { ProductDto } from "../api/dtos/product-dto";
import { SettingsDto } from "../api/dtos/settings-dto";
import {
  getProductsById,
  getDefaultSettings,
  getOscaroPowerProductsById,
  addProductsToCart,
  addDealtOfferToCart,
} from "../api/prestashop-api";
import {
  InstallHelpEntity,
  ProductEntity,
  ProductsEntity,
} from "./entities/product-entity";

export const getProducts = async (
  productIds: string[] = []
): Promise<ProductsEntity> => {
  if (productIds.length === 0) {
    return { products: [] };
  }

  return getProductsById(productIds).then((productsList) => {
    return {
      products: productsList.products.map((product) =>
        getProductEntityFromDto(product)
      ),
    };
  });
};

// TODO: Entity for Oscaro-Power Products
export const getOscaroPowerProducts = async (
  productIds: string[] = []
): Promise<ProductsEntity> => {
  if (productIds.length === 0) {
    return { products: [] };
  }

  return getOscaroPowerProductsById(productIds).then((productsList) => {
    return {
      products: productsList.products.map((product) =>
        getProductEntityFromDto(product)
      ),
    };
  });
};

// TODO: Entity for Settings
export const getSettings = async (): Promise<SettingsDto> => {
  return getDefaultSettings().then((data) => {
    return data;
  });
};

export const getProductEntityFromDto = (product: ProductDto): ProductEntity => {
  return {
    ...product,
    is_oscaro_choice:
      product?.is_oscaro_choice === "1" || product?.oscaro_choice === "1",
  };
};

export const addToCart = async (
  cartId: number,
  products: PrestashopCartItemDto[]
) => {
  const items = products.filter((product) => product.quantity > 0);
  return await addProductsToCart(cartId, items);
};

// Add dealt product to cart
export const saveDealtToCart = async (
  cartId: number,
  dealtOffer: InstallHelpEntity
): Promise<void> => {
  return await addDealtOfferToCart(cartId, dealtOffer);
};

export const mapObjectToCartItem = (idAttribute: string, quantity: number) => {
  const [id, attribute] = idAttribute.split("_");
  return {
    id_product: id,
    id_product_attribute: attribute,
    quantity: quantity,
  };
};
