// import React from "react";
// import ReactDOM from "react-dom";

// migrate React 18 - createRoot
import { StrictMode } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from "styled-components";
import store from "./store";
import Routes from "./components/BuilderRouter";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { CustomScrollbar } from "./components/Styled/CustomScrollBar";
import { GlobalFonts } from "./components/Styled/Fonts";
import { GlobalStyle, theme } from "./components/Styled/GlobalStyle";
import { SkeletonTheme } from "react-loading-skeleton";

import 'material-symbols';

const container = document.getElementById('root-configurateur');
const root = createRoot(container!);

root.render(
<StrictMode>
  {/* <React.StrictMode> */}
    {/*State Management */}
    <Provider store={store}>
      {/* Global theming */}
      <ThemeProvider theme={theme}>
        <SkeletonTheme
          baseColor={theme.colors.clay.clayPower600}
          borderRadius={4}
        >
          <CustomScrollbar />
          {/* Custom Router */}
          <BrowserRouter>
            {/* Global Fonts */}
            <GlobalFonts />

            {/* Global styling */}
            <GlobalStyle />

            {/* Custom routes */}
            <Routes />
          </BrowserRouter>
        </SkeletonTheme>
      </ThemeProvider>
    </Provider>
  {/* </React.StrictMode> */}
  </StrictMode>
);

reportWebVitals();
