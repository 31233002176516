import styled from "styled-components";
import { motion } from "framer-motion";

export const ConfirmZone = styled(motion.div)``;

export const Section = styled(motion.section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;

  .section__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .section__title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    gap: 10px;

    &__col {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 0;
      }

      p {
        color: ${(props) => props.theme.colors.clay.clayPower700};
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .section-title-ask {
    align-items: center;
    display: flex;
    flex-direction: row;

    p {
      padding: 0px 8px 0px 0px;
    }
  }
`;

export const TotalContent = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.clay.clayPower700};

  p:first-child {
    text-align: right;
  }

  p:last-child {
    text-align: left;
  }

  p {
    padding-top: 16px;
    padding-bottom: 8px;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    padding-bottom: 16px;
  }
`;

export const SectionCalepinageHorsToitureType = styled(Section)``;

export const SectionAlimentationType = styled(Section)`
  .alimentation-viewmore:hover {
    cursor: pointer;
  }
`;

export const SectionSolarKit = styled(Section)``;

export const SolarKitContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const SolarKitCard = styled(motion.div)<{ isactive: string }>`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1px;
  width: 100%;
  height: 100%;
  img {
    transform: scale(2.5);
    transform-origin: center;
    box-sizing: border-box;
    aspect-ratio: 1/1;
    object-fit: cover;
    height: 100%;
  }
  ${(props) =>
    props.isactive === "true" &&
    `
    & > div {
      border-color: ${props.theme.colors.primary.bluePower700};
    }
  `};
`;

export const PanelImg = styled.div`
  position: relative;
  padding:0.75rem;
  border-radius: 12px;
  border: 2px solid ${(props) => props.theme.colors.clay.clayPower300};
  border-box
  width: 90px;
  height: 140px;
  box-sizing: border-box;
  .panelTag {
    position: absolute;
    top: 50%;
    left:-2px;
    right:-2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: ${(props) => props.theme.colors.tertiary.leafPower700};
    border-radius: 4px;
  }
  p {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.clay.clayPower200};
  }
`;

export const SectionSupervising = styled(Section)``;

export const SectionWiring = styled(Section)``;

export const SectionLightningArrester = styled(Section)``;

export const SectionWarranty = styled(Section)``;

export const SectionBattery = styled(Section)``;

export const SectionAdministrationHelp = styled(Section)``;

export const SectionInstallationHelp = styled(Section)`
  a {
    align-self: end;
  }
`;

export const SectionConfirm = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
