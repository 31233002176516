import { AlimType } from "../../../components/Steps/Design/phases/phase2/sections_content/constants";
import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { dealtDepartmentsAvailable } from "../../../data/configurateur/services/dealt-departments-available";
import { OnduleurEntity } from "../../../data/prestashop/services/entities/product-entity";
import { isMicroOnduleur } from "../onduleurs/configurateur-onduleurs-reducer";
import {
  ConfigurateurInstallHelpErrors,
  ConfigurateurInstallHelpsState,
} from "./configurateur-install-helps-state";

const initialState: ConfigurateurInstallHelpsState = {
  installHelpsData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadInstallHelps = "loadInstallHelps",
  selectInstallHelp = "selectInstallHelp",
  checkEligibility = "checkEligibility",
}

export const configurationInstallHelpsReducer = (
  state = initialState,
  action
): ConfigurateurInstallHelpsState => {
  const { data, status, error, installHelpsSelected, postcode } = action;

  switch (action.type) {
    case ActionType.loadInstallHelps:
      return {
        ...state,
        installHelpsData: {
          ...state.installHelpsData,
          data: data || state?.installHelpsData?.data,
          status: status,
          error: error,
          installHelpsSelected:
            installHelpsSelected ||
            state?.installHelpsData?.installHelpsSelected,
          postcode: state?.installHelpsData?.postcode,
        },
      };
    case ActionType.selectInstallHelp:
      return {
        ...state,
        installHelpsData: {
          ...state.installHelpsData,
          data: data || state?.installHelpsData?.data,
          installHelpsSelected:
            installHelpsSelected ||
            state?.installHelpsData?.installHelpsSelected,
          error: state?.installHelpsData?.error,
          postcode: state?.installHelpsData?.postcode,
        },
      };

    case ActionType.checkEligibility:
      return {
        ...state,
        installHelpsData: {
          ...state.installHelpsData,
          error: error,
          installHelpsSelected:
            installHelpsSelected ||
            state?.installHelpsData?.installHelpsSelected,
          postcode: postcode || state?.installHelpsData?.postcode,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurInstallHelpsLoad =
  (postcode: string, onduleur: OnduleurEntity, alimentation: AlimType) =>
  async (dispatch, getStore) => {
    try {
      if (
        !isEligible(postcode) ||
        !isMicroOnduleur(onduleur) ||
        alimentation !== AlimType.MONOPHASE
      ) {
        dispatch({
          type: ActionType.loadInstallHelps,
          status: ComponentStatus.loaded,
          error: !isEligible(postcode)
            ? ConfigurateurInstallHelpErrors.department
            : ConfigurateurInstallHelpErrors.configuration,
        });
        return;
      }
      dispatch({
        type: ActionType.loadInstallHelps,
        status: ComponentStatus.loading,
      });
      const dealtOffers = await configurateurService.getDealtOffers();

      const prevsSelectedIds =
        getStore().configurateurInstallHelps?.installHelpsData?.installHelpsSelected?.map(
          (selection) => selection?.installHelp?.id
        );
      dispatch({
        type: ActionType.loadInstallHelps,
        status: ComponentStatus.loaded,
        data: dealtOffers,
        installHelpsSelected: dealtOffers?.map((offer) => {
          return {
            checked: prevsSelectedIds?.includes(offer.id),
            installHelp: offer,
          };
        }),
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadInstallHelps,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurInstallHelpSelect =
  (id?: string) => async (dispatch, getState) => {
    const installHelpsState = getState()
      .configurateurInstallHelps as ConfigurateurInstallHelpsState;

    dispatch({
      type: ActionType.selectInstallHelp,
      installHelpsSelected: installHelpsState?.installHelpsData?.data?.map(
        (offer) => {
          const selectable =
            installHelpsState?.installHelpsData?.installHelpsSelected?.find(
              (selected) => selected?.installHelp?.id === offer?.id
            );
          return {
            checked:
              offer?.id === id ? !selectable?.checked : selectable?.checked,
            installHelp: offer,
          };
        }
      ),
    });
  };

export const configurateurInstallHelpCheckEligibility =
  (postcode?: string) => (dispatch, getStore) => {
    const error = !isEligible(postcode)
      ? ConfigurateurInstallHelpErrors.department
      : null;

    dispatch({
      type: ActionType.checkEligibility,
      error: error,
      installHelpsSelected: error !== undefined ? [] : undefined,
      postcode: postcode,
    });
  };

const isEligible = (postcode?: string) => {
  if (postcode?.length < 2) return false;
  const department = postcode?.slice(0, 2);
  return dealtDepartmentsAvailable?.some((availableDep) => {
    return availableDep.includes(department);
  });
};
