import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurHelpsState } from "./configurateur-helps-state";

const initialState: ConfigurateurHelpsState = {
  helpsData: {
    status: ComponentStatus.idle,
    hasSelectedOnce: false,
  },
};

enum ActionType {
  loadHelps = "loadHelps",
  selectHelp = "selectHelp",
}

export const configurationHelpsReducer = (
  state = initialState,
  action
): ConfigurateurHelpsState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadHelps:
      return {
        ...state,
        helpsData: {
          data: data || state?.helpsData?.data,
          status: status,
          error: error,
          selected: selected || state?.helpsData?.selected,
          hasSelectedOnce: state?.helpsData?.hasSelectedOnce,
        },
      };
    case ActionType.selectHelp:
      return {
        ...state,
        helpsData: {
          ...state.helpsData,
          data: data || state?.helpsData?.data,
          selected: selected,
          hasSelectedOnce: true,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurHelpsLoad =
  (
    panelIdAttribute: string,
    panelsNumber: number,
    batteryIdAttribute: string
  ) =>
  async (dispatch, getStore) => {
    try {
      dispatch({
        type: ActionType.loadHelps,
        status: ComponentStatus.loading,
      });
      const helps = await configurateurService.getHelps(
        panelIdAttribute,
        panelsNumber,
        batteryIdAttribute
      );
      let selected = helps?.find(
        (help) =>
          getStore()?.configurateurHelps.helpsData?.selected
            ?.id_product_attr === help?.id_product_attr
      );
      if (!getStore().configurateurHelps?.helpsData?.hasSelectedOnce) {
        selected = helps?.find((help) => help?.default);
      }

      dispatch({
        type: ActionType.loadHelps,
        status: ComponentStatus.loaded,
        // sort asc by price
        data: helps.sort((a, b) => a.price - b.price),
      });

      dispatch({
        type: ActionType.selectHelp,
        selected: selected,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadHelps,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurHelpSelect =
  (helpIdAttribute?: string) => async (dispatch, getState) => {
    const helpsState = getState().configurateurHelps as ConfigurateurHelpsState;
    const selectedHelp = helpsState?.helpsData?.data?.find(
      (help) => help?.id_product_attr === helpIdAttribute
    );

    dispatch({
      type: ActionType.selectHelp,
      selected: selectedHelp,
    });
  };
