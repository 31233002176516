import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepProps } from "../interface";
import {
  OMainButton,
  OSocialButton,
  OSecondaryButton,
  OTertiaryButton,
  OTinyButton,
} from "../Styled/Buttons";
import { Container, Col, Row } from "react-bootstrap";
import {
  OBody1,
  OBody2,
  OBody3,
  OBody4,
  OBody5,
  OBody6,
  OBody7,
  OBody8,
  OBody9,
  OBody10,
  OBody11,
  OH1,
  OH2,
  OH3,
} from "../Styled/Texts";
import {
  ProgressBar,
  ProgressBarContainer,
  ProgressLabel,
} from "../Steps/Recommandation/phases/phase2/progress_bar";

const Home: React.FC<StepProps> = ({ onContinue }: any) => {
  const { t } = useTranslation("home");

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Linear progress
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = 1;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = () => {
    onContinue();
  };

  return (
    <Container>
    {/* <HomeContainer> */}
      <Row>

      <Col lg={8} md={12} sm={12}>
          <p>Typographie:</p>

          <OH1>OH1 : {t("get_started")}</OH1>
          <OH2>OH2 : {t("get_started")}</OH2>
          <OH3>OH3 : {t("get_started")}</OH3>

          <OBody1>OBody1 : {t("get_started")}</OBody1>
          <OBody2>OBody2 : {t("get_started")}</OBody2>
          <OBody3>OBody3 : {t("get_started")}</OBody3>
          <OBody4>OBody4 : {t("get_started")}</OBody4>
          <OBody5>OBody5 : {t("get_started")}</OBody5>
          <OBody6>OBody6 : {t("get_started")}</OBody6>
          <OBody7>OBody7 : {t("get_started")}</OBody7>
          <OBody8>OBody8 : {t("get_started")}</OBody8>
          <OBody9>OBody9 : {t("get_started")}</OBody9>
          <OBody10>OBody10 : {t("get_started")}</OBody10>
          <OBody11>OBody11 : {t("get_started")}</OBody11>
      </Col>

      <Col lg={4} md={12} sm={12}>

          <p>Main Buttons:</p>

          <OMainButton onClick={handleClick}>{t("get_started")}</OMainButton>

          <OMainButton loading onClick={handleClick}> {t("get_started")} </OMainButton>

          <OMainButton disabled onClick={handleClick}> {t("get_started")} </OMainButton>

          <OMainButton success onClick={handleClick}> {t("get_started")} </OMainButton>

          <OMainButton primary onClick={handleClick}> {t("get_started")} </OMainButton>


          <Row>
            <Col lg={8} md={8} sm={8}>

              <p className="mt-4">Secondary Buttons:</p>

              <OSecondaryButton onClick={handleClick}> {t("get_started")} </OSecondaryButton>

              <p className="mt-4">Tertiary Buttons:</p>

              <OTertiaryButton onClick={handleClick}> {t("get_started")} </OTertiaryButton>

              <OTertiaryButton writed onClick={handleClick}> {t("get_started")} </OTertiaryButton>

              <OTertiaryButton underline onClick={handleClick}> {t("get_started")} </OTertiaryButton>

            </Col>

            <Col lg={4} md={4} sm={4} className="mt-4">

            <p>Icon buttons:</p>

              <OTinyButton onClick={handleClick} />
              <OTinyButton mode="close" onClick={handleClick} />
              <OTinyButton mode="left" onClick={handleClick} />
              <OTinyButton mode="right" onClick={handleClick} />
              <OTinyButton background="none" onClick={handleClick} />
              <OTinyButton background="clay500" onClick={handleClick} />
              <OTinyButton background="clay600" onClick={handleClick} />

            </Col>

          </Row>

      </Col>

    </Row>

    <Row>

        <Col lg={4} md={6} sm={12}>

          <ProgressBarContainer>
            <ProgressBar progress={progress.toFixed(0).toString()} />
            <ProgressLabel progress={progress.toFixed(0).toString()}>
              {progress.toFixed(0)} %
            </ProgressLabel>
          </ProgressBarContainer>

          <OSocialButton us onClick={handleClick}> Connect </OSocialButton>

          <OSocialButton google onClick={handleClick}> Connect </OSocialButton>

          <OSocialButton apple onClick={handleClick}> Connect </OSocialButton>

        </Col>

      </Row>
    {/* </HomeContainer> */}
    </Container>
  );
};

export default Home;
