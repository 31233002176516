import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Sections } from "../constants";
import { useSelector } from "react-redux";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { RootState } from "../../../../../../store";
import { OH3 } from "../../../../../Styled/Texts";
import { SectionSupervisingData } from "./constants";
import { SectionSupervising } from "./styled";
import { AskIcon } from "../../../../../Styled/Buttons/AskButton/styled";
import { SupervisionEntity } from "../../../../../../data/prestashop/services/entities/product-entity";
import { SupervisionTooltip } from "../supervision/supervision-tooltip";
import { SupervisionItem } from "../supervision/supervision-item";

const SupervisingComponent: React.FC<{ initial: SectionSupervisingData }> = ({
  initial,
}) => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.SUPERVISING;
  const { t } = useTranslation([currentStepName, "global"]);

  const supervision = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected
  ) as SupervisionEntity;

  return (
    supervision && (
      <SectionSupervising
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={sectionVariants}
        id={currentSectionName + "_anchor"}
      >
        <motion.div className="section__title">
          <OH3 center="true">
            <Trans>
              {t("phase_2.sections." + currentSectionName + ".title")}
            </Trans>
          </OH3>
          <AskIcon className="supervision-ask">
            ? <SupervisionTooltip />
          </AskIcon>
        </motion.div>
        <SupervisionItem supervision={supervision} />
      </SectionSupervising>
    )
  );
};

export default SupervisingComponent;
