import React, { useEffect, useRef, useState } from "react";
import { HeaderContainer, Nav, NavLinkCustom } from "./styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { OBody5 } from "../../Styled/Texts";

interface HeaderProps {
  currentStep: string;
  handleContinue: (nextStep: string) => void;
}

const Header: React.FC<HeaderProps> = ({ currentStep, handleContinue }) => {
  const [open] = useState(false);
  const { t } = useTranslation("header");
  const steps = useSelector((state: RootState) => state.step.steps);
  const activeBorder = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateActiveBorderPosition();

    const handleResize = () => {
      updateActiveBorderPosition();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentStep]);

  const updateActiveBorderPosition = () => {
    if (activeBorder.current) {
      const activeNavLink = document.querySelector<HTMLElement>(".is-active");
      if (activeNavLink) {
        const navLinkPosition = activeNavLink.getBoundingClientRect();
        const headerContainerPosition =
          activeBorder.current.parentElement!.getBoundingClientRect();
        const left = navLinkPosition.left - headerContainerPosition.left;
        activeBorder.current.style.left = `${left}px`;
        activeBorder.current.style.width = `${navLinkPosition.width}px`;
      } else {
        activeBorder.current.style.width = "0";
      }
    }
  };

  const handleStepClick = (stepName: string) => {
    if (steps[stepName].accessible) {
      handleContinue(stepName);
    }
  };

  const stepsArray = Object.keys(steps);
  const currentStepIndex = stepsArray.indexOf(currentStep);
  return (
    <HeaderContainer>

      <div ref={activeBorder} className={`active-border`} />

      <Nav open={open}>
        {stepsArray.map((stepName, index) => (
          <NavLinkCustom
            key={stepName}
            className={`nav-item nav-link-custom${
              currentStep === stepName ? " is-active" : ""
            }${index === currentStepIndex - 1 ? " prev" : ""}${
              index === currentStepIndex + 1 ? " next" : ""
            }`}
            disabled={!steps[stepName].accessible}
            onClick={(event) => handleStepClick(stepName)}
          >
            <OBody5>
              {index + 1}. {t(stepName)}
            </OBody5>
          </NavLinkCustom>
        ))}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
