import styled from "styled-components";

export const OModal = styled.div<{
  show: boolean;
  maxContentWidth?: string;
  maxContentHeight?: string;
  padding?: string;
}>`
  .modal-content {
    display: block;

    z-index: 10000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: ${(props) => props.padding || "32px 40px 32px 40px"};
    background-color: ${(props) => props.theme.colors.clay.clayPower200};
    border-radius: 32px;
    align-items: center;
    min-width: 25vw;
    max-width: ${(props) => props.maxContentWidth || "40vw"};
    max-height: ${(props) => props.maxContentHeight};
    @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
      min-width: 90vw;
      max-height: 90vh;
      overflow-y: scroll;
    }
  }

  .modal-background {
    display: block;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-header-close-btn {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .modal-content-body {
    padding: 32px 0px 32px 0px;
  }

  .modal-content-list > p {
    color: ${(props) => props.theme.colors.primary.bluePower700};
  }

  .modal-close-btn {
    width: 50%;
    margin: 0 auto;
  }
  .modal-close-btn:hover {
    border: solid 1px ${(props) => props.theme.colors.clay.clayPower700};
  }
`;
