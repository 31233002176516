import { IPanelsGrid, panelsGridConfig } from "../../../../shared/panels_grid/constants";

export interface DesignPhase1ReduxStepData {
  panelsGrid?: IPanelsGrid;
  dataUrl?: any;
  recommandationCount?: number;
}

export const designPhase1Config = {
  defaultValues: {
    panelsGrid: panelsGridConfig.defaultValues,
    dataUrl: undefined,
    recommandationCount: 0
    },
  gridConfig: {
    left: [12, 8, 8],
    right: [12, 4, 4],
  },
};

export interface DesignPhase1Props {

  onContinue: () => void;
}
