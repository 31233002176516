import React, { useRef } from "react";
import { localizationConfig, LocalizationReduxStepData } from "../constants";
import { Trans, useTranslation } from "react-i18next";
import { ButtonContainer, Map, MapboxLocationContainer } from "../styled";
import { OCol, ORow } from "../../../Styled/CustomGrid";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  STEP_LOCALIZATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  setCurrentStep,
  setGlobalCurrentPhase,
  setSkip,
  setStepAccessible,
  setStepData,
  setStepPhase1,
} from "../../../../store/reducer";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import { OBody5, OH1 } from "../../../Styled/Texts";
import { OMainButton } from "../../../Styled/Buttons";
import { fade } from "../../../Styled/GlobalAnimations";
import useIsMobile from "../../../../utils/useIsMobile";
import { StepProps } from "../../../interface";
import { Autocomplete } from "./autocomplete/Autocomplete";
import { resetMapBoxReducer } from "../../../../store/calepinage/mapBoxReducer";
import { trackDataLayer } from "../../../../utils/tracking/tracking-utils";
import { useInitMap } from "./hooks/use-init-map";
import { useJumpToLocation } from "./hooks/use-jump-to-location";
import { OLinkButton } from "../../../Styled/Buttons/Link/o-link-button";
import { theme } from "../../../Styled/GlobalStyle";
import { skipRecommandation } from "../../../../utils/navigation/navigation-utils";

const LocalizationPhase1: React.FC<StepProps> = ({ onContinue }) => {
  const { t } = useTranslation([STEP_LOCALIZATION, "global"]);
  const dispatch = useDispatch();

  const config = localizationConfig;

  const mapContainer = useRef(null);
  const map = useRef(null);

  const zoom = useSelector((state: RootState) => state.localization.zoom);

  const location = useSelector(
    (state: RootState) => state.localization.location
  );

  const installationType = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data?.installation
  );

  useInitMap(map, mapContainer, zoom);
  useJumpToLocation(map, location);

  // On continue handler (save data in redux)
  const handleOnContinue = () => {
    dispatch(setSkip(false));
    dispatch(
      setStepData({
        step: STEP_LOCALIZATION,
        data: {
          position: location.position,
          search: store.getState().localization.text,
          zoom: zoom,
        } as LocalizationReduxStepData,
      })
    );
    dispatch(setStepAccessible({ step: STEP_NEEDS, accessible: false }));
    dispatch(
      setStepAccessible({ step: STEP_RECOMMANDATION, accessible: false })
    );
    dispatch(setStepAccessible({ step: STEP_DESIGN, accessible: false }));
    dispatch(setStepPhase1({ step: STEP_RECOMMANDATION }));
    dispatch(setStepPhase1({ step: STEP_NEEDS }));
    dispatch(setStepPhase1({ step: STEP_DESIGN }));
    dispatch(resetMapBoxReducer());
    trackDataLayer("LocalisationSelected", {
      localisation: location,
    });
    onContinue();
  };

  return (
    <ORow className="h-100 flex-column flex-md-row">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className={`d-flex flex-column justify-content-start justify-content-md-center align-items-center order-1 gap-3 p-0 p-md-3`}
      >
        <OH1 center="true" {...fade()} style={{ maxWidth: "90%" }}>
          <Trans>{t("title")}</Trans>
        </OH1>
        <OBody5 center="true" {...fade(0.2)} style={{ maxWidth: "90%" }}>
          <Trans> {t("description")}</Trans>
        </OBody5>
        <ButtonContainer>
          <Autocomplete />
          <OMainButton
            className="w-100 localisation-next-btn"
            primary
            onClick={location === null ? undefined : handleOnContinue}
            disabled={location === null}
            {...fade(0.3)}
          >
            {t("global:validate")}
          </OMainButton>
          <OLinkButton
            className="localisation-skip-btn"
            color={theme.colors.onyx.onyxPower700}
            onClick={() => skipRecommandation(dispatch, installationType)}
          >
            {t("goToConfiguration")}
          </OLinkButton>
        </ButtonContainer>
      </OCol>

      <OCol
        sm={config.gridConfig.right[0]}
        md={config.gridConfig.right[1]}
        lg={config.gridConfig.right[2]}
        className={`d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center align-items-center contentsized order-2 p-0 p-md-3`}
      >
        <MapboxLocationContainer>
          <Map ref={mapContainer}></Map>
        </MapboxLocationContainer>
      </OCol>
    </ORow>
  );
};

export default LocalizationPhase1;
