import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import {
  STEP_DESIGN,
  STEP_LOCALIZATION,
  setStepData,
} from "../../../../../store/reducer";
import {
  DesignPhase1ReduxStepData,
  DesignPhase1Props,
  designPhase1Config,
} from "./constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useDispatch } from "react-redux";
import { Position } from "../../../Localization/constants";
import { Orientation2D } from "../../../../shared/panels_grid/constants";
import { OMainButton, OReturnButton } from "../../../../Styled/Buttons";
import {
  OBody10,
  OBody11,
  OBody12,
  OBody8,
  OBody9,
  OH2,
  OH3,
} from "../../../../Styled/Texts";
import { fade } from "../../../../Styled/GlobalAnimations";
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { OrientationGrid } from "./styled";
import { OActionButton } from "../../../../Styled/Buttons/OActionButton";
import CalepinageToiture from "../../../../shared/calepinage";
import {
  setDispositionPanneaux,
  setNeedComputeMatriceCalepinage,
  setPanelCountInCalepinage,
} from "../../../../../store/calepinage/calepinageReducer";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
import { svgsPath } from "../../../../../utils/constants";
import { URL_TUTO_CALEPINAGE } from "../../../../shared/calepinage/constant";
import {
  buildMatrix,
  getUsableMatriceFromMapbox,
} from "../../../../shared/calepinage/mapbox/utils";
import { Tooltip } from "react-tooltip";
import { theme } from "../../../../Styled/GlobalStyle";

const DesignPhase1: React.FC<DesignPhase1Props> = ({ onContinue }) => {
  const currentStepName = STEP_DESIGN;
  const { t } = useTranslation([currentStepName, "global"]);
  const config = designPhase1Config;
  const dispatch = useDispatch();

  const recommandationCount = useSelector(
    (state: RootState) => state.calepinageReducer.recommandationCount
  );
  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );

  const orientation = useSelector(
    (state: RootState) => state.calepinageReducer.dispositionPanneaux
  );

  const [panelsCount, setPanelCount] = useState<number>(recommandationCount);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);

  const dispatchPanelCountInCalepinage = (count) =>
    dispatch(setPanelCountInCalepinage(count));

  const globalGrid = useSelector(
    (state: RootState) => state.mapBoxReducer.globalGrid
  );

  const canNext = () => {
    if (globalGrid?.features?.length > 0) {
      if (
        globalGrid.features.filter(
          (feature) => feature.properties.selected === true
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const stepData = useSelector(
    (state: RootState) =>
      state.step.steps[currentStepName]?.data?.phase1 ?? config.defaultValues
  ) as DesignPhase1ReduxStepData;

  const position = useSelector(
    (state: RootState) => state.step.steps[STEP_LOCALIZATION]?.data?.position
  ) as Position;

  const setOrientation = (orientation: Orientation2D) => {
    dispatch(setDispositionPanneaux(orientation));
  };

  const getLabelOrientation = (orientation: Orientation2D) => {
    if (orientation === Orientation2D.PORTRAIT) {
      return t("portrait").toLowerCase();
    } else {
      return t("landscape").toLowerCase();
    }
  };

  useEffect(() => {
    if (panelCountInCalepinage == null) {
      setPanelCount(recommandationCount);
      dispatchPanelCountInCalepinage(recommandationCount);
    }
  }, [
    dispatchPanelCountInCalepinage,
    panelCountInCalepinage,
    recommandationCount,
  ]);

  useEffect(() => {
    setPanelCount(panelCountInCalepinage);
  }, [panelCountInCalepinage]);

  useEffect(() => {
    if (canContinue) {
      setCanContinue(false);
      onContinue();
    }
  }, [canContinue]);

  const handlePortrait = () => {
    setOrientation(Orientation2D.PORTRAIT);
  };

  const handleLandscape = () => {
    setOrientation(Orientation2D.LANDSCAPE);
  };

  const handleContinue = () => {
    if (canNext()) {
      dispatch(setNeedComputeMatriceCalepinage(true));
      setCanContinue(true);

      trackDataLayer("CalepinageSelected", {
        calepinage: {
          mode: getLabelOrientation(orientation),
          panelsNumber: panelCountInCalepinage,
          filteredMatrix: getUsableMatriceFromMapbox(globalGrid),
          unfilteredMatrix: buildMatrix(globalGrid),
        },
      });
    }
    // onContinue();
  };

  const handleDeleteAll = () => {
    setDeleteAll(true);
  };

  useEffect(() => {
    dispatch(
      setStepData({
        step: currentStepName,
        data: {
          phase1: {
            panelsGrid: {
              orientation: orientation,
            },
          },
        },
      })
    );
  }, [orientation]);

  return (
    <ORow className="h-100">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className="d-flex flex-column justify-content-between align-items-center contentsized position-relative no-padding"
      >
        {/* <KitPreview /> */}
        <CalepinageToiture
          position={position}
          mapMode={true}
          orientation={orientation}
          deleteAll={deleteAll}
          setDeleteAll={setDeleteAll}
        />
      </OCol>
      <OCol
        sm={config.gridConfig.right[0]}
        md={config.gridConfig.right[1]}
        lg={config.gridConfig.right[2]}
        className="d-flex flex-column justify-content-start align-items-center bg-light gap-3 p-0 p-md-3 h-100 overflow-auto"
      >
        <ORow>
          <OH2 center="true" {...fade(0.5)}>
            <Trans> {t("phase_1.title")}</Trans>
          </OH2>
          <OBody9 center="true" {...fade(0.5)}>
            <Trans> {t("phase_1.title_desc")}</Trans>
          </OBody9>
        </ORow>
        <motion.div className="d-flex flex-column w-100 gap-1">
          <motion.div className="d-flex flex-row justify-content-between w-100 gap-2">
            <OBody12>
              <Trans>{t("phase_1.label_left")}</Trans>
            </OBody12>
            <OBody12>
              <strong>{panelsCount + " "}</strong>
              <Trans>
                {t("phase_1.panels", { count: panelsCount })} (
                {getLabelOrientation(orientation)})
              </Trans>
            </OBody12>
          </motion.div>
          <OrientationGrid>
            <OActionButton
              onClick={handlePortrait}
              isactive={(orientation === Orientation2D.PORTRAIT).toString()}
            >
              {t("Portrait")}
            </OActionButton>
            <OActionButton
              onClick={handleLandscape}
              isactive={(orientation === Orientation2D.LANDSCAPE).toString()}
            >
              {t("landscape")}
            </OActionButton>
          </OrientationGrid>
        </motion.div>{" "}
        <motion.div className="d-flex flex-column justify-content-between w-100 gap-2">
          <OBody11 center="true" {...fade(0.5)}>
            {t("phase_1.explication1")}
            <img src={svgsPath + "pivoter-map.svg"} className="px-1" />
            {t("phase_1.explication2")}
          </OBody11>
          <ReactPlayer
            url={URL_TUTO_CALEPINAGE}
            loop={true}
            muted={true}
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </motion.div>
        <ORow>
          <div className="o-tooltip-container">
            <OMainButton
              className="w-100 conception-calepinage-next-btn"
              primary
              onClick={handleContinue}
              disabled={!canNext()}
              data-tooltip-id="calepinage-validation-btn-tooltip"
            >
              {t("phase_1.continue")}
            </OMainButton>
            <Tooltip
              id="calepinage-validation-btn-tooltip"
              className="o-tooltip"
              noArrow={true}
              hidden={canNext()}
            >
              <OBody8 color={theme.colors.clay.clayPower200}>
                {t("phase_1.next_btn_disabled_tooltip")}
              </OBody8>
            </Tooltip>
          </div>

          <OReturnButton
            className="w-100"
            onClick={handleDeleteAll}
            {...fade(0.3)}
          >
            {t("phase_1.delete_all")}
          </OReturnButton>
        </ORow>
      </OCol>
    </ORow>
  );
};

export default DesignPhase1;
