import axios from "axios";
import { SuitableProductsDto } from "./dtos/suitable-products-dto";
import { CONFIGURATEUR_URL } from "../../../utils/constants";

const routePannels = "/panneaux";
const routeOnduleurs = "/onduleurs";
const routeSupervision = "/supervision";
const routeAccessories = "/accessoires";
const routeBatteries = "/batteries";
const routeSoutienAdmin = "/soutien_admin";
const routePanneauFixation = "/panneaufixation";

export class ConfigurateurApi {
  async getPannels(fixationId?: number): Promise<SuitableProductsDto> {
    const params = {
      f_id_product: fixationId,
    };
    const result = await axios.get(CONFIGURATEUR_URL + routePannels, {
      params,
    });
    return result.data;
  }

  async getOnduleurs(
    emplacementId: number,
    fixationId: string,
    panelId: string,
    panelsNumber: number,
    phaseNumber: number
  ): Promise<SuitableProductsDto> {
    const params = {
      carac17: emplacementId,
      f_id_product: fixationId,
      p_id_product: panelId,
      np: panelsNumber,
      nphase: phaseNumber,
    };
    const result = await axios.get(CONFIGURATEUR_URL + routeOnduleurs, {
      params,
    });
    return result.data;
  }

  async getSupervision(
    onduleurId: string,
    onduleurIdAttribute: string
  ): Promise<SuitableProductsDto> {
    const params = {
      o_id_product: onduleurId,
      o_id_product_attribute: onduleurIdAttribute,
    };
    const result = await axios.get(CONFIGURATEUR_URL + routeSupervision, {
      params,
    });
    return result.data;
  }

  async getAccessories(
    panelId: string,
    panelAttribute: string,
    panelsNumber: number,
    onduleurId: string,
    onduleurAttribute: string,
    onduleursNumber: number,
    fixationId: string,
    fixationAttribute: string,
    fixationNumber: number,
    withParafoudre: boolean,
    entryA: number,
    entryB: number,
    supervisionId: string,
    supervisionAttribute: string,
    phaseNumber: number,
    tracker: number,
    postcode?: string
  ): Promise<SuitableProductsDto> {
    const params = {
      p_id_product: panelId,
      p_id_product_attribute: panelAttribute,
      np: panelsNumber,
      o_id_product: onduleurId,
      o_id_product_attribute: onduleurAttribute,
      nbxO: onduleursNumber,
      f_id_product: fixationId,
      f_id_product_attribute: fixationAttribute,
      nbxF: fixationNumber,
      parafoudre: withParafoudre ? true : null,
      A: entryA,
      B: entryB,
      s_id_product: supervisionId,
      s_id_product_attribute: supervisionAttribute,
      nphase: phaseNumber,
      tracker: tracker,
      cp: postcode,
    };
    return (await axios.get(CONFIGURATEUR_URL + routeAccessories, { params }))
      .data;
  }

  async getBatteries(
    onduleurId: string,
    onduleurIdAttribute: string,
    phaseNumber: number
  ): Promise<SuitableProductsDto> {
    const params = {
      o_id_product: onduleurId,
      o_id_product_attribute: onduleurIdAttribute,
      nphase: phaseNumber,
    };
    const result = await axios.get(CONFIGURATEUR_URL + routeBatteries, {
      params,
    });
    return result.data;
  }

  async getSoutienAdmin(
    panelId: string,
    panelIdAttribute: string,
    panelsNumber: number,
    batteryId?: string,
    batteryIdAttribute?: string
  ): Promise<SuitableProductsDto> {
    const params = {
      p_id_product: panelId,
      p_id_product_attribute: panelIdAttribute,
      np: panelsNumber,
      b_id_product: batteryId,
      b_id_product_attribute: batteryIdAttribute,
    };
    const result = await axios.get(CONFIGURATEUR_URL + routeSoutienAdmin, {
      params,
    });
    return result.data;
  }

  async getGaranties(): Promise<SuitableProductsDto> {
    // No API available to get garanties
    return {
      liste: [
        {
          id_product: 1470,
          id_product_attribute: 0,
          quantity: 1,
          tracker: 0,
        },
      ],
    };
  }

  async getPanneauFixations(
    matrice: number[][],
    disposition: number,
    emplacementId: number,
    panelId: string
  ): Promise<SuitableProductsDto> {
    const params = {
      // iso v1
      matrice:
        "[" + matrice.map((item) => "[" + item.join(",") + "]").join(",") + "]",
      // On our side, portrait=0 and landscape=1
      // On API side, portrait=1 and landscape=0
      disposition: disposition === 0 ? 1 : 0,
      p_id_product: panelId,
      carac17: emplacementId,
    };

    return (
      await axios.get(CONFIGURATEUR_URL + routePanneauFixation, {
        params,
      })
    ).data;
  }
}
