import { useSelector } from "react-redux";
import { RootState } from "../..";

export const useVirtualCartKitPower = (): number => {
  const panelsData = useSelector(
    (state: RootState) => state?.configurateurPanels?.panelsData?.selected
  );
  const nominalPower = parseFloat(panelsData?.powerWcFeature?.value);
  const panelNumber = panelsData?.kitQuantity;
  // divide by 1000 to have power into kw
  return (panelNumber * nominalPower) / 1000;
};
