import store, { RootState } from "../../store";
import {
  InstallationType,
  isRoofType,
} from "../../components/Steps/Installation/constants";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import {
  configurateurFixationsHorsToitureLoad,
  configurateurFixationsToitureLoad,
} from "../../store/configurateur/fixations/configurateur-fixations-reducer";

export const useEffectPanneauFixations = (
  installationType: InstallationType,
  matrice: number[][],
  emplacementId: number,
  disposition: number,
  panelId: string
) => {
  // Case toiture
  useEffect(() => {
    if (emplacementId && panelId && isRoofType(installationType)) {
      store.dispatch(
        configurateurFixationsToitureLoad(
          matrice,
          disposition,
          emplacementId,
          panelId
        )
      );
    }
  }, [disposition, emplacementId, installationType, matrice, panelId]);

  // Case hors toiture
  const fixationIdAttribute = useSelector(
    (state: RootState) => state.calepinageReducer?.fixationSelected?.id_product
  );
  useEffect(() => {
    if (!isRoofType(installationType) && fixationIdAttribute) {
      store.dispatch(
        configurateurFixationsHorsToitureLoad(fixationIdAttribute)
      );
    }
  }, [fixationIdAttribute]);
};

export const useFixationIds = (): { id?: string; attribute?: string } => {
  const fixationsState = useSelector(
    (state: RootState) => state.configurateurFixations?.fixationsData
  );
  if (fixationsState?.panneauFixation?.data?.length > 0) {
    return fixationsState?.panneauFixation?.data?.map((fixation) => {
      return {
        id: fixation?.id_product,
        attribute: fixation?.id_product_attr,
      };
    })[0];
  }
  return {};
};
