import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import thunkMiddleware from "redux-thunk";

const composedEnhancer = applyMiddleware(thunkMiddleware);

const initialState = window.initialReduxState || {};

const store = configureStore({
  reducer: rootReducer,
  enhancers: [composedEnhancer],
  preloadedState: initialState.reduxState,
});

store.subscribe(() => {
  const state = store.getState();
  // window.history.replaceState({ reduxState: state }, "");
});


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
