import { PanneauxFixationEntity } from "../../fixations/repositories/entities/panneau-fixation-entity";
import { ProductDto } from "../../prestashop/api/dtos/product-dto";
import {
  getDealtOffers,
  getDefaultSettings,
  getOscaroPowerProductsById,
} from "../../prestashop/api/prestashop-api";
import {
  AccessoriesEntity,
  BatteryEntity,
  GarantieEntity,
  HelpEntity,
  InstallHelpEntity,
  OnduleurEntity,
  PanelEntity,
  PanneauFixationEntity,
  SupervisionEntity,
} from "../../prestashop/services/entities/product-entity";
import { getProductEntityFromDto } from "../../prestashop/services/products-service";
import { ConfigurateurApi } from "../api/configurateur-api";
import { SuitableProductDto } from "../api/dtos/suitable-products-dto";

class ConfigurateurService {
  configApi = new ConfigurateurApi();

  async getPannels(): Promise<PanelEntity[]> {
    try {
      const panelEntities = (await getDefaultSettings()).solar_panels.items.map(
        (panel) => {
          return {
            ...getProductEntityFromDto(panel),
            powerWcFeature: panel.features.find(
              (feature) => feature.id_feature === "11"
            ),
            warrantyFeature: panel.features.find(
              (feature) => feature.id_feature === "25"
            ),
            dimensionFeature: panel.features.find(
              (feature) => feature.id_feature === "40"
            ),
          };
        }
      );

      panelEntities.map((panel: PanelEntity) => {
        const dimensions = panel.dimensionFeature.value.split("*");

        panel.dimensionFeature.longueur = Number(dimensions[0]) / 1000;
        panel.dimensionFeature.largeur = Number(dimensions[1]) / 1000;
        panel.dimensionFeature.hauteur = Number(dimensions[2]) / 1000;

        return panel;
      });

      return panelEntities;
    } catch (error) {
      return [];
    }
  }

  async getOnduleurs(
    emplacementId: number,
    fixationId: string,
    panelId: string,
    panelsNumber: number,
    phaseNumber: number
  ): Promise<OnduleurEntity[]> {
    try {
      const suitableOnduleurs = (
        await this.configApi.getOnduleurs(
          emplacementId,
          fixationId,
          panelId,
          panelsNumber,
          phaseNumber
        )
      ).liste;
      const products = (
        await getOscaroPowerProductsById(
          suitableOnduleurs.map(this.mapSuitableId)
        )
      ).products;
      const suitableIds = suitableOnduleurs.map(
        (suitable) => `${suitable.id_product}_${suitable.id_product_attribute}`
      );
      return products
        .filter((product) => suitableIds.includes(product.id_product_attr))
        .map((product) => {
          const suitable = suitableOnduleurs.find(
            (suitableOnduleur) =>
              suitableOnduleur.id_product.toString() === product.id_product
          );
          const kitQuantity = suitable?.quantity;
          return {
            ...getProductEntityFromDto(product),
            kitQuantity: kitQuantity,
            kitPrice: product.price * kitQuantity,
            tracker: suitable?.tracker,
          };
        });
    } catch (error) {
      return [];
    }
  }

  async getSupervision(
    onduleurIdAttribute: string
  ): Promise<SupervisionEntity[]> {
    try {
      const onduleurIds = onduleurIdAttribute.split("_");
      const suitableSupervisions = (
        await this.configApi.getSupervision(onduleurIds[0], onduleurIds[1])
      ).liste;
      const products = (
        await getOscaroPowerProductsById(
          suitableSupervisions.map(this.mapSuitableId)
        )
      ).products;
      return products.map((product) => {
        const kitQuantity = this.getSuitableFromProduct(
          suitableSupervisions,
          product
        ).quantity;
        return {
          ...getProductEntityFromDto(product),
          kitQuantity: kitQuantity,
        };
      });
    } catch (error) {
      return [];
    }
  }

  async getAccessories(
    panelAttribute: string,
    panelsNumber: number,
    onduleurAttribute: string,
    onduleursNumber: number,
    fixationAttribute: string,
    fixationNumber: number,
    withParafoudre: boolean,
    entryA: number,
    entryB: number,
    supervisionAttribute: string,
    phaseNumber: number,
    tracker: number,
    postcode?: string
  ): Promise<AccessoriesEntity> {
    try {
      const panelIds = panelAttribute.split("_");
      const onduleurIds = onduleurAttribute.split("_");
      const fixationIds = fixationAttribute.split("_");
      const supervisionIds = supervisionAttribute.split("_");

      const suitableAccessories = await this.configApi.getAccessories(
        panelIds[0],
        panelIds[1],
        panelsNumber,
        onduleurIds[0],
        onduleurIds[1],
        onduleursNumber,
        fixationIds[0],
        fixationIds[1],
        fixationNumber,
        withParafoudre,
        entryA,
        entryB,
        supervisionIds[0],
        supervisionIds[1],
        phaseNumber,
        tracker,
        postcode
      );

      const productsWithAttr = (
        await getOscaroPowerProductsById(
          suitableAccessories.liste
            .filter(
              (suitable) =>
                suitable.quantity !== 0 && suitable.id_product_attribute !== 0
            )
            .map(this.mapSuitableId)
        )
      ).products;
      const productsWithoutAttr = (
        await getOscaroPowerProductsById(
          suitableAccessories.liste
            .filter(
              (suitable) =>
                suitable.quantity !== 0 && suitable.id_product_attribute === 0
            )
            .map(this.mapSuitableId)
        )
      ).products;

      const products = productsWithAttr.concat(productsWithoutAttr);

      return {
        parafoudreRequired: suitableAccessories.parafoudre_necessaire,
        accessories: products.map((product) => {
          const kitQuantity = this.getSuitableFromProduct(
            suitableAccessories.liste,
            product
          ).quantity;
          return {
            ...getProductEntityFromDto(product),
            kitQuantity: kitQuantity,
            kitPrice: product.price * kitQuantity,
            parafoudreRequired: suitableAccessories.parafoudre_necessaire,
          };
        }),
      };
    } catch (error) {
      return;
    }
  }

  async getBatteries(
    onduleurIdAttribute: string,
    phaseNumber: number
  ): Promise<BatteryEntity[]> {
    try {
      const onduleurIds = onduleurIdAttribute.split("_");
      const suitableBatteries = (
        await this.configApi.getBatteries(
          onduleurIds[0],
          onduleurIds[1],
          phaseNumber
        )
      ).liste;
      const products = (
        await getOscaroPowerProductsById(
          suitableBatteries.map(this.mapSuitableId)
        )
      ).products;
      return products.map((product) => {
        const kitQuantity = suitableBatteries.find(
          (accesory) => accesory.id_product.toString() === product.id_product
        ).quantity;
        return {
          ...getProductEntityFromDto(product),
          kitQuantity: kitQuantity,
          kitPrice: product.price * kitQuantity,
        };
      });
    } catch (error) {
      return [];
    }
  }

  async getHelps(
    panelIdAttribute: string,
    panelsNumber: number,
    batteryIdAttribute?: string
  ): Promise<HelpEntity[]> {
    try {
      const panelIds = panelIdAttribute.split("_");
      const batteryIds = batteryIdAttribute
        ? batteryIdAttribute?.split("_")
        : [];

      const suitableHelps = (
        await this.configApi.getSoutienAdmin(
          panelIds[0],
          panelIds[1],
          panelsNumber,
          batteryIds[0],
          batteryIds[1]
        )
      ).liste;
      const products = (
        await getOscaroPowerProductsById(suitableHelps.map(this.mapSuitableId))
      ).products;
      return products.map((product) => {
        const suitable = this.getSuitableFromProduct(suitableHelps, product);
        const kitQuantity = suitable?.quantity;
        const overridenLabel = suitable?.titre;
        const isDefault = suitable?.default;
        const hasSurplusHelp = suitable?.surplus;
        return {
          ...getProductEntityFromDto(product),
          kitQuantity: kitQuantity,
          kitPrice: product.price * kitQuantity,
          configurator_name: overridenLabel,
          default: isDefault,
          hasSurplus: hasSurplusHelp,
        };
      });
    } catch (error) {
      return [];
    }
  }

  async getGaranties(): Promise<GarantieEntity[]> {
    try {
      const suitableGaranties = (await this.configApi.getGaranties()).liste;
      const products = (
        await getOscaroPowerProductsById(
          suitableGaranties.map(this.mapSuitableId)
        )
      ).products;

      return products.map((product) => {
        const kitQuantity = suitableGaranties.find(
          (garantie) => garantie.id_product.toString() === product.id_product
        ).quantity;
        return {
          ...getProductEntityFromDto(product),
          kitQuantity: kitQuantity,
          kitPrice: product.price * kitQuantity,
        };
      });
    } catch (error) {
      return [];
    }
  }

  async getDealtOffers(): Promise<InstallHelpEntity[]> {
    try {
      const offers = await getDealtOffers();
      return offers["dealt-op-offers"]?.map((offer) => {
        const price = Number.parseFloat(offer?.price);
        return {
          ...offer,
          kitPrice: price,
        };
      });
    } catch (e) {
      return [];
    }
  }

  async getPanneauFixations(
    matrice: number[][],
    disposition: number,
    emplacementId: number,
    panelId: string
  ): Promise<PanneauxFixationEntity> {
    const suitableFixations = await this.configApi.getPanneauFixations(
      matrice,
      disposition,
      emplacementId,
      panelId
    );
    const products = (
      await getOscaroPowerProductsById(
        suitableFixations.liste.map(this.mapSuitableId)
      )
    ).products;
    return {
      fixationsNb: suitableFixations.nbxFixation,
      data: products.map((product) => {
        const kitQuantity = this.getSuitableFromProduct(
          suitableFixations.liste,
          product
        ).quantité;
        return {
          ...getProductEntityFromDto(product),
          kitQuantity: kitQuantity,
          kitPrice: product.price * kitQuantity,
        };
      }),
    };
  }

  async getPanneauFixationById(
    fixationIdAttribute: string
  ): Promise<PanneauFixationEntity> {
    const products = (await getOscaroPowerProductsById([fixationIdAttribute]))
      .products;
    const product = products[0];
    return {
      ...getProductEntityFromDto(product),
      kitQuantity: 1,
      kitPrice: product.price,
    };
  }

  private mapSuitableId(suitableProduct: SuitableProductDto): string {
    if (suitableProduct.id_product_attribute === 0) {
      return suitableProduct.id_product.toString();
    }
    return `${suitableProduct.id_product.toString()}_${suitableProduct.id_product_attribute.toString()}`;
  }

  private getSuitableFromProduct(
    suitables: SuitableProductDto[],
    product: ProductDto
  ): SuitableProductDto {
    return suitables.find((suitable) => {
      const suitableIdAttr = this.mapSuitableId(suitable);
      return suitableIdAttr === product.id_product_attr;
    });
  }
}

export const configurateurService = new ConfigurateurService();
