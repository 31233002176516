import React from "react";
import { OEquipCard, OEquipCardBody, OEquipCardHeader } from "./styled";
import "react-lazy-load-image-component/src/effects/blur.css";
import { OBody11, OBody4 } from "../../../../Styled/Texts";
import { NeedType, NeedTypeImageLinks } from "./constants";
import { formatNumber } from "../../../../../utils/convert-number-locale";

interface EquipmentOEquipCardProps {
  type?: NeedType;
  label?: string;
  power?: number;
  style?: React.CSSProperties;
  unit?: string;
  update?: (type) => void;
  isSelected?: boolean;
}
export const EquipmentOEquipCard: React.FC<EquipmentOEquipCardProps> = ({
  type,
  label,
  style,
  power,
  unit,
  update,
  isSelected,
}) => {
  const handleSingleCardClick = () => {
    update(type);
  };

  return (
    <OEquipCard
      className={`besoins-equipements-item-${type}`}
      selected={isSelected}
      style={style}
      onClick={handleSingleCardClick}
    >
      <OEquipCardHeader>
        <img
          src={NeedTypeImageLinks[type]}
          alt={type}
          key={type + "-img"}
          className="img-fluid equip-card-img"
        />
      </OEquipCardHeader>
      <OEquipCardBody>
        <OBody11 center="true">{label}</OBody11>
        <OBody4 center="true">
          {formatNumber(power ?? 0, 2, false, {
            minimumFractionDigits: 0,
          })} {unit}
        </OBody4>
      </OEquipCardBody>
    </OEquipCard>
  );
};
