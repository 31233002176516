import _ from "lodash";
import React, {useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import store, { RootState } from "../../../store";

import { svgsPath } from "../../../utils/constants";

import { swapObstacle } from "../../../store/calepinage/calepinageHorsMapReducer";

import { PanelsContainer, PanelCore, PanelVirtual, PanelItem, DeleteHover, Obstacle, ObstacleHover, PanelNumber } from "./styled";
import { OMaterialButton } from "../../Styled/Buttons";

interface PanelContainerProps {
  row: number[];
  rowWithVirtualPanelToShow?: number;
  rowWithDeletePanelToShow?: number;
  dispositionPanneaux?: number;
  index: number;
  children?: React.ReactNode;
  className?: string;
}

const PanelContainer: React.FC<PanelContainerProps> = ({ row, rowWithVirtualPanelToShow, rowWithDeletePanelToShow, index, dispositionPanneaux }) => {

    const ref = useRef(null);
    const panelRef = useRef(null);
    const deletePanelRef = useRef(null);

    const [hoverItem, setHoverItem] = useState(null);

    const maxPanelCount = useSelector(
        (state: RootState) => state?.calepinageHMReducer?.maxPanelCount
    );

    const panelsCount = useSelector(
        (state: RootState) => state?.calepinageHMReducer?.count
    );

    // scroll to last 
    useEffect(() => {
        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, [ref, row]);

    // scroll to target element if not visible when mouse over add button
    useEffect(() => {
        if(rowWithVirtualPanelToShow === null){
            return;
        }
        panelRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, [panelRef, rowWithVirtualPanelToShow]);

    // scroll to target element if not visible when mouse over remove button
    useEffect(() => {
        if(rowWithDeletePanelToShow === null){
            return;
        }
        deletePanelRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }, [deletePanelRef, rowWithDeletePanelToShow]);

    if (!row) {
        return null;
    }

    const showVirtualPanelOnRow = (item) => {
        setHoverItem(item);        
    };

    const hideVirtualPanelOnRow = () => {
        setHoverItem(null);
    };

    const swapObstacleEvent = (rowIndex, index) => {
        store.dispatch(swapObstacle( {rowIndex: rowIndex, index: index} ));
    }

    const swapObstacleToPanel = (rowIndex, index) => {
        if(maxPanelCount===panelsCount){
            return;
        }
        swapObstacleEvent(rowIndex, index);
    }

    const swapPanelToObstacle = (rowIndex, index) => {
        swapObstacleEvent(rowIndex, index);
    }

    const panel = dispositionPanneaux ? "matrice-panneau-portrait-2x.svg" : "matrice-panneau-paysage.svg";
    const panelSrc = svgsPath +  panel;

    return (
        <>
            <PanelsContainer ref={ref} className={`${ dispositionPanneaux ? "flex-row" : "flex-column"}`}>
            
                {row.map((item, rowIndex) => (
                    <PanelItem className={`${ dispositionPanneaux ? "panel-portrait" : "panel-paysage"}`}>
                        <PanelCore onMouseEnter={()=>showVirtualPanelOnRow(rowIndex) } onMouseLeave={hideVirtualPanelOnRow} panelSrc={panelSrc}>

                            
                            <PanelNumber className={`${ item === 1 && hoverItem === rowIndex ? "d-none" : ""} ${ item === 0 ? "d-none" : ""}`}>
                                { _.sum(_.take(row, (rowIndex + 1))) } / { _.sum(row) }
                            </PanelNumber>
                            

                            <DeleteHover ref={deletePanelRef} className={`${ ((index === rowWithDeletePanelToShow) && (rowIndex === (row.length - 1)))  ? "" : "d-none"}`}>
                                <p>Supprimer panneau</p>
                            </DeleteHover>
                            <Obstacle className={`${ item === 0 ? "" : "d-none"}`} onClick={()=>swapObstacleToPanel(index, hoverItem) }>
                                <p>
                                    <OMaterialButton className="material-symbols-rounded" icon="-" size="22" color="grey-light"></OMaterialButton>
                                    <br/>
                                    Rétablir <br />
                                    panneau
                                </p>
                            </Obstacle>
                            <ObstacleHover className={`${ item === 1 && hoverItem === rowIndex ? "invisble" : "d-none"}`} onClick={()=>swapPanelToObstacle(index, hoverItem) }>
                                <p>
                                <OMaterialButton className="material-symbols-rounded" icon="+" size="22" color="red"></OMaterialButton>
                                <br/>
                                Ajouter <br className={`${ dispositionPanneaux ? "d-none" : ""}`} />
                                obstacle
                                </p>
                            </ObstacleHover>

                        </PanelCore>
                    </PanelItem>
                ))}

                <PanelItem className={`${ dispositionPanneaux ? "panel-portrait" : "panel-paysage"}`}>
                    <PanelVirtual ref={panelRef} className={`${ index === rowWithVirtualPanelToShow ? "block" : "d-none"}`}>
                        <p>
                            Ajouter <br className={`${ dispositionPanneaux ? "d-none" : ""}`} />
                            panneau
                        </p>
                    </PanelVirtual>
                </PanelItem>
                
            </PanelsContainer>
        </>

    );

};

export default PanelContainer;